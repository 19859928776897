import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Form } from "../../../modules/react-forms/lib";
import { Alert, Modal } from "../../../modules/ui-kit-react/lib";
import ProjectGroupForm from "../Form";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { CreateProjectGroupRequest } from "types/api";

interface CreateGroupProps {
  onClose: () => void;
  active: boolean;
}

const CreateGroup: React.FC<CreateGroupProps> = ({ active, onClose }) => {
  const formRef = useRef<Form<CreateProjectGroupRequest>>(null);
  const queryClient = useQueryClient();

  const createGroup = useAuthenticatedMutation<any, CreateProjectGroupRequest>(
    "project-groups",
    {
      requiresTenant: true,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["project-groups"] });
        onClose();
      },
    }
  );

  const handleSubmit = (groupData: CreateProjectGroupRequest) => {
    createGroup.mutate(groupData);
  };

  return (
    // @ts-expect-error ui-kit-lib has outdated types
    <Modal
      small
      active={active}
      closeModal={onClose}
      type="small"
      title="New Group"
      actions={[
        {
          flat: true,
          appearance: "danger",
          label: "Cancel",
          onClick: onClose,
        },
        {
          raised: true,
          appearance: "primary",
          label: "Create",
          onClick: () => formRef.current?.submit()
        },
      ]}
    >
      <>
        {createGroup.error && (
          <Alert type="danger" message={createGroup.error.message} />
        )}
        <Form ref={formRef} onValidSubmit={handleSubmit}>
          <ProjectGroupForm gqlError={createGroup.error} />
        </Form>
      </>
    </Modal>
  );
};

CreateGroup.propTypes = {
  onClose: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default CreateGroup;
