import React, { useState, useEffect } from "react";
import moment from "moment";
import Search from "components/Search";
import { highestRole } from "components/Auth/roles";
import Tooltip from "components/Tooltip";
import Title from "components/Title";
import {
  Button,
  CardContent,
  CardHead,
  Table,
} from "../../../../modules/ui-kit-react/lib";
import { useQuery } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import AddUserRoleModal from "./AddUserRoleModal";
import style from "./style.module.css";
import { User, UserType, UserRole } from "types/api";
import { useNavigate } from "react-router-dom";

const roleTipMap: any = {
  ADMIN: "Can do everything within the tenant.",
  DEPLOYER: "Can deploy privileged environments within the tenant.",
  USER: "Can deploy non-privileged environments within the tenant.",
};

const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const [visibleUsers, setVisibleUsers] = useState<UserRole[]>([]);
  const [addUserActive, setAddUserActive] = useState(false);

  // Fetch user roles
  const { data, error, isLoading, refetch: refetchRoles } = useQuery<UserRole[]>(
    useTenantQuery(["security/user-roles"])
  );

  useEffect(() => {
    setVisibleUsers(data || []);
  }, [data]);

  // Delete user role mutation
  const deleteRole = useAuthenticatedMutation<void, { id: number }>(
    "security/user-roles/:id",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        refetchRoles();
      },
      pathParams: (variables) => ({ id: variables.id.toString() }),
    }
  );

  if (error) throw error;
  if (isLoading) return null;

  const userRoles =
    data?.filter((r) => r.type === UserType.Normal) || [];

  const renderActions = (row: UserRole) => (
    <div className={style.actions}>
      <Button
        appearance="primary"
        small
        raised
        flat
        onClick={() => navigate(`../user-roles/${row.userId}`)}
      >
        <i className="material-icons">edit</i>
      </Button>

      <Button
        appearance="danger"
        small
        raised
        flat
        onClick={() => deleteRole.mutate({ id: row.userId })}
      >
        <i className="material-icons">delete</i>
      </Button>
    </div>
  );

  const renderRoles = ({ roles }: UserRole) => {
    const role = highestRole(roles || []) || "";

    return (
      <>
        <span className={style.role} data-tip={roleTipMap[role]}>
          {role.toLowerCase()}
        </span>
        <Tooltip />
      </>
    );
  };

  const renderSearch = (users: UserRole[]) => (
    <Search
      onSearch={(items: any) => setVisibleUsers(items)}
      keys={["user.username", "user.displayName"]}
      collection={users}
    />
  );

  return (
    <>
      <Title title="User Roles" />

      <CardHead className={style.head}>
        <div className={style.title}>User Roles</div>
        <div className={style.search}>{renderSearch(userRoles)}</div>

        <div className={style.topActions}>
          <Button
            appearance="primary"
            icon="add"
            iconPos="left"
            onClick={() => setAddUserActive(true)}
          >
            Add User
          </Button>
        </div>
      </CardHead>

      <CardContent>
        <Table
          paginationSize={25}
          columns={[
            {
              heading: "Display Name",
              path: "displayName",
              sort: true,
            },
            {
              heading: "Username",
              path: "username",
              sort: true,
            },
            {
              heading: "Role",
              path: "roles.length",
              template: renderRoles,
              sort: true,
            },
            {
              heading: "Actions",
              template: renderActions,
              sort: false,
            },
          ]}
          data={visibleUsers}
        />
      </CardContent>

      {addUserActive && (
        <AddUserRoleModal
          userRoleData={data}
          onClose={() => {
            setAddUserActive(false)
            refetchRoles()
          }}
        />
      )}
    </>
  );
};

export default UsersList;
