import React, { useState } from "react";
import classnames from "classnames";
import { capitalize } from "lodash";
import { Alert, Button, Chip } from "../../modules/ui-kit-react/lib";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import Protected from "../Protected";
import WebHookEdit from "./Edit";
import getWebHookType from "./util";
import { Environment, WebHook as IWebHook } from "types/api";

interface WebHookProps {
  webHook: IWebHook;
  environment: Environment;
  newWebHookId: number;
  newSigningKey: string;
}

const WebHook: React.FC<WebHookProps> = ({
  webHook,
  environment,
  newWebHookId,
  newSigningKey,
}) => {
  const [editing, setEditing] = useState<number | null>(null);
  const [copied, setCopied] = useState(false);
  const queryClient = useQueryClient();

  const deleteWebHook = useAuthenticatedMutation<void, { id: number }>(
    "webhooks/{webhookId}",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`environments/${environment.id}`],
        });
      },
    }
  );

  const webHookType = getWebHookType(webHook?.url || "");

  return (
    <React.Fragment key={webHook.id}>
      <div className={style.hook}>
        <div className={style.hookUrlWrap}>
          <div className={style.hookUrl}>
            <i className="material-icons">link</i>
            <span>{webHook.url}</span>
          </div>
          <Protected hasRole="USER">
            <div className={style.actions}>
              <i
                onClick={(e) => {
                  e.preventDefault();
                  setEditing(webHook.id || null);
                }}
                className={classnames("material-icons", style.edit)}
                data-cy="edit-web-hook-btn"
              >
                edit
              </i>
              <Button
                appearance="danger"
                small
                flat
                onClick={() => {
                  if (webHook.id) {
                    deleteWebHook.mutate({ id: webHook.id });
                  }
                }}
                data-cy="delete-web-hook-btn"
              >
                <i className="material-icons">delete</i>
              </Button>
            </div>
          </Protected>

          {newSigningKey &&
            newWebHookId === webHook.id &&
            webHookType === "https" && (
              <Alert
                className={style.keyAlert}
                message={
                  <div>
                    <strong>SAVE THIS KEY - IT WILL NOT BE SHOWN AGAIN</strong>
                    <div className={style.signingKeyWrap}>
                      <div className={style.key}>
                        <code>{newSigningKey}</code>
                      </div>
                      <div>
                        <Button
                          appearance={copied ? "success" : "primary"}
                          raised
                          small
                          onClick={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(newSigningKey);
                            setCopied(true);
                          }}
                        >
                          {copied ? "Copied" : "Copy"}
                        </Button>
                      </div>
                    </div>
                    <p>
                      Please use this key to verify the integrity of messages
                      sent to this web hook.
                    </p>
                    <a
                      href="https://shield-docs.rvohealth.dev/docs/features/web-hooks.html"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Learn more about web hook verification here.
                    </a>
                  </div>
                }
                type="info"
              />
            )}
          <div className={style.on}>
            {webHook?.on?.map((status) => (
              // @ts-ignore
              <Chip>{capitalize(status)}</Chip>
            ))}
          </div>
        </div>
      </div>
      {editing && (
        <WebHookEdit onClose={() => setEditing(null)} webHook={webHook} />
      )}
    </React.Fragment>
  );
};

export default WebHook;
