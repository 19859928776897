import React, { createRef, FC } from 'react';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import { Form } from '../../modules/react-forms/lib';
import { get } from 'lodash';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import style from '../SlackAlertForm/style.module.css';

interface SlackReminder {
  id: string;
  channel: string;
}

interface Props {
  onSubmit: (form: { channel: string }) => void;
  onCancel: () => void;
  reminder?: SlackReminder;
  error: any;
  edit?: boolean;
  loading: boolean;
}

const SlackReminderForm: FC<Props> = ({
  onSubmit,
  onCancel,
  reminder,
  edit = false,
  error,
  loading,
}) => {
  const form = createRef<Form<{ channel: string }>>();

  return (
    <Form
      //@ts-ignore
      className={style.form}
      onValidSubmit={onSubmit}
      ref={form}
    >
      <TextInput
        name="channel"
        type="text"
        placeholder="#engineering-global"
        className={style.input}
        defaultValue={get(reminder, 'channel')}
        error={extractFieldError(error, 'channel')}
        required
      />

      <div className={style.actions}>
        <Button
          raised
          small
          appearance="primary"
          onClick={(e) => {
            e.preventDefault();
            form.current?.submit();
          }}
        >
          {edit ? 'Update' : 'Create'}
        </Button>

        <Button flat small appearance="danger" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default SlackReminderForm;
