import React from "react";
import {
  Card,
  CardContent,
  CardHead,
  Alert,
  Button,
} from "../../../modules/ui-kit-react/lib";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { ProjectGroup } from "types/api";
import style from "../Form/style.module.css";
import AddProjectToGroupButton from "../AddProjectToGroupButton";

interface ProjectGroupProjectsListProps {
  projectGroup: ProjectGroup;
}

const ProjectGroupProjectsList: React.FC<ProjectGroupProjectsListProps> = ({
  projectGroup,
}) => {
  const queryClient = useQueryClient();

  const removeProject = useAuthenticatedMutation<void, { projectId: number }>(
    `project-groups/${projectGroup.id}/projects/:projectId`,
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`project-groups/${projectGroup.id}`],
        });
      },
      pathParams: (variables) => ({
        projectId: variables.projectId.toString(),
      }),
    }
  );

  return (
    <Card>
      <CardHead>Projects in This Group</CardHead>
      <CardContent>
        {projectGroup?.projects?.length === 0 && (
          <Alert
            type="warning"
            className={style.noProjects}
            message={
              <>
                There are no projects in this group.
                <AddProjectToGroupButton
                  projectGroup={projectGroup}
                  flat={false}
                  raised
                  small
                />
              </>
            }
          />
        )}
        <ul className={style.projects}>
          {projectGroup?.projects?.map((project) => (
            <li key={project.id}>
              <div>{project.name}</div>
              <div>
                <Button
                  appearance="danger"
                  small
                  flat
                  icon="remove"
                  onClick={() =>
                    removeProject.mutate({ projectId: project.id })
                  }
                />
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default ProjectGroupProjectsList;
