import React from "react";
import UserRoleForm from "components/UserRoleForm";
import Title from "components/Title";
import { sliceRoles } from "components/Auth/roles";
import {
  Row,
  Col,
  CardHead,
  CardContent,
  Alert,
} from "../../../../modules/ui-kit-react/lib";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import { useParams, useNavigate } from "react-router-dom";
import { UserRole } from "types/api";
import { useUser } from "hooks/security-hooks";

const UserEdit: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: user } = useUser(Number(id));

  // Fetch user role
  const {
    data: userRole,
    error,
    isLoading,
  } = useQuery<UserRole>(useTenantQuery([`security/user-roles/${id}`]));

  // Update user role mutation
  const updateRole = useAuthenticatedMutation<UserRole, string[]>(
    `security/user-roles/${id}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["security/user-roles"],
        });
        navigate("../user-roles");
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );

  if (error) throw error;
  if (isLoading) return "loading...";

  return (
    <>
      <Title title="Edit User Role" />
      <CardHead>Edit User Role</CardHead>

      <CardContent>
        <Row>
          <Col md={6} xs={12}>
            {updateRole.error && (
              <Alert type="danger" message={updateRole.error.message} />
            )}
            <UserRoleForm
              loading={updateRole.isPending}
              edit
              userRole={{
                user: {
                  username: user?.username,
                },
                roles: userRole,
              }}
              onSubmit={(form) => {
                updateRole.mutate(sliceRoles(form.role));
              }}
            />
          </Col>
        </Row>
      </CardContent>
    </>
  );
};

export default UserEdit;
