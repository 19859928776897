import React from 'react';
import { get } from 'lodash';
import { awsRegions, humanize } from 'utils/enums';
// @ts-expect-error
import { Validators } from '../../../modules/react-forms';
import TextInput from '../../Form/TextInput';
import extractFieldError from '../../../utils/gqlErrors';
import SelectInput from '../../Form/SelectInput';
import { useAwsProfiles } from 'hooks/provider-hooks';
import Checkbox from '../../Form/Checkbox';
import HelpTip from '../../HelpTip';
import style from './style.module.css';
import { AwsProfile, S3Configuration } from 'types/api';

const s3Validation = Validators.isURL({
  protocols: ['s3'],
  require_tld: false,
  require_protocol: true,
  allow_underscores: true
});

interface S3FieldsProps {
  s3Configuration?: S3Configuration;
  gqlError?: {
    graphQLErrors?: any[];
  } | null;
}

const S3Fields: React.FC<S3FieldsProps> = ({ 
  s3Configuration = {}, 
  gqlError = null 
}) => {
  const { data: awsProfilesResponse, isLoading } = useAwsProfiles();
  
  const getError = (field: string) => {
    return extractFieldError(gqlError, field);
  };

  if (isLoading) {
    return null;
  }

  const awsProfiles = awsProfilesResponse || [];

  return (
    <>
      <TextInput
        name="providerConfiguration[__typename]"
        type="hidden"
        defaultValue="s3"
      />

      <TextInput
        name="providerConfiguration[artifactBucket]"
        label="Artifact Bucket"
        defaultValue={get(s3Configuration, 'artifactBucket')}
        required
        error={getError('artifactBucket')}
        placeholder="s3://bucket/path"
        validation={{
          'Invalid S3 bucket URI': s3Validation
        }}
      />

      <TextInput
        name="providerConfiguration[deployBucket]"
        label="Deploy Bucket"
        defaultValue={get(s3Configuration, 'deployBucket')}
        required
        error={getError('deployBucket')}
        placeholder="s3://bucket"
        validation={{
          'Invalid S3 bucket URI': s3Validation,
          'URI must be the root bucket': (uri: string) =>
            uri.match(/^s3:\/\/[^/]+\/?$/i)
        }}
      />

      <TextInput
        name="providerConfiguration[distributionId]"
        label="CloudFront Distribution ID(s)"
        defaultValue={get(s3Configuration, 'distributionId', null)}
        error={getError('distributionId')}
        placeholder="EDFDVBD632BHDS5"
        hint="If provided, this distribution's cache will be cleared after deploy. Multiple distribution IDs can be specified, separated by commas."
        theme={{
          hint: style.hint
        }}
      />

      <SelectInput
        name="providerConfiguration[region]"
        label="Region"
        defaultValue={get(s3Configuration, 'region')}
        required
        error={getError('region')}
        source={awsRegions.map(region => ({
          value: region,
          label: humanize(region)
        }))}
      />

      <SelectInput
        name="providerConfiguration[awsProfileId]"
        label="AWS Profile"
        defaultValue={get(s3Configuration, 'awsProfileId')}
        required
        source={awsProfiles.map((profile: AwsProfile) => ({
          value: profile.id,
          label: profile.name
        }))}
        error={getError('awsProfile.id')}
        validation={!Validators.isEmpty}
      />

      <Checkbox
        name="providerConfiguration[deleteOldFiles]"
        label={
          <>
            Delete Old Files &nbsp;
            <HelpTip text="If checked, will delete any files remaining in the S3 bucket not present in the release." />
          </>
        }
        defaultValue={get(s3Configuration, 'deleteOldFiles') ?? false}
        error={getError('deleteOldFiles')}
      />
    </>
  );
};

export default S3Fields;
