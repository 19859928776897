import React from 'react';
import Alert from '../../modules/ui-kit-react/lib/components/Alert';
import { CircularProgress } from '@mui/material';
import SlackAlertForm from 'components/SlackAlertForm';
import { useCreateSlackAlert } from 'hooks/slack-hooks';
import { getStatusEnumsFromForm } from 'utils/enums';
import style from './style.module.css';

interface SlackAlertInlineAddProps {
  environmentId: number;
  onClose: () => void;
}

const SlackAlertInlineAdd: React.FC<SlackAlertInlineAddProps> = ({
  onClose,
  environmentId,
}) => {
  const createAlert = useCreateSlackAlert(environmentId);

  const handleSubmit = async (form: { channel: string; on: string[] }) => {
    try {
      await createAlert.mutateAsync({
        channel: form.channel,
        on: getStatusEnumsFromForm(form.on),
        environmentId
      });
      onClose();
    } catch (error) {
      // Error handling is automatic through react-query
      console.error('Error creating slack alert:', error);
    }
  };

  return (
    <div className={style.container} data-cy="slack-notification-form">
      {createAlert.isPending && <CircularProgress />}
      
      {createAlert.error && (
        <Alert type="danger" message={createAlert.error.message} />
      )}
      
      <div className={style.heading}>New Alert</div>
      
      <SlackAlertForm
        gqlError={createAlert.error}
        loading={createAlert.isPending}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default SlackAlertInlineAdd;
