import React from 'react';
import { Grid } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EnvBox from 'components/EnvBox';

const ProjectEnvGridItem = ({
  env,
  isDragging,
  project,
  subscribeToMore
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: isDraggingItem
  } = useSortable({
    id: String(env.id),
    data: env
  });

  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none'
  };

  return (
    <Grid 
      item 
      lg={4} 
      md={6} 
      xs={12}
      ref={setNodeRef}
      style={styles}
      sx={{ p: 1.5 }}
      {...attributes}
      {...listeners}
    >
      <EnvBox
        env={env}
        isDragging={isDragging || isDraggingItem}
        project={project}
        subscribeToMore={subscribeToMore}
        dragHandleProps={null}
      />
    </Grid>
  );
};

export default ProjectEnvGridItem;
