import React, { useState } from "react";
import Container from "components/Container";
import EnvForm from "components/EnvForm";
import Title from "components/Title";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Crumb,
  Alert,
  CardHead,
  CardContent,
} from "../../../modules/ui-kit-react/lib";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { Provider } from "types/api";

interface ProviderConfig {
  __typename: Provider;
  [key: string]: any;
}

const EnvironmentsAdd: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [providerError, setProviderError] = useState(false);

  // Create environment mutation
  const createEnv = useAuthenticatedMutation<
    { id: number },
    { environment: any }
  >(`projects/${projectId}/environments`, {
    requiresTenant: true,
  });

  // Update deployment configuration mutation
  const updateDeployConfig = useAuthenticatedMutation<any, any>(
    "environments/:environmentId/deployment-configuration",
    {
      requiresTenant: true,
      method: "PUT",
      queryParams: (variables) => ({
        providerType: variables.providerType,
      }),
      pathParams: (variables) => ({
        environmentId: variables.environmentId.toString(),
      }),
    }
  );

  const createProvider = async (
    providerConfiguration: ProviderConfig,
    environmentId: number
  ) => {
    try {
      const { __typename, ...configWithoutTypename } = providerConfiguration;
  
      const modifiedConfig = { ...configWithoutTypename };
      
      // Use ecsClusterId to a number if it exists, because it's a string in form field management
      if ('ecsClusterId' in modifiedConfig && modifiedConfig.ecsClusterId !== undefined) {
        modifiedConfig.ecsClusterId = Number(modifiedConfig.ecsClusterId);
      }
  
      await updateDeployConfig.mutateAsync({
        ...modifiedConfig,
        providerType: __typename,
        environmentId,
      });
    } catch (error) {
      setProviderError(true);
      throw error;
    }
  };

  const handleSubmit = async ({ providerConfiguration, ...rest }: any) => {
    try {
      // Create environment
      if (!providerError) {
        const privileged = rest.privileged === true;
        const autoPromote = rest.autoPromote === true;

        const envResponse = await createEnv.mutateAsync({
          ...rest,
          privileged,
          autoPromote,
          tagPattern: privileged
            ? "^v(0|[1-9]+[0-9]*).(0|[1-9]+[0-9]*).(0|[1-9]+[0-9]*)$"
            : rest.tagPattern || null,
        });

        // Create provider configuration if specified
        if (providerConfiguration) {
          await createProvider(providerConfiguration, envResponse.id);
        }
      }

      navigate(`../../projects/${projectId}`);
    } catch (error) {
      setError(error as Error);
    }
  };

  return (
    <Container>
      <Title title="Add Environment" />

      <CardHead>
        <Breadcrumbs>
          <Crumb>
            <Link to="../../../projects">Projects</Link>
          </Crumb>

          <Crumb>
            <Link to={`../../../projects/${projectId}`}>Project Details</Link>
          </Crumb>

          <Crumb active>New Environment</Crumb>
        </Breadcrumbs>
      </CardHead>

      <CardContent>
        <h1>New Environment</h1>
        <div data-cy="new-environment-form">
          {error && !providerError ? (
            <Alert type="danger" message={error.message} />
          ) : null}

          {providerError ? (
            <Alert
              type="warning"
              message='The environment was successfully created, however, there was an error setting up the deployment configuration.
              You can find the errors in the "Setup Deployment Configuration" section below.
              You may attempt to fix the problems and try again, or setup the configuration at a later time.'
            />
          ) : null}

          <EnvForm
            loading={createEnv.isPending}
            providerConfigError={providerError}
            gqlError={error}
            projectId={projectId}
            onSubmit={handleSubmit}
          />
        </div>
      </CardContent>
    </Container>
  );
};

export default EnvironmentsAdd;
