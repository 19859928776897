import React from "react";
import { useNavigate } from "react-router-dom";
import { withShortcut } from "components/Shortcuts";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { MultiSelect } from "../../modules/ui-kit-react/lib";

interface TenantSwitcherProps {
  close?: () => void;
}

const TenantSwitcher: React.FC<TenantSwitcherProps> = ({ close }) => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery<{ tenants: string[] }>({
    queryKey: ["tenants"],
    meta: { requiresTenant: false },
  });

  if (isLoading) return "...";

  return (
    <MultiSelect
      // @ts-ignore
      autoFocus
      menuIsOpen
      isMulti={false}
      placeholder="Switch Tenant..."
      options={
        (data?.tenants || []).map((t) => ({
          label: t,
          value: t,
        })) as any
      }
      onChange={({ value }: { value: string }) => {
        navigate(`/${value}`);
        if (close) close();
      }}
    />
  );
};

TenantSwitcher.propTypes = {
  close: PropTypes.func,
};

export default withShortcut("t")(TenantSwitcher);
