import React from "react";
import Title from "components/Title";
import {
  Button,
  Table,
  // Chip,
  CardHead,
  CardContent,
} from "modules/ui-kit-react/lib";
import { Chip } from "@mui/material";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { AwsProfile, EcsCluster, EksCluster } from "types/api";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";

const AwsProfilesList = () => {
  const navigate = useNavigate();
  // Fetch AWS profiles
  const {
    data: profiles,
    error: profilesError,
    refetch: refetchProfiles,
  } = useQuery<AwsProfile[]>(useTenantQuery(["providers/aws/profiles"]));

  // Fetch ECS clusters
  const { data: ecsClusters, refetch: refetchEcsClusters } = useQuery<
    EcsCluster[]
  >(useTenantQuery(["providers/aws/ecs-clusters"]));

  // Fetch EKS clusters
  const { data: eksClusters, refetch: refetchEksClusters } = useQuery<
    EksCluster[]
  >(useTenantQuery(["providers/aws/eks-clusters"]));

  const deleteProfile = useAuthenticatedMutation<void, { id: number }>(
    "providers/aws/profiles/:id",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        // Refetch both profiles and clusters after deletion
        refetchProfiles();
        refetchEcsClusters();
        refetchEksClusters();
      },
      onError: (error) => {
        console.error("Error deleting profile:", error);
      },
      pathParams: (variables) => ({ id: variables.id.toString() }),
    }
  );

  const createClusterRenderTemplate = (
    awsProfileId: number,
    clusters?: EcsCluster[] | EksCluster[]
  ) => {
    if (!clusters) return <span>None</span>;
    const applicableClusters = clusters.filter(
      (cluster) => cluster.awsProfileId === awsProfileId
    );
    if (!applicableClusters?.length) return <span>None</span>;

    return applicableClusters.map((cluster) => (
      <Chip key={cluster.id} label={cluster.name} />
    ));
  };

  const renderActions = (profile: AwsProfile) => (
    <div className={style.actions}>
      <Button
        data-cy="manage-aws-profile-btn"
        appearance="primary"
        small
        raised
        flat
        onClick={() => navigate(`../aws-profiles/${profile.id}`)}
      >
        <i className="material-icons">settings</i>
      </Button>

      <Button
        data-cy="delete-aws-profile-btn"
        appearance="danger"
        small
        raised
        flat
        onClick={() => deleteProfile.mutate({ id: profile.id })}
        // disabled={deleteProfile.isLoading}
      >
        <i className="material-icons">delete</i>
      </Button>
    </div>
  );

  if (profilesError) {
    throw profilesError;
  }

  return (
    <>
      <Title title="AWS Profiles" />

      <CardHead className={style.new}>
        <div>AWS Profiles</div>
        <Button
          data-cy="create-aws-profile-btn"
          appearance="primary"
          raised
          onClick={() => navigate("../aws-profiles/new")}
        >
          New
        </Button>
      </CardHead>

      <CardContent>
        <Table
          paginationSize={15}
          columns={[
            {
              heading: "Name",
              path: "name",
              sort: true,
            },
            {
              heading: "Role Arn",
              path: "roleArn",
              sort: false,
            },
            {
              heading: "ECS Clusters",
              template: (row: AwsProfile) =>
                createClusterRenderTemplate(row.id, ecsClusters),
              sort: false,
            },
            {
              heading: "EKS Clusters",
              template: (row: AwsProfile) =>
                createClusterRenderTemplate(row.id, eksClusters),
              sort: false,
            },
            {
              heading: "Actions",
              template: renderActions,
            },
          ]}
          data={profiles || []}
        />
      </CardContent>
    </>
  );
};

export default AwsProfilesList;
