import React, { FC } from "react";
import Alert from "../../modules/ui-kit-react/lib/components/Alert";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import SlackReminderForm from "./SlackReminderForm";
import style from "../SlackAlertInlineAdd/style.module.css";

interface Props {
  onClose: () => void;
  environmentId: number;
}

const SlackAlertInlineAdd: FC<Props> = ({ environmentId, onClose }) => {
  const queryClient = useQueryClient();

  const createReminder = useAuthenticatedMutation<any, { channel: string }>(
    `environments/${environmentId}/slack-reminders`,
    {
      requiresTenant: true,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`environments/${environmentId}`],
        });
        onClose();
      },
    }
  );

  return (
    <div className={style.container} data-cy="slack-notification-form">
      {createReminder.error && (
        <Alert type="danger" message={createReminder.error.message} />
      )}

      <div className={style.heading}>New Reminder</div>

      <SlackReminderForm
        error={createReminder.error}
        loading={createReminder.isPending}
        onCancel={onClose}
        onSubmit={(form) => createReminder.mutate(form)}
      />
    </div>
  );
};

export default SlackAlertInlineAdd;
