import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TextInput from "components/Form/TextInput";
import CheckBox from "components/Form/Checkbox";
import extractFieldError from "utils/gqlErrors";
import HelpTip from "components/HelpTip";
import classnames from "classnames";
import {
  Button,
  Card,
  CardContent,
  CardHead,
  Row,
  Col,
} from "../../modules/ui-kit-react/lib";
// @ts-ignore
import { Form, Validators } from "../../modules/react-forms/lib";
import styles from "./style.module.css";
import ProviderSelection from "../ProviderSelection";
import Protected from "../Protected";
import SlackAlerts from "../SlackAlerts";
import SlackReminders from "../SlackReminders";
import WebHooks from "../WebHooks";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import { useQuery } from "@tanstack/react-query";
import { Environment, SlackAlert, SlackReminder } from "types/api";

export interface EnvironmentWithSlack extends Environment {
  slackAlerts: SlackAlert[];
  slackReminders: SlackReminder[];
}

const useEnvironmentData = (environmentId: string) => {
  // Fetch base environment data
  const {
    data: envData,
    error: envError,
    isLoading: envLoading,
  } = useQuery<{ environment: Environment }>({
    ...useTenantQuery(["environments", environmentId]),
  });

  // Fetch slack alerts
  const { data: alertsData, isLoading: alertsLoading } = useQuery<{
    slackAlerts: SlackAlert[];
  }>({
    ...useTenantQuery(["environments", environmentId, "slack-alerts"]),
    enabled: !!environmentId,
  });

  // Fetch slack reminders
  const { data: remindersData, isLoading: remindersLoading } = useQuery<{
    slackReminders: SlackReminder[];
  }>({
    ...useTenantQuery(["environments", environmentId, "slack-reminders"]),
    enabled: !!environmentId,
  });

  // Combine the data
  const enhancedEnvironment: EnvironmentWithSlack | undefined =
    envData?.environment
      ? {
          ...envData.environment,
          slackAlerts: alertsData?.slackAlerts || [],
          slackReminders: remindersData?.slackReminders || [],
        }
      : undefined;

  return {
    environment: enhancedEnvironment,
    isLoading: envLoading || alertsLoading || remindersLoading,
    error: envError,
  };
};

function EnvForm({
  // @ts-expect-error missing types
  onSubmit,
  // @ts-expect-error missing types
  loading,
  environment = {},
  edit = false,
  // @ts-expect-error missing types
  projectId,
  // @ts-expect-error missing types
  gqlError,
  providerConfigError = false,
}) {
  const navigate = useNavigate();
  const {
    environment: environmentWithSlack,
    isLoading,
    error: envError,
  // @ts-ignore
  } = useEnvironmentData(environment?.id || "");

  const { tenant } = useParams();

  const [providerConfig, setProviderConfig] = useState(false);

  const cancel = () => {
    // Navigate back to the project details
    navigate(`/${tenant}/projects/${projectId}`);
  };

  // @ts-ignore
  const getError = (field) => extractFieldError(gqlError, field);

  if (loading) return "Loading...";

  return (
    <Form onValidSubmit={onSubmit}>
      <Row>
        {/* Left Column */}
        <Col sm={5}>
          <Card
            className={classnames({
              [styles.readonly]: providerConfigError,
            })}
          >
            <CardHead>Environment Details</CardHead>
            <CardContent>
              <TextInput
                name="name"
                label="Name"
                placeholder="e.g. Production"
                // @ts-ignore
                defaultValue={environment.name}
                error={getError("name")}
                required
                disabled={providerConfigError}
                standAlone={providerConfigError}
              />

              {edit && (
                <TextInput
                  label="Slug"
                  // @ts-ignore
                  defaultValue={environment.slug}
                  error={getError("slug")}
                  disabled
                  standAlone
                />
              )}

              <TextInput
                name="url"
                label="Url"
                placeholder="e.g. https://www.rvohealth.com"
                // @ts-ignore
                defaultValue={environment.url}
                validation={{
                  "Must provide protocol: http or https": Validators.isURL({
                    require_protocol: true,
                    protocols: ["http", "https"],
                  }),
                }}
                error={getError("url")}
                disabled={providerConfigError}
                standAlone={providerConfigError}
              />

              <CheckBox
                name="privileged"
                label={
                  <span>
                    Protected Environment&nbsp;
                    <HelpTip text="Only allows those with the deployer role to deploy to this environment." />
                  </span>
                }
                // @ts-ignore
                defaultValue={edit ? environment.privileged : true}
                error={getError("privileged")}
                disabled={providerConfigError}
                standAlone={providerConfigError}
              />

              <TextInput
                name="tagPattern"
                label="Tag Filter Pattern"
                placeholder="*"
                // @ts-ignore
                defaultValue={environment.tagPattern}
                error={getError("tagPattern")}
                // @ts-ignore
                disabled={providerConfigError || environment.privileged}
                standAlone={providerConfigError}
                hint="If provided, will be used to filter the tags shown for deployment. Setting this is disabled in protected environments."
              />
            </CardContent>
          </Card>

          {/* Only show ProviderSelection for new env if admin */}
          {!edit && (
            <Protected hasRole="ADMIN">
              <Card>
                <CardHead>
                  <CheckBox
                    className={styles.checkbox}
                    standAlone
                    label={
                      <span>
                        Setup Deployment Configuration&nbsp;
                        <HelpTip text="You can setup your deployment configuration now, or after the environment is created" />
                      </span>
                    }
                    onChange={setProviderConfig}
                    value={providerConfig}
                  />
                </CardHead>
                {providerConfig && (
                  <CardContent>
                    <ProviderSelection gqlError={gqlError} />
                  </CardContent>
                )}
              </Card>
            </Protected>
          )}
        </Col>

        {/* Right Column */}
        <Col sm={7}>
          <Card
            className={classnames({
              [styles.readonly]: providerConfigError,
            })}
          >
            <CardHead>Automation</CardHead>
            <CardContent>
              <h4>Continuous Delivery</h4>
              <CheckBox
                name="autoPromote"
                label={
                  <>
                    Automatically promote successful builds &nbsp;
                    <HelpTip text="This requires further integration with your CI provider. Please check out the auto promote docs for more information." />
                  </>
                }
                // @ts-ignore
                defaultValue={edit ? environment.autoPromote : false}
                error={getError("autoPromote")}
                disabled={providerConfigError}
                standAlone={providerConfigError}
              />
              {edit && environmentWithSlack && <SlackAlerts environment={environmentWithSlack} />}
              {edit && environmentWithSlack && <WebHooks environment={environmentWithSlack} />}
              {edit && environmentWithSlack && <SlackReminders environment={environmentWithSlack} />}
            </CardContent>
          </Card>
        </Col>
      </Row>

      <div className={styles.actions}>
        <Button
          // @ts-ignore
          name="save"
          disabled={loading}
          raised
          appearance="primary"
          type="submit"
        >
          {edit ? "Update" : "Create"}
        </Button>

        <Button
          // @ts-ignore
          name="cancel"
          disabled={loading}
          lowered
          appearance="danger"
          onClick={cancel}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}

export default EnvForm;
