import React, { useState } from 'react';
import { Alert } from '../../../modules/ui-kit-react/lib';
import { useParams, Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTenantQuery } from 'components/Auth/AuthenticatedQueryProvider';
import TextInput from 'components/Form/TextInput';
// import MultiSelectInput from 'components/Form/MultiSelectInput';
import SelectInput from 'components/Form/SelectInput';
import { humanize } from 'utils/enums';

interface EksCluster {
  id: number;
  name: string;
  region: string;
  awsProfile?: {
    name: string;
  }
}

interface EksHelmConfiguration {
  chartRepositoryUrl?: string;
  chartName?: string;
  releaseName?: string;
  valuesPath?: string;
  eksClusterId?: number;
  timeout?: number;
  namespace?: string;
}

interface Props {
  eksHelmConfiguration?: EksHelmConfiguration;
}

function NoClusters() {
  const { tenant } = useParams();

  return (
    <Alert
      type="danger"
      message={(
        <span>
          We were unable to find any EKS clusters. In order to create an EKS
          deployment configuration, you will need to
          <Link to={`/${tenant}/admin/aws-profiles`}>
            sync the clusters from an AWS profile.
          </Link>
          <br />
          <br />
          You can read more about this process
          <a
            href="https://shield-docs.rvohealth.dev/docs/admin/profiles.html#syncing-eks-clusters"
            target="_blank"
            rel="noreferrer noopener"
          >
            here
          </a>
          .
        </span>
      )}
    />
  );
}

export default function EksHelmFields({ eksHelmConfiguration }: Props) {
  // const [clusterId, setClusterId] = useState(eksHelmConfiguration?.cluster?.id);
  
  const { data, error, isLoading } = useQuery<EksCluster[]>(
    useTenantQuery(['providers/aws/eks-clusters'])
  );

  if (isLoading) return null;
  if (error) return <Alert message={error.message} type="danger" />;
  const eksClusters = data || [];
  const noEksClusters = !eksClusters.length;

  return (
    <>
      {noEksClusters && <NoClusters />}

      <TextInput
        name="providerConfiguration[__typename]"
        type="hidden"
        defaultValue="eks-helm"
        disabled={noEksClusters}
      />

      <TextInput
        name="providerConfiguration[chartRepositoryUrl]"
        label="Chart Repository URL"
        defaultValue={eksHelmConfiguration?.chartRepositoryUrl}
        disabled={noEksClusters}
        required
      />

      <TextInput
        name="providerConfiguration[chartName]"
        label="Chart Name"
        defaultValue={eksHelmConfiguration?.chartName}
        disabled={noEksClusters}
        required
      />

      <TextInput
        name="providerConfiguration[valuesPath]"
        label="Values Path (relative to root of repository)"
        defaultValue={eksHelmConfiguration?.valuesPath}
        disabled={noEksClusters}
        required
      />

      <TextInput
        name="providerConfiguration[releaseName]"
        label="Release Name"
        defaultValue={eksHelmConfiguration?.releaseName}
        disabled={noEksClusters}
      />

      <SelectInput
        name="providerConfiguration[eksClusterId]"
        label="EKS Cluster"
        defaultValue={eksHelmConfiguration?.eksClusterId}
        source={eksClusters.map(cluster => ({
          value: cluster.id,
          label: `${cluster.awsProfile?.name || ''} - ${cluster.name} (${humanize(
            cluster.region
          )})`
        }))}
        required
      />

      <TextInput
        name="providerConfiguration[namespace]"
        label="Namespace"
        defaultValue={eksHelmConfiguration?.namespace}
        disabled={noEksClusters}
        required
      />

      <SelectInput
        name="providerConfiguration[timeout]"
        label="Timeout (min)"
        defaultValue={eksHelmConfiguration?.timeout || 600}
        source={[5, 10, 15, 30, 45, 60].map(minutes => ({
          value: minutes * 60,
          label: minutes.toString()
        }))}
        required
      />

    </>
  );
}