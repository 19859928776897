import React, { FC, createRef } from "react";
import { Alert, Modal } from "../../../../modules/ui-kit-react/lib";
import { ButtonProps } from "../../../../modules/ui-kit-react/lib/components/Button";
import { Form } from "../../../../modules/react-forms/lib";
import { ROLES, sliceRoles } from "components/Auth/roles";
import MultiSelect from "components/Form/MultiSelectInput";
import { useQuery } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import { User, UserRole } from "types/api";

interface Props {
  onClose: () => void;
  userRoleData: UserRole[] | undefined;
}

interface FormProps {
  userId: number;
  role: string;
}

const UserRoleModal: FC<Props> = ({ userRoleData, onClose }) => {
  const formRef = createRef<Form<FormProps>>();
  const userRoles = userRoleData || [];
  const usersWithRoles = userRoles?.map(({ userId }) => userId);
  const [selectedUserId, setSelectedUserId] = React.useState<number | null>(
    null
  );

  // Fetch users
  const { data: userData, error: userLoadError } = useQuery<User[]>({
    queryKey: ["security/users"],
    meta: { requiresTenant: false },
  });

  const users = userData || [];

  const availableUsers = users.filter(
    (user) => !usersWithRoles.includes(user.id)
  );

  const updateRole = useAuthenticatedMutation<any, string[]>(
    `security/user-roles/${selectedUserId || ""}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: onClose,
    }
  );

  return (
    <Modal
      active
      closeModal={onClose}
      type="small"
      title="Add User"
      theme={{ body: style.modalBody }}
      actions={
        [
          {
            flat: true,
            appearance: "danger",
            label: "Cancel",
            onClick: onClose,
          },
          {
            raised: true,
            appearance: "primary",
            label: "Create",
            onClick: () => formRef.current?.submit(),
          },
        ] as unknown as ButtonProps[]
      }
    >
      <>
        {userLoadError && (
          <Alert type="danger" message="Error loading users!" />
        )}

        <Form
          ref={formRef}
          onValidSubmit={({ userId, role }) => {
            setSelectedUserId(userId);
            const roles = sliceRoles(role);
            updateRole.mutate(roles);
          }}
        >
          <MultiSelect
            autoFocus
            required
            name="userId"
            label="User"
            isMulti={false}
            placeholder="Search user..."
            options={availableUsers?.map(
              (user) =>
                ({
                  label:
                    user.displayName || user.username || user.email || user.id,
                  value: user.id,
                } as any)
            )}
          />

          <MultiSelect
            required
            name="role"
            label="Role"
            isMulti={false}
            placeholder="Search role..."
            options={ROLES?.map((p) => ({
              value: p,
              label: p,
            }))}
          />
          <div className={style.note}>
            A user must visit Shield at least once to show up in the list of
            users.
          </div>
        </Form>
      </>
    </Modal>
  );
};

export default UserRoleModal;
