import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import { Button } from "../../modules/ui-kit-react/lib";
import { Environment } from "types/api";
import style from "./style.module.css";

interface Props {
  projectId: number;
}

const ArchivedEnvironments: React.FC<Props> = ({ projectId }) => {
  const queryClient = useQueryClient();
  
  const queryKey = [`projects/${projectId}/environments?only_archived=true`];
  
  const {
    data: environments,
    error,
    isLoading,
  } = useQuery<Environment[]>(
    useTenantQuery(queryKey)
  );

  const onUnarchiveSuccess = (environmentId: number) => {
    queryClient.setQueryData(queryKey, (oldData: Environment[] | undefined) => {
      if (!oldData) return [];
      return oldData.filter(env => env.id !== environmentId);
    });
    
    // Invalidate and refetch queries for both archived and active environments
    queryClient.invalidateQueries({
      queryKey: [`projects/${projectId}/environments`],
      exact: false,
    });
    
    // Invalidate the main project query to refresh the environments list
    queryClient.invalidateQueries({
      queryKey: [`projects/${projectId}`],
      exact: false,
    });
  };

  const unarchiveEnvironment = useAuthenticatedMutation<
    void,
    { environmentId: number }
  >("environments/:environmentId/unarchive", {
    requiresTenant: true,
    method: "POST",
    pathParams: (variables) => ({
      environmentId: String(variables.environmentId),
    }),
    onSuccess: (_, variables) => {
      onUnarchiveSuccess(variables.environmentId);
    },
  });

  if (isLoading) return null;
  if (error) throw error;

  return (
    <ul className={style.environments}>
      {!environments || environments.length === 0 ? (
        <li>None</li>
      ) : (
        environments.map(({ name, id }) => (
          <li key={id}>
            <div>{name}</div>
            <div>
              <Button
                appearance="primary"
                small
                flat
                icon="restore_from_trash"
                onClick={() =>
                  unarchiveEnvironment.mutate({ environmentId: id })
                }
              />
            </div>
          </li>
        ))
      )}
    </ul>
  );
};

export default ArchivedEnvironments;
