import React, { useState } from "react";
import classnames from "classnames";
import Protected from "components/Protected";
import SlackAlertEdit from "components/SlackAlertInlineEdit";
import SlackAlertAdd from "components/SlackAlertInlineAdd";
import { capitalize } from "lodash";
import { Chip, Button } from "../../modules/ui-kit-react/lib";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { EnvironmentWithSlack } from "components/EnvForm";
import style from "./style.module.css";

interface SlackAlert {
  id: string;
  channel: string;
  on: string[];
}

interface SlackAlertsProps {
  environment: EnvironmentWithSlack;
}

const SlackAlerts: React.FC<SlackAlertsProps> = ({ environment }) => {
  const [editingAlertId, setEditingAlertId] = useState<number | null>(null);
  const [alertFormActive, setAlertFormActive] = useState(false);
  const queryClient = useQueryClient();

  const deleteAlert = useAuthenticatedMutation<void, { id: number }>(
    "slack-alerts/{slackAlertId}",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`environments/${environment.id}`],
        });
      },
    }
  );

  return (
    <>
      <h4>Slack Notifications</h4>
      {environment.slackAlerts
        .filter(({ channel }) => channel && !channel.startsWith("@"))
        .map((slackAlert) => (
          <React.Fragment key={slackAlert.id}>
            <div className={style.alert}>
              <div className={style.alertChannel}>
                <div className={style.alertChannelName}>
                  <i className="material-icons">notifications</i>
                  <span>{slackAlert.channel}</span>
                </div>
                <Protected hasRole="USER">
                  <div className={style.actions}>
                    <i
                      onClick={(e) => {
                        e.preventDefault();
                        setEditingAlertId(slackAlert.id);
                      }}
                      className={classnames("material-icons", style.edit)}
                      data-cy="edit-slack-notification-btn"
                    >
                      edit
                    </i>
                    <Button
                      appearance="danger"
                      small
                      flat
                      onClick={() => deleteAlert.mutate({ id: slackAlert.id })}
                      data-cy="delete-slack-notification-btn"
                    >
                      <i className="material-icons">delete</i>
                    </Button>
                  </div>
                </Protected>
                <div className={style.on}>
                  {slackAlert?.on?.map((status) => (
                    // @ts-ignore
                    <Chip>{capitalize(status)}</Chip>
                  ))}
                </div>
              </div>
            </div>
            {editingAlertId === slackAlert.id && (
              <SlackAlertEdit
                environmentId={environment.id}
                onClose={() => setEditingAlertId(null)}
                alert={slackAlert}
              />
            )}
          </React.Fragment>
        ))}

      {alertFormActive ? (
        <SlackAlertAdd
          environmentId={environment.id}
          onClose={() => setAlertFormActive(false)}
        />
      ) : (
        <Protected hasRole="USER">
          <div onClick={() => setAlertFormActive(true)} className={style.add}>
            <i className="material-icons">add</i>
            <span data-cy="add-channel-btn">Add Channel</span>
          </div>
        </Protected>
      )}
    </>
  );
};

export default SlackAlerts;
