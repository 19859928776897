import React from "react";
import Container from "components/Container";
import ProjectForm from "components/ProjectForm";
import Title from "components/Title";
import { Link, useParams, useNavigate } from "react-router-dom";
import Alert from "../../../modules/ui-kit-react/lib/components/Alert";
import {
  Breadcrumbs,
  Crumb,
  Card,
  CardHead,
  CardContent,
  Button,
} from "../../../modules/ui-kit-react/lib";
import { Row, Col } from "../../../modules/ui-kit-react/lib/components/Grid";
import { useQuery } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import References from "../../../components/References";
import ArchivedEnvironments from "../../../components/ArchivedEnvironments";
import { Project, Reference } from "types/api";

export interface ProjectWithReferences extends Project {
  references: Reference[];
}

const useProjectReferenceData = (projectId?: string) => {
  // Fetch base project data
  const {
    data: projectData,
    error: projectError,
    isLoading: projectLoading,
  } = useQuery<Project>({
    ...useTenantQuery([`projects/${projectId}`]),
    enabled: !!projectId,
  });

  // Fetch references
  const { data: referencesData, isLoading: referencesLoading } = useQuery<
    Reference[]
  >({
    ...useTenantQuery([`projects/${projectId}/references`]),
    enabled: !!projectId,
  });

  // Combine the data
  const combinedProject: ProjectWithReferences | undefined = projectData
    ? {
        ...projectData,
        references: referencesData || [],
      }
    : undefined;

  return {
    project: combinedProject,
    isLoading: projectLoading || referencesLoading,
    error: projectError,
  };
};

const ProjectsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { project, error } = useProjectReferenceData(id);

  const updateProject = useAuthenticatedMutation<Project, Partial<Project>>(
    `projects/${id}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        navigate(`../${id}`);
      },
    }
  );

  if (error) throw error;
  if (!project) return;

  return (
    <Container>
      <Title title={`Edit Project "${project.name}"`} />

      <CardHead className={style.header}>
        <Breadcrumbs>
          <Crumb>
            <Link to="../">Projects</Link>
          </Crumb>
          <Crumb>
            <Link to={`../${project.id}`}>{project.name}</Link>
          </Crumb>
          <Crumb active>Edit</Crumb>
        </Breadcrumbs>
        <div>
          <Button
            appearance="primary"
            flat
            icon="arrow_back"
            onClick={() => navigate("../")}
          >
            Back to Project
          </Button>
        </div>
      </CardHead>

      <CardContent>
        <Row>
          <Col md={6} xs={12}>
            <Card>
              <CardHead>Project Details</CardHead>
              <CardContent>
                {updateProject.error && (
                  <Alert type="danger" message={updateProject.error.message} />
                )}
                <ProjectForm
                  edit
                  project={project}
                  loading={updateProject.isPending}
                  onSubmit={(form: any) =>
                    updateProject.mutate({
                      name: form.name,
                      description: form.description,
                      repository: form.repository,
                    })
                  }
                />
              </CardContent>
            </Card>
          </Col>

          <Col md={6} xs={12}>
            <Card>
              <CardHead>Project References</CardHead>
              <CardContent>
                <References project={project} />
              </CardContent>
            </Card>

            <Card>
              <CardHead>Archived Environments</CardHead>
              <CardContent>
                <ArchivedEnvironments projectId={project.id} />
              </CardContent>
            </Card>
          </Col>
        </Row>
      </CardContent>
    </Container>
  );
};

export default ProjectsEdit;
