import React from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import {
  Deployment,
  DeploymentLogEntry,
  Environment,
  Project,
} from "types/api";

interface DeploymentLogProps {
  deployment: Deployment;
  project: Project;
  environment: Environment;
  subscribe: boolean;
}

const DeploymentLog: React.FC<DeploymentLogProps> = ({
  deployment,
  project,
  environment,
  subscribe,
}) => {
  // Fetch logs with polling if subscribe is true
  const { data } = useQuery<DeploymentLogEntry[]>({
    ...useTenantQuery([`deployment/${deployment.id}/logs`]),
    refetchInterval: subscribe ? 2000 : false,
    refetchIntervalInBackground: true,
  });

  const renderEntry = (entry: DeploymentLogEntry, index: number) => (
    <code key={`${entry.timestamp}-${index}`}>
      <span className={style.time}>
        {moment(entry.timestamp).utc().format()}
      </span>{" "}
      [<span className={style[entry.level]}>{entry.level}</span>]{" "}
      {entry.message}
    </code>
  );

  const entries = data || [];

  return (
    <div className={style.log} tabIndex={0}>
      <code className={style.header}>
        {`| ${project.name} | ${environment.name} | ${deployment.artifactName} |`}
        <br />
        <br />
      </code>
      {entries?.length ? entries.map(renderEntry) : null}
    </div>
  );
};

export default DeploymentLog;
