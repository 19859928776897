import React, { FC, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { uniqueId } from "lodash";
import { Project } from "types/api";
import ProjectBadgeForm from "components/ProjectBadgeForm";

import { IconButton, Tooltip as MUITooltip, Dialog, Box } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";

import style from "./style.module.css";

interface DeleteBadgeButtonProps {
  index: number;
  project: Project;
}

const DeleteBadgeButton: FC<DeleteBadgeButtonProps> = ({ index, project }) => {
  const queryClient = useQueryClient();
  const updateProject = useAuthenticatedMutation<Project, Partial<Project>>(
    `projects/${project.id}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`projects/${project.id}`],
        });
      },
    }
  );

  const deleteBadge = (badgeIndex: number): void => {
    const updatedBadges = project?.badges?.filter((_, i) => i !== badgeIndex);

    updateProject.mutate({
      name: project.name,
      description: project.description,
      repository: project.repository,
      badges: updatedBadges,
    });
  };

  return (
    <MUITooltip title="Delete badge">
      <IconButton
        size="small"
        color="error"
        onClick={(): void => deleteBadge(index)}
        data-cy="delete-badge-btn"
        sx={{
          pb: 1,
        }}
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    </MUITooltip>
  );
};

interface AddBadgeButtonProps {
  onClick: () => void;
  project: Project;
}

const AddBadgeButton: FC<AddBadgeButtonProps> = ({ onClick, project }) => (
  <MUITooltip title="Add badge">
    <IconButton
      size="small"
      color="primary"
      onClick={onClick}
      data-cy="add-badge-btn"
    >
      <AddBoxIcon />
    </IconButton>
  </MUITooltip>
);

interface ProjectBadgesProps {
  showEdit: boolean;
  project: Project;
}

const ProjectBadges: FC<ProjectBadgesProps> = ({
  project,
  showEdit = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {showEdit && (
        <Box className={style.addBadgeContainer}>
          <AddBadgeButton
            onClick={(): void => setIsEditing(true)}
            project={project}
          />
        </Box>
      )}

      <Box className={style.badgesContainer}>
        {(project.badges || []).map((badge, index) => (
          <Box key={uniqueId("project-badge")} className={style.badgeItem}>
            <a
              className={style.badge}
              href={badge.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div>
                <img src={badge.imgSrc} alt="Badge" />
              </div>
            </a>
            {showEdit && <DeleteBadgeButton index={index} project={project} />}
          </Box>
        ))}
      </Box>

      <Dialog
        open={isEditing}
        onClose={(): void => setIsEditing(false)}
        maxWidth="md"
      >
        <Box sx={{ p: 3 }}>
          <ProjectBadgeForm
            project={project}
            onSuccess={(): void => setIsEditing(false)}
            onCancel={(): void => setIsEditing(false)}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default ProjectBadges;
