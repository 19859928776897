import React from 'react';
import TextInput from 'components/Form/TextInput';
import { Form } from '../../modules/react-forms/lib';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import styles from './style.module.css';
import { useNavigate } from 'react-router-dom';

const validators = {
  name: {
    'Name must be less than 128 characters': (value) => value.length >= 1 && value.length <= 128
  },
  repository: {
    'Must provide protocol: http or https': (value) => {
      try {
        const url = new URL(value);
        return url.protocol === 'http:' || url.protocol === 'https:';
      } catch {
        return false;
      }
    }
  }
};

const ProjectForm = ({
  onSubmit,
  loading = false,
  project = {},
  edit = false,
  errors = {}
}) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    if (edit && project.id) {
      navigate(`../../projects/${project.id}`);
    } else {
      navigate('../../projects');
    }
  };

  return (
    <Form onValidSubmit={onSubmit}>
      <TextInput
        name="name"
        label="Name"
        placeholder="Shield"
        required
        defaultValue={project.name}
        validation={validators.name}
        error={errors.name}
      />

      <TextInput
        name="repository"
        label="Repository"
        placeholder="https://github.com/rvo-redplatform/shield"
        required
        defaultValue={project.repository}
        validation={validators.repository}
        error={errors.repository}
      />

      <TextInput
        name="description"
        label="Description"
        placeholder="The API that runs https://shield.rvohealth.com"
        multiline
        rows={5}
        required
        defaultValue={project.description}
        error={errors.description}
      />

      <div className={styles.actions}>
        <Button 
          disabled={loading} 
          raised 
          appearance="primary" 
          type="submit"
        >
          {edit ? 'Update' : 'Create'}
        </Button>
        
        {!edit && (
          <Button
            disabled={loading}
            lowered
            appearance="danger"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ProjectForm;