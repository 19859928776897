import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Card, CardHead } from "../../modules/ui-kit-react/lib";
import Window, { Pane } from "./Window";
import DeployTool from "./DeployTool";
import Error from "./Error";
import LatestDeployment from "./LatestDeployment";
import Actions from "./Actions";
import Tooltip from "../Tooltip";
import ProviderForm from "./ProviderForm";
import CompareTool from "./CompareTool";
import style from "./style.module.css";
import MoveButton from "./MoveButton";
import Protected from "../Protected";
import { useDeploymentEvents } from "hooks/useDeploymentEventsInternal";
import useEnvironmentWithLatestDeployment from "hooks/useLastDeployment";

function EnvBox({
  env,
  isDragging,
  project,
  subscribeToMore,
  dragHandleProps,
}) {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState("");
  const [currentPane, setCurrentPane] = useState("latest-deployment");

  const { lastEvent } = useDeploymentEvents();

  const environmentWithLatestDeployment =
    useEnvironmentWithLatestDeployment(env);

  // Update status when environment changes
  useEffect(() => {
    const newStatus = environmentWithLatestDeployment?.lastDeployment?.status;
    if (newStatus !== status) {
      setStatus(newStatus);
    }
  }, [environmentWithLatestDeployment]);

  // Handle incoming deployment events
  useEffect(() => {
    const currentEnvId = Number(env.id);
    const currentProjectId = Number(project.id);

    if (
      !lastEvent ||
      !lastEvent.data ||
      !lastEvent.data.message ||
      !lastEvent.data.message.meta
    ) {
      return;
    }

    const eventEnvId = lastEvent.data.message.meta.environmentId
      ? Number(lastEvent.data.message.meta.environmentId)
      : null;
    const eventProjectId = lastEvent.data.message.meta.projectId
      ? Number(lastEvent.data.message.meta.projectId)
      : null;

    if (eventEnvId !== currentEnvId || eventProjectId !== currentProjectId) {
      return;
    }

    // Handle different event types
    switch (lastEvent.data.message.type) {
      case "TASK_QUEUED":
        setStatus("PENDING");
        environmentWithLatestDeployment.refetch();
        break;
      case "TASK_RUNNING":
        setStatus("STARTED");
        environmentWithLatestDeployment.refetch();
        break;
      case "TASK_COMPLETED":
        setStatus("COMPLETED");
        environmentWithLatestDeployment.refetch();
        break;
      case "CANCELED":
        setStatus("CANCELED");
        environmentWithLatestDeployment.refetch();
        break;
      default:
        // Ignore other event types
        break;
    }
  }, [lastEvent, env.id]);

  // Function to change the active pane
  const changePane = (pane) => {
    setCurrentPane(pane);
  };

  const hideProviderForm = () => changePane("latest-deployment");

  return (
    <Card className={style.container}>
      <CardHead
        className={classnames(style.header, {
          [style.passing]: status === "COMPLETED",
          [style.deploying]: ["PENDING", "STARTED", "CANCELING"].includes(
            status
          ),
          [style.canceling]: status === "CANCELING",
          [style.canceled]: status === "CANCELED",
          [style.failed]: ["FAILED", "ERRORED"].includes(status),
        })}
      >
        <Protected hasRole="USER">
          <MoveButton
            isDragging={isDragging}
            dragHandleProps={dragHandleProps}
          />
        </Protected>
        <div className={style.name} data-cy="environment-summary-title">
          {env.name}
        </div>
        <Actions
          env={env}
          project={project}
          providerClick={() => changePane("provider-config")}
        />
      </CardHead>

      <Window>
        <Pane id="deploy-tool" active={currentPane === "deploy-tool"}>
          <DeployTool
            project={project}
            environment={env}
            setError={setError}
            changePane={changePane}
          />
        </Pane>

        <Pane id="provider-config" active={currentPane === "provider-config"}>
          <ProviderForm
            onCancel={hideProviderForm}
            environmentId={env.id}
            onSubmit={hideProviderForm}
          />
        </Pane>

        <Pane
          id="latest-deployment"
          data-test-id="latest-deployment"
          active={currentPane === "latest-deployment" && !error}
        >
          <LatestDeployment
            project={project}
            environment={environmentWithLatestDeployment}
            onStatusChange={(newStatus) => setStatus(newStatus)}
            subscribeToMore={subscribeToMore}
            setError={setError}
            changePane={changePane}
          />
        </Pane>

        <Pane id="compare-tool" active={currentPane === "compare-tool"}>
          <CompareTool
            project={project}
            environment={environmentWithLatestDeployment}
            changePane={changePane}
          />
        </Pane>

        <Pane id="error" active={!!error}>
          <Error message={error} clearError={() => setError("")} />
        </Pane>
      </Window>
      <Tooltip disable={isDragging} />
    </Card>
  );
}

// PropTypes for non-TypeScript usage
EnvBox.propTypes = {
  env: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
  }).isRequired,
  isDragging: PropTypes.bool.isRequired,
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  subscribeToMore: PropTypes.func.isRequired,
  dragHandleProps: PropTypes.object,
};

EnvBox.defaultProps = {
  dragHandleProps: {},
};

export default EnvBox;
