import React, { ReactNode, useMemo } from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryKey,
  QueryFunction,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useAuth } from "components/Auth/AuthContext";
import { useTenant } from "../../context/TenantContext";
import config from "config";
import { useAppContext } from "context";

interface AuthenticatedQueryProviderProps {
  children: ReactNode;
}

type QueryConfig = {
  // Distinguishes between tenant and non-tenant paths
  requiresTenant?: boolean;
};

const buildUrl = (
  path: QueryKey,
  currentTenant?: string,
  queryConfig?: QueryConfig
): string => {
  const endpoint = Array.isArray(path) ? path[0] : path;
  const cleanPath =
    typeof endpoint === "string" && endpoint.startsWith("/")
      ? endpoint.slice(1)
      : endpoint;

  // If the path requires a tenant but none is provided, throw an error
  if (queryConfig?.requiresTenant && !currentTenant) {
    throw new Error(
      "A tenant is required for this endpoint but none was provided"
    );
  }

  const basePath = `${config.API.BASE_URL}/api/v2`;

  // If this is a tenant-specific endpoint, inject the tenant
  if (queryConfig?.requiresTenant && currentTenant) {
    return `${basePath}/tenants/${currentTenant}/${cleanPath}`;
  }

  // Otherwise, return default path
  return `${basePath}/${cleanPath}`;
};

const AuthenticatedQueryProvider: React.FC<AuthenticatedQueryProviderProps> = ({
  children,
}) => {
  const { token } = useAuth();
  const { currentTenant } = useTenant();
  const { logout } = useAppContext(); // Add this

  const queryClient = useMemo(() => {
    const queryFn: QueryFunction = async ({ queryKey, meta }) => {
      if (!token) {
        throw new Error("No authentication token available");
      }

      const [basePath, params] = Array.isArray(queryKey)
        ? [queryKey[0], queryKey[1]]
        : [queryKey, undefined];

      const queryConfig = meta as QueryConfig;
      const baseUrl = buildUrl(basePath, currentTenant, queryConfig);

      const url =
        params && typeof params === "object"
          ? `${baseUrl}?${new URLSearchParams(
              params as Record<string, string>
            ).toString()}`
          : baseUrl;

      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // If 401, we'll logout so we can reauth
      if (response.status === 401) {
        localStorage.removeItem("access_token");
        logout(); // redirect to login
        throw new Error("Unauthorized - clearing token");
      }

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    };

    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 60 * 1000,
          retry: (failureCount, error) => {
            // Don't retry on 401s
            if (
              error instanceof Error &&
              error.message.includes("Unauthorized")
            ) {
              return false;
            }
            return failureCount < 1;
          },
          refetchOnWindowFocus: false,
          queryFn,
        },
      },
    });
  }, [token, currentTenant, logout]);

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

/*
  Example usage:
  const createProject = useAuthenticatedMutation<Project, CreateProjectRequest>(
    'projects', 
    {
      requiresTenant: true, // This is the tenant-required option
      onSuccess: (newProject) => {},
      onError: (error) => {}
    }
  );
*/
export const useAuthenticatedMutation = <TData, TVariables>(
  path: string,
  options?: Omit<UseMutationOptions<TData, Error, TVariables>, "mutationFn"> & {
    requiresTenant?: boolean;
    method?: string;
    queryParams?: (variables: TVariables) => Record<string, string>;
    pathParams?: (variables: TVariables) => Record<string, string>;
    excludeBody?: boolean;
  }
) => {
  const { token } = useAuth();
  const { currentTenant } = useTenant();
  const { logout } = useAppContext();

  return useMutation<TData, Error, TVariables>({
    ...options,
    mutationFn: async (variables) => {
      // Replace path parameters if they exist
      let processedPath = path;
      let pathParamKeys: string[] = [];
      if (options?.pathParams) {
        const params = options.pathParams(variables);
        pathParamKeys = Object.keys(params);
        Object.entries(params).forEach(([key, value]) => {
          processedPath = processedPath.replace(`:${key}`, value);
        });
      }

      let url = buildUrl([processedPath], currentTenant, {
        requiresTenant: options?.requiresTenant,
      });

      // Add query parameters if they exist
      let queryParamKeys: string[] = [];
      if (options?.queryParams) {
        const params = options.queryParams(variables);
        queryParamKeys = Object.keys(params);
        const searchParams = new URLSearchParams(params);
        url = `${url}?${searchParams.toString()}`;
      }

      const method = options?.method ?? "POST";

      // will exclude any keys already in queryParams or pathParams
      let bodyToSend: any = undefined;
      
      if (!options?.excludeBody) {
        if ((queryParamKeys.length > 0 || pathParamKeys.length > 0) && variables) {
          const filteredVariables = { ...variables as object };
          
          // Remove query params from body
          queryParamKeys.forEach(key => {
            delete filteredVariables[key as keyof typeof filteredVariables];
          });
          
          // Remove path params from body
          pathParamKeys.forEach(key => {
            delete filteredVariables[key as keyof typeof filteredVariables];
          });
          
          bodyToSend = JSON.stringify(filteredVariables);
        } else {
          bodyToSend = JSON.stringify(variables);
        }
      }

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: bodyToSend,
      });

      if (response.status === 401) {
        localStorage.removeItem("access_token");
        logout();
        throw new Error("Unauthorized - clearing token");
      }

      if (!response.ok) {
        const message = await response.text();
        throw new Error(
          `Network response was not ok [${response.status}]: ${message}`
        );
      }

      return response.text().then(text => {
        if (!text) {
          return null;
        }
        return JSON.parse(text);
      });
    },
  });
};

// Example usage: const { data } = useQuery(useTenantQuery(['projects']));
export const useTenantQuery = (
  queryKey: QueryKey,
  params?: Record<string, string | number | boolean | undefined>
) => {
  // If we have params, add them as the second element of the queryKey array
  const finalQueryKey = params ? [queryKey, params] : queryKey;

  return {
    queryKey: finalQueryKey,
    meta: { requiresTenant: true }, // Always true since we're using the tenant-specific hook
  };
};

interface ResponseWithHeaders<T> {
  data: T;
  headers: Headers;
  extractedHeaders: Record<string, string | null>;
  response: Response;
}

const fetchWithHeaders = async <T,>(
  url: string,
  token: string,
  options: RequestInit = {}
): Promise<ResponseWithHeaders<T>> => {
  const response = await fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...options.headers,
    },
  });

  if (response.status === 401) {
    localStorage.removeItem("access_token");
    throw new Error("Unauthorized - clearing token");
  }

  if (!response.ok) {
    const message = await response.text();
    throw new Error(
      `Network response was not ok [${response.status}]: ${message}`
    );
  }

  // Clone the response before consuming it with .json()
  const responseClone = response.clone();
  const data = await response.json();
  
  // Convert headers to a plain object
  const extractedHeaders: Record<string, string> = {};
  responseClone.headers.forEach((value, key) => {
    extractedHeaders[key] = value;
  });

  return {
    data,
    headers: responseClone.headers,
    extractedHeaders,
    response: responseClone,
  };
};

export const useQueryWithHeaders = <TData,>(
  queryKey: QueryKey,
  options?: Omit<
    UseQueryOptions<ResponseWithHeaders<TData>, Error>,
    "queryKey"
  > & {
    requiresTenant?: boolean;
  }
) => {
  const { token } = useAuth();
  const { currentTenant } = useTenant();
  const { logout } = useAppContext();

  return useQuery<ResponseWithHeaders<TData>, Error>({
    ...options,
    queryKey,
    queryFn: async ({ queryKey, meta }) => {
      if (!token) {
        throw new Error("No authentication token available");
      }

      const [basePath, params] = Array.isArray(queryKey)
        ? [queryKey[0], queryKey[1]]
        : [queryKey, undefined];

      const baseUrl = buildUrl(basePath, currentTenant, options);

      const url =
        params && typeof params === "object"
          ? `${baseUrl}?${new URLSearchParams(
              params as Record<string, string>
            ).toString()}`
          : baseUrl;

      try {
        return await fetchWithHeaders<TData>(url, token);
      } catch (error) {
        if (error instanceof Error && error.message.includes("Unauthorized")) {
          logout();
        }
        throw error;
      }
    },
  });
};

export const useMutationWithHeaders = <TData, TVariables>(
  path: string,
  options?: Omit<
    UseMutationOptions<ResponseWithHeaders<TData>, Error, TVariables>,
    "mutationFn"
  > & {
    requiresTenant?: boolean;
    method?: string;
  }
) => {
  const { token } = useAuth();
  const { currentTenant } = useTenant();
  const { logout } = useAppContext();

  return useMutation<ResponseWithHeaders<TData>, Error, TVariables>({
    ...options,
    mutationFn: async (variables) => {
      const url = buildUrl([path], currentTenant, {
        requiresTenant: options?.requiresTenant,
      });

      if (!token) {
        throw new Error("No authentication token available");
      }

      try {
        return await fetchWithHeaders<TData>(url, token, {
          method: options?.method ?? "POST",
          body: JSON.stringify(variables),
        });
      } catch (error) {
        if (error instanceof Error && error.message.includes("Unauthorized")) {
          logout();
        }
        throw error;
      }
    },
  });
};

export default AuthenticatedQueryProvider;
