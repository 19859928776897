import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams, useNavigate } from "react-router-dom";
import { withRoutedPagination } from "components/RoutedPagination";
import Title from "components/Title";
import {
  Breadcrumbs,
  CardHead,
  Crumb,
  Tabs,
  Tab,
  Button,
  Alert,
} from "../../../modules/ui-kit-react/lib";
import { useQuery } from "@tanstack/react-query";
import {
  useTenantQuery,
  useQueryWithHeaders,
} from "components/Auth/AuthenticatedQueryProvider";
import DeploymentList from "../../../components/DeploymentList";
import Container from "../../../components/Container";
import SlackAlertSubscribe from "../../../components/SlackAlertSubscribe";
import TaskDefinitionDetails from "../../../components/TaskDefinitionDetails";
import Protected from "../../../components/Protected";
import style from "./style.module.css";
import {
  TenantFeature,
  Environment,
  Project,
  ProviderType,
  BasicLambdaConfiguration,
  EcsConfiguration,
  EcsScheduledTaskConfiguration,
  S3Configuration,
  TaskTestConfiguration,
  CodebuildConfiguration,
} from "types/api";

type Config =
  | BasicLambdaConfiguration
  | EcsConfiguration
  | EcsScheduledTaskConfiguration
  | S3Configuration
  | TaskTestConfiguration
  | CodebuildConfiguration;

interface EnvironmentsViewProps {
  page: number;
  changePage: (page: number) => void;
}

const useEnvironmentData = (environmentId: number) => {
  // Fetch environment details
  const {
    data: envData,
    error: envError,
    isLoading: envLoading,
  } = useQuery<Environment>({
    ...useTenantQuery([`environments/${environmentId}`])
  });

  // Fetch project details
  const {
    data: projectData,
    error: projectError,
    isLoading: projectLoading,
  } = useQuery<Project>({
    ...useTenantQuery([`projects/${envData && envData?.projectId}`]),
    enabled: !!envData,
  });

  // Fetch tenant features
  const {
    data: featureData,
    error: featureError,
    isLoading: featureLoading,
  } = useQuery<TenantFeature[]>({
    ...useTenantQuery(["features"]),
  });

  return {
    environment: envData,
    project: projectData,
    features: featureData,
    isLoading: envLoading || projectLoading || featureLoading,
    error: envError || projectError || featureError,
  };
};

const EnvironmentsView: React.FC<EnvironmentsViewProps> = ({
  page,
  changePage,
}) => {
  const [tab, setTab] = useState(0);
  const { id: environmentId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { environment, project, features, isLoading, error } =
    useEnvironmentData(Number(environmentId));

  // Fetch current provider configuration with headers
  const { data: response } = useQueryWithHeaders<Config>(
    [`environments/${environmentId}/deployment-configuration`],
    {
      requiresTenant: true,
    }
  );

  const submittedType = (response?.headers.get("X-Provider-Type") as ProviderType)

  if (isLoading) return null;
  if (error) throw error;
  if (!environment || !project) return;

  const isFeatureEnabled = (key: string) => {
    const feature = features?.find((f) => f.key === key);
    return feature ? feature.value : false;
  };

  const handlePageChange = (newPage: number) => {
    if (tab === 0) {
      changePage(newPage);
    }
  };

  const tabs = [
    <Tab label="Deployment History" key="deployment-history">
      <DeploymentList
        environment={environment}
        page={page}
        updatePage={handlePageChange}
      />
    </Tab>,
  ];

  if (submittedType === ProviderType.Ecs) {
    tabs.push(
      <Tab label="ECS Details" key="ecs-details">
        {isFeatureEnabled("ECS_DETAILS") ? (
          <TaskDefinitionDetails environmentId={environment.id} />
        ) : (
          <Alert
            message="This feature is disabled for this tenant."
            type="danger"
          />
        )}
      </Tab>
    );
  }

  return (
    <Container>
      <Title title={`${project.name} - Environment "${environment.name}"`} />

      <CardHead className={style.pageHead}>
        <Breadcrumbs>
          <Crumb>
            <Link to="../../projects">Projects</Link>
          </Crumb>
          <Crumb>
            <Link to={`../../projects/${environment.projectId}`}>
              {project.name}
            </Link>
          </Crumb>
          <Crumb active>{environment.name}</Crumb>
        </Breadcrumbs>
        <div className={style.actions}>
          <Protected hasRole={environment.privileged ? "ADMIN" : "USER"}>
            <Button
              appearance="primary"
              flat
              onClick={() => navigate(`../${environmentId}/edit`)}
              icon="edit"
            >
              Edit Environment
            </Button>
          </Protected>
          <SlackAlertSubscribe environment={environment} />
        </div>
      </CardHead>
      <Tabs
        disableTextAnimation
        index={tab}
        onChange={setTab}
        theme={{
          navigation: style.tabNavigation,
          label: style.tabLabel,
          pointer: style.tabPointer,
        }}
      >
        {tabs}
      </Tabs>
    </Container>
  );
};

EnvironmentsView.propTypes = {
  page: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default withRoutedPagination(EnvironmentsView);
