import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Button, 
  Menu, 
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Divider,
  Box
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CodeIcon from '@mui/icons-material/Code';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ProjectLinks = ({ project }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button 
        variant="text" 
        color="primary" 
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Links
      </Button>
      <Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem disabled>
            <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
              Repository
            </Typography>
          </MenuItem>
          <MenuItem 
            component="a" 
            href={project.repository} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <ListItemIcon>
              <CodeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{project.name}</ListItemText>
          </MenuItem>
          
          <Divider />
          <MenuItem disabled>
            <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
              Environments
            </Typography>
          </MenuItem>
          {project?.environments?.length > 0 ? (
            project.environments.map(env => (
              <MenuItem
                key={env.id}
                component="a"
                href={env.url}
                target="_blank"
                rel="noopener noreferrer"
                disabled={env.url == null}
              >
                <ListItemIcon>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{env.name}</ListItemText>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <Typography sx={{ fontStyle: 'italic', color: 'text.disabled' }}>
                None
              </Typography>
            </MenuItem>
          )}
          
          <Divider />
          <MenuItem disabled>
            <Typography sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
              References
            </Typography>
          </MenuItem>
          {project?.references?.length > 0 ? (
            project.references.map(ref => (
              <MenuItem
                key={ref.id}
                component="a"
                href={ref.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <OpenInNewIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{ref.name}</ListItemText>
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <Typography sx={{ fontStyle: 'italic', color: 'text.disabled' }}>
                None
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
    </React.Fragment>
  );
};

ProjectLinks.propTypes = {
  project: PropTypes.shape({
    environments: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    references: PropTypes.arrayOf(PropTypes.object),
    repository: PropTypes.string
  }).isRequired
};

export default ProjectLinks;
