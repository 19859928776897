import React, { createContext, useContext, useState, useEffect } from "react";

const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
  const [currentTenant, setCurrentTenant] = useState(() => localStorage.getItem("currentTenant") || "");

  useEffect(() => {
    if (currentTenant) {
      localStorage.setItem("currentTenant", currentTenant);
    } else {
      localStorage.removeItem("currentTenant");
    }
  }, [currentTenant]);

  return (
    <TenantContext.Provider value={{ currentTenant, setCurrentTenant }}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error("useTenant must be used within a TenantProvider");
  }
  return context;
};
