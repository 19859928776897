import React from "react";
import AwsProfileForm from "components/AwsProfileForm/index";
import Title from "components/Title";
import {
  Row,
  Col,
  CardHead, 
  CardContent,
  Alert,
} from "../../../../modules/ui-kit-react/lib";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { CreateAwsProfileRequest } from "types/api";

const AwsProfileNew: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createProfile = useAuthenticatedMutation<any, CreateAwsProfileRequest>(
    "providers/aws/profiles",
    {
      requiresTenant: true,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["providers/aws/profiles"] });
        navigate("../aws-profiles");
      },
    }
  );

  return (
    <>
      <Title title="New AWS Profile" />
      <CardHead>New AWS Profile</CardHead>

      <CardContent>
        <Row>
          <Col md={6} xs={12}>
            {createProfile.error && (
              <Alert type="danger" message={createProfile.error.message} />
            )}
            <AwsProfileForm
              gqlError={createProfile.error}
              loading={createProfile.isPending}
              onSubmit={(form: CreateAwsProfileRequest) => {
                createProfile.mutate({
                  name: form.name,
                  roleArn: form.roleArn,
                  externalId: form.externalId,
                })
              }
              }
            />
          </Col>
        </Row>
      </CardContent>
    </>
  );
};

export default AwsProfileNew;
