import React, { useState } from "react";

import BasicLambdaForm from "components/EnvBox/BasicLambdaForm";
import EcsForm from "components/EnvBox/EcsForm";
import EcsScheduledTaskForm from "components/EnvBox/EcsScheduledTaskForm";
import EksHelmForm from "components/EnvBox/EksHelmForm";
import S3Form from "components/EnvBox/S3Form";
import CodeBuildForm from "../CodeBuildForm";

import ProviderSelector from "../ProviderSelector";
import Tooltip from "../../Tooltip";
import { Box, Button, CircularProgress } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
  useQueryWithHeaders,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import {
  BasicLambdaConfiguration,
  EcsConfiguration,
  EcsScheduledTaskConfiguration,
  EksHelmConfiguration,
  S3Configuration,
  TaskTestConfiguration,
  CodebuildConfiguration,
  ProviderType,
} from "types/api";

import style from "./style.module.css";
import { useParams } from "react-router-dom";

type Config =
  | BasicLambdaConfiguration
  | EcsConfiguration
  | EcsScheduledTaskConfiguration
  | EksHelmConfiguration
  | S3Configuration
  | TaskTestConfiguration
  | CodebuildConfiguration;

const PROVIDER_CONFIG_MAP = {
  [ProviderType.Lambda]: "BasicLambdaConfiguration",
  [ProviderType.Ecs]: "EcsConfiguration",
  [ProviderType.EcsScheduledTask]: "EcsScheduledTaskConfiguration",
  [ProviderType.EksHelm]: "EksHelmConfiguration",
  [ProviderType.S3]: "S3Configuration",
  [ProviderType.CodeBuild]: "CodebuildConfiguration",
  [ProviderType.TaskTest]: "TaskTestConfiguration",
} as const;

type ConfigType = (typeof PROVIDER_CONFIG_MAP)[ProviderType];

const CONFIGURATION_PROVIDER_MAP: Record<ConfigType, ProviderType> =
  Object.entries(PROVIDER_CONFIG_MAP).reduce(
    (acc, [providerType, configType]) => ({
      ...acc,
      [configType]: providerType as ProviderType,
    }),
    {} as Record<ConfigType, ProviderType>
  );

const convertToApiProviderType = (
  uiType: import("components/ProviderSelection/useDisplayedProviderWarning").ProviderType
): import("types/api").ProviderType => {
  return CONFIGURATION_PROVIDER_MAP[uiType];
};

interface ProviderFormProps {
  environmentId: number;
  onSubmit: () => void;
  changePane: (s: string) => void;
  onCancel: () => void;
}

const ProviderForm = ({
  environmentId,
  onSubmit,
  changePane,
  onCancel,
}: ProviderFormProps) => {
  const { id: projectId } = useParams();
  const [createType, setCreateType] = useState<ProviderType | null>(null);
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  // Fetch current provider configuration with headers
  const { data: response, isLoading } = useQueryWithHeaders<Config>(
    [`environments/${environmentId}/deployment-configuration`],
    {
      requiresTenant: true,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      staleTime: 0,
    }
  );

  // Delete provider configuration
  const deleteConfig = useAuthenticatedMutation<void, void>(
    `environments/${environmentId}/deployment-configuration`,
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.removeQueries({
          queryKey: [`environments/${environmentId}/deployment-configuration`],
        });
        queryClient.invalidateQueries({
          queryKey: [`environments/${environmentId}`],
          exact: false,
        });
        queryClient.invalidateQueries({
          queryKey: [`projects/${projectId}`],
        });
        setCreateType(null);
        onCancel();
      },
    }
  );

  // Update/Create provider configuration
  const updateConfig = useAuthenticatedMutation<void, any>(
    `environments/${environmentId}/deployment-configuration`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`environments/${environmentId}/deployment-configuration`],
        });
        queryClient.invalidateQueries({
          queryKey: [`environments/${environmentId}`],
          exact: false,
        });
        queryClient.invalidateQueries({
          queryKey: [`projects/${projectId}`],
        });
        setCreateType(null);
        onSubmit();
      },
      onError: (err: any) => setError(err),
      queryParams: (variables) => ({
        providerType: variables.providerType,
      }),
    }
  );

  const handleCancel = () => {
    onCancel();
    changePane("latest-deployment");
  };

  const handleSubmit = (providerConfiguration: any) => {
    // If we're creating, use the createType directly
    // If we're updating, get the provider type from the response headers
    // If neither exists, try to determine it from the configuration type
    const submittedType =
      createType ||
      (response?.headers.get("X-Provider-Type") as ProviderType) ||
      CONFIGURATION_PROVIDER_MAP[
        type as keyof typeof CONFIGURATION_PROVIDER_MAP
      ];

    const { __typename, ...configWithoutTypename } =
      providerConfiguration.providerConfiguration;

    updateConfig.mutate({
      ...configWithoutTypename,
      providerType: submittedType,
    });
  };

  const renderDeleteButton = () => (
    <div className={style.delete}>
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBlock: 2
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={async () => {
            await deleteConfig.mutate();
            setCreateType(null);
          }}
          disabled={deleteConfig.isPending}
          startIcon={<i className="material-icons">delete</i>}
        >
          DELETE
        </Button>
      </Box>
      <Tooltip />
    </div>
  );

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const providerConfig = response?.data || {};
  const providerType = response?.headers.get("X-Provider-Type") as ProviderType;

  const create = !providerType;

  // Use the provider type from headers or createType for new configurations
  const type = create
    ? createType
      ? PROVIDER_CONFIG_MAP[createType]
      : null
    : PROVIDER_CONFIG_MAP[providerType];

  switch (type) {
    case "BasicLambdaConfiguration":
      return (
        <>
          {!create && renderDeleteButton()}
          <BasicLambdaForm
            environmentId={environmentId}
            configuration={providerConfig}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            gqlError={error}
          />
        </>
      );
    case "EcsConfiguration":
      return (
        <>
          {!create && renderDeleteButton()}
          <EcsForm
            environmentId={environmentId}
            ecsConfiguration={providerConfig}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            gqlError={error}
          />
        </>
      );
    case "S3Configuration":
      return (
        <>
          {!create && renderDeleteButton()}
          <S3Form
            environmentId={environmentId}
            s3Configuration={providerConfig}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            gqlError={error}
          />
        </>
      );
    case "EcsScheduledTaskConfiguration":
      return (
        <>
          {!create && renderDeleteButton()}
          <EcsScheduledTaskForm
            environmentId={environmentId}
            providerConfig={providerConfig}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            gqlError={error}
          />
        </>
      );
    case "EksHelmConfiguration":
      return (
        <>
          {!create && renderDeleteButton()}
          <EksHelmForm
            environmentId={environmentId}
            eksHelmConfiguration={providerConfig}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </>
      );
    case "CodebuildConfiguration":
      return (
        <>
          {!create && renderDeleteButton()}
          <CodeBuildForm
            environmentId={environmentId}
            providerConfig={providerConfig}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            gqlError={error}
          />
        </>
      );
    default:
      return (
        <ProviderSelector
          onSelect={(type) => setCreateType(convertToApiProviderType(type))}
          onCancel={() => changePane("latest-deployment")}
        />
      );
  }
};

export default ProviderForm;
