import React from 'react';
import Alert from '../../modules/ui-kit-react/lib/components/Alert';
import { CircularProgress } from '@mui/material';
import ReferenceForm from 'components/ReferenceForm';
import { useCreateReference } from 'hooks/project-hooks';
import style from './style.module.css';
import { Project } from 'types/api';

interface ReferenceInlineAddProps {
  onClose: () => void;
  project: Project;
}

const ReferenceInlineAdd: React.FC<ReferenceInlineAddProps> = ({
  onClose,
  project
}) => {
  const createReference = useCreateReference(project.id);

  const handleSubmit = async (form: { name: string; url: string }) => {
    try {
      await createReference.mutateAsync({
        name: form.name,
        url: form.url
      });
      onClose();
    } catch (error) {
      // Error handling is automatic through react-query
      console.error('Error creating reference:', error);
    }
  };

  return (
    <div className={style.container}>
      <div data-cy="reference-form">
        {createReference.isPending && <CircularProgress />}
        
        {createReference.error && (
          <Alert type="danger" message={createReference.error.message} />
        )}
        
        <div className={style.heading}>New Reference</div>
        
        <ReferenceForm
          gqlError={createReference.error}
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ReferenceInlineAdd;
