import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SpinnerOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  zIndex: theme.zIndex.modal + 1,
}));

const FullPageSpinner = ({ size = 40 }) => {
  return (
    <SpinnerOverlay>
      <CircularProgress 
        size={size}
        color="primary"
        thickness={4}
      />
    </SpinnerOverlay>
  );
};

export default FullPageSpinner;
