import { useState, useEffect, useRef, useCallback } from "react";
import config from "config";
import { useTenant } from "context/TenantContext";
import { useAuth } from "components/Auth/AuthContext";

type DeploymentEvent = {
  id: any;
  data: any;
  retry: any;
  event: any;
};

let globalEventSource: FetchStreamEventSource | null = null;
let globalEvents: DeploymentEvent[] = [];
let globalLastEvent: DeploymentEvent | null = null;
let subscribers: Set<Function> = new Set();
let activeConnection: boolean = false;
let currentTenantId: string | null = null;
let currentToken: string | null = null;

class FetchStreamEventSource {
  private controller: AbortController;
  private url: string;
  private token: string;
  private onmessage: (event: DeploymentEvent) => void;
  private onclose: () => void;
  private onerror: (error: Error) => void;
  private isConnected: boolean = false;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectDelay: number = 1000;
  
  constructor(
    url: string,
    token: string,
    onmessage: (event: DeploymentEvent) => void,
    onclose: () => void,
    onerror: (error: Error) => void
  ) {
    this.url = url;
    this.token = token;
    this.onmessage = onmessage;
    this.onclose = onclose;
    this.onerror = onerror;
    this.controller = new AbortController();
  }
  
  async connect(): Promise<void> {
    if (this.isConnected) return;
    
    try {
      const response = await fetch(this.url, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${this.token}`,
          "Accept": "text/event-stream",
          "Cache-Control": "no-cache",
        },
        signal: this.controller.signal,
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      if (!response.body) {
        throw new Error("Response body is null");
      }
      
      this.isConnected = true;
      this.reconnectAttempts = 0;
      
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";
      
      const processStream = async (): Promise<void> => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            
            if (done) {
              this.isConnected = false;
              this.onclose();
              return;
            }
            
            buffer += decoder.decode(value, { stream: true });
            
            const lines = buffer.split("\n\n");
            buffer = lines.pop() || "";
            
            for (const line of lines) {
              if (line.trim() === "" || line.includes(":heartbeat")) continue;
              
              const parsedEvent = this.parseEventString(line);
              if (parsedEvent) {
                this.onmessage(parsedEvent);
              }
            }
          }
        } catch (error: any) {
          if (error.name !== "AbortError") {
            this.onerror(error);
            this.isConnected = false;
            
            if (!this.controller.signal.aborted) {
              this.reconnect();
            }
          }
        }
      };
      
      processStream();
      
    } catch (error: any) {
      if (error.name !== "AbortError") {
        this.onerror(error);
        
        if (!this.controller.signal.aborted) {
          this.reconnect();
        }
      }
    }
  }
  
  private parseEventString(eventString: string): DeploymentEvent | null {
    try {
      const lines = eventString.split("\n");
      const eventObj: DeploymentEvent = {
        id: "",
        data: "",
        event: "",
        retry: null,
      };
      
      for (const line of lines) {
        if (!line.trim()) continue;
        
        const colonIndex = line.indexOf(":");
        if (colonIndex === -1) continue;
        
        const field = line.substring(0, colonIndex).trim();
        const value = line.substring(colonIndex + 1).trim();
        
        switch (field) {
          case "id":
            eventObj.id = value;
            break;
          case "data":
            eventObj.data = value;
            break;
          case "event":
            eventObj.event = value;
            break;
          case "retry":
            eventObj.retry = parseInt(value, 10);
            break;
        }
      }
      
      // Try to parse JSON data
      if (
        eventObj.data &&
        typeof eventObj.data === "string" &&
        eventObj.data.trim().startsWith("{") &&
        eventObj.data.trim().endsWith("}")
      ) {
        try {
          eventObj.data = JSON.parse(eventObj.data);
        } catch (parseError) {
          console.warn("Failed to parse data as JSON:", parseError);
        }
      }
      
      return eventObj;
    } catch (error) {
      console.error("Error parsing event:", error);
      return null;
    }
  }
  
  private reconnect(): void {
    if (this.controller.signal.aborted || this.reconnectAttempts >= this.maxReconnectAttempts) {
      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        console.error("Max reconnection attempts reached");
        this.onclose();
      }
      return;
    }
    
    this.reconnectAttempts++;
    const delay = this.reconnectDelay * Math.pow(1.5, this.reconnectAttempts - 1);
    
    console.log(`Reconnecting in ${delay}ms (attempt ${this.reconnectAttempts})`);
    
    setTimeout(() => {
      this.controller = new AbortController();
      this.connect();
    }, delay);
  }
  
  abort(): void {
    this.controller.abort();
    this.isConnected = false;
    this.onclose();
  }
}

function initializeEventSource(tenantId: string, token: string): void {
  // If connection is already established for the same tenant and token, do nothing
  if (activeConnection && tenantId === currentTenantId && token === currentToken) {
    return;
  }

  // Clean up any existing connection
  if (globalEventSource) {
    globalEventSource.abort();
    globalEventSource = null;
  }

  currentTenantId = tenantId;
  currentToken = token;

  const url = `${config.API.BASE_URL}/api/v2/tenants/${tenantId}/deployments/subscribe`;

  const eventSource = new FetchStreamEventSource(
    url,
    token,
    (eventObj: DeploymentEvent) => {
      globalEvents.push(eventObj);
      globalLastEvent = eventObj;
      
      // Notify all subscribers
      subscribers.forEach(callback => callback(eventObj));
    },
    () => {
      console.log("SSE connection closed");
      activeConnection = false;
    },
    (error) => {
      console.error("SSE connection error:", error);
      activeConnection = false;
    }
  );

  globalEventSource = eventSource;
  eventSource.connect();
  activeConnection = true;
}

// Reconnect singleton as needed
function reconnectEventSource(): void {
  if (currentTenantId && currentToken) {
    initializeEventSource(currentTenantId, currentToken);
  }
}

export function useDeploymentEvents() {
  const { currentTenant } = useTenant();
  const { token } = useAuth();
  const [events, setEvents] = useState<DeploymentEvent[]>(globalEvents);
  const [lastEvent, setLastEvent] = useState<DeploymentEvent | null>(globalLastEvent);

  const updateEvents = useCallback((newEvent: DeploymentEvent) => {
    setEvents([...globalEvents]); // explicitly triggers a re-render
    setLastEvent(newEvent);
  }, []);

  useEffect(() => {
    if (!currentTenant || !token) return;

    subscribers.add(updateEvents);

    if (!activeConnection || currentTenantId !== currentTenant || currentToken !== token) {
      initializeEventSource(currentTenant, token);
    }

    // Ensure state is synced with global state
    setEvents([...globalEvents]);
    setLastEvent(globalLastEvent);

    // remove this component from subscribers
    return () => {
      subscribers.delete(updateEvents);
      
      // If no more subscribers, close the connection
      if (subscribers.size === 0) {
        if (globalEventSource) {
          globalEventSource.abort();
          globalEventSource = null;
        }
        activeConnection = false;
      }
    };
  }, [currentTenant, token, updateEvents]);

  return {
    events,
    lastEvent,
    reconnect: reconnectEventSource,
  };
}
