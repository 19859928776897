import React from "react";
import { orderBy } from "lodash";
import Tooltip from "components/Tooltip";
import Title from "components/Title";
import AccessAdminCard from "components/AccessAdminCard";
import slack from "assets/Slack_Mark.svg";
import withAuth from "components/Auth";
import {
  Alert,
  CardContent,
  CardHead,
} from "../../../modules/ui-kit-react/lib";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import Container from "../../../components/Container";
import getUserRole from "../../../utils/getUserRole";
import { UserRole } from "types/api";

const Access: React.FC<{
  auth: {
    profile: { roles: string[] };
    tenant: string;
  };
}> = ({ auth: { profile, tenant } }) => {
  const { data, isLoading, error } = useQuery<{ userRoles: UserRole[] }>(
    useTenantQuery(["security/user-roles"])
  );

  if (error) return "error"; // waiting for error page PR
  if (isLoading) return null;

  const admins = orderBy(
    data?.userRoles.filter((user) => user?.roles?.includes("ADMIN")) || [],
    ({ updatedAt, displayName }) => [updatedAt, displayName],
    ["desc", "asc"]
  );

  const tenantName = tenant.toUpperCase();

  return (
    <Container>
      <Title title="Access" />
      <CardHead>{tenantName} Admins</CardHead>
      <CardContent>
        <Alert
          type="info"
          message={
            <>
              <div className={style.message}>
                Your current access level for this tenant is
                <strong> {getUserRole(profile.roles)}</strong>.
              </div>
              <div>
                If you need elevated access, you can reach out to one of the
                admins below.
              </div>
            </>
          }
        />

        <div className={style.admins}>
          {admins.map((user) => (
            <AccessAdminCard key={user.userId} user={user} />
          ))}
          {admins.length === 0 && (
            <div>
              There are no admins for this tenant. Contact the team via the
              Slack channel{" "}
              <a
                href="https://rvohealth.slack.com/archives/C03SXR1DGHY"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  alt="Send a Slack Message"
                  className={style.slackImage}
                  src={slack}
                />
                #support-redplatform
              </a>{" "}
              to get access.
            </div>
          )}
        </div>
      </CardContent>
      <Tooltip />
    </Container>
  );
};

export default withAuth(Access);
