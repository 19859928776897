import React from "react";
import classnames from "classnames";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { Deployment as DeploymentData, Environment } from "types/api";
import { Button } from "../../modules/ui-kit-react/lib";
import Protected from "../Protected";
import style from "./style.module.css";

interface CancelButtonProps {
  deployment: DeploymentData;
  environment: Environment;
}

const CancelButton: React.FC<CancelButtonProps> = ({
  deployment,
  environment,
}) => {
  const [canceling, setCanceling] = React.useState(false);

  const cancelDeployment = useAuthenticatedMutation<void, void>(
    `environments/${environment.id}/deployments`,
    {
      requiresTenant: true,
      method: "DELETE",
      onSettled: () => {
        setCanceling(false);
      }
    }
  );

  const supportsCancelation = () => environment.providerType === "ecs";

  const onCancel = () => {
    if (canceling) return;
    setCanceling(true);
    cancelDeployment.mutate();
  };

  if (deployment.status !== "STARTED" || !supportsCancelation()) {
    return null;
  }

  const buttonClass = classnames(
    style.status,
    style.danger,
    style.cancelButton
  );

  return (
    <Protected hasRole={environment.privileged ? "DEPLOYER" : "USER"}>
      <Button
        className={buttonClass}
        appearance="danger"
        onClick={onCancel}
        disabled={canceling}
        small
        lowered
        icon="close"
      >
        Cancel
      </Button>
    </Protected>
  );
};

export default CancelButton;
