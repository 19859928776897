import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HookTokenList from 'pages/Admin/HookTokens/List';

function HookTokens() {
  return (
    <Routes>
      <Route index element={<HookTokenList />} />
    </Routes>
  );
}

export default HookTokens;
