// @ts-nocheck
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import moment from "moment";
import ArtifactName from "components/ArtifactName";
import DeploymentLog from "components/DeploymentLog";
import { Button, Paper } from "../../modules/ui-kit-react/lib";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import CancelButton from "./CancelButton";
import style from "./style.module.css";
import GitRef from "../GitRef";
import {
  Deployment as DeploymentData,
  DeploymentLogEntry,
  Environment,
  Project,
} from "types/api";
import { useUser } from "hooks/security-hooks";
import { useDeploymentEvents } from "hooks/useDeploymentEventsInternal";
import { CircularProgress } from "@mui/material";

interface DeploymentProps {
  deployment: DeploymentData;
  environment: Environment;
}

const useProjectData = (environment: Environment) => {
  // Fetch project details
  const {
    data: projectData,
    error: projectError,
    isLoading: projectLoading,
  } = useQuery<Project>({
    ...useTenantQuery([`projects/${environment.projectId}`]),
    enabled: !!environment?.projectId,
  });

  return {
    project: projectData,
    isLoading: projectLoading,
    error: projectError,
  };
};

const useDeploymentLogs = (deployment: DeploymentData) => {
  // Fetch logs
  const {
    data: logs,
    error: logsError,
    isLoading: logsLoading,
  } = useQuery<DeploymentLogEntry[]>(
    useTenantQuery([`deployment/${deployment.id}/logs`])
  );

  return {
    logs,
    isLoading: logsLoading,
    error: logsError,
  };
};

const Deployment: React.FC<DeploymentProps> = ({ deployment, environment }) => {
  const [showLog, setShowLog] = useState(false);

  // Fetch detailed deployment data
  const { data, refetch, isLoading: isDeploymentLoading } = useQuery<{ payload: DeploymentData }>({
    ...useTenantQuery([`deployments/${deployment.id}`]),
    refetchInterval:
      deployment?.status &&
      ["PENDING", "STARTED", "CANCELING"].includes(deployment.status)
        ? 5000
        : false,
  });

  const { logs } = useDeploymentLogs(deployment);

  const { project } = useProjectData(environment);

  const detailedDeployment = data?.payload || deployment;

  const { data: user, isLoading: isUserLoading } = useUser(detailedDeployment.deployerID);

  const { lastEvent } = useDeploymentEvents();

  useEffect(() => {
    const currentEnvId = Number(environment.id);

    if (
      !lastEvent ||
      !lastEvent.data ||
      !lastEvent.data.message ||
      !lastEvent.data.message.meta
    ) {
      return;
    }

    const eventEnvId = lastEvent.data.message.meta.environmentId
      ? Number(lastEvent.data.message.meta.environmentId)
      : null;

    if (eventEnvId !== currentEnvId) {
      return;
    }

    // Handle different event types
    switch (lastEvent.data.message.type) {
      case "TASK_QUEUED":
        refetch();
        break;
      case "TASK_RUNNING":
        refetch();
        break;
      case "TASK_COMPLETED":
        refetch();
        break;
      default:
        // Ignore other event types
        break;
    }
  }, [lastEvent, environment.id]);

  useEffect(() => {
    if (
      deployment?.status &&
      ["PENDING", "STARTED"].includes(deployment.status)
    ) {
      setShowLog(true);
    }
  }, [deployment.status]);

  const renderAutoPromoted = ({ autoPromoted }: DeploymentData) =>
    autoPromoted ? "Yes" : "No";

  const renderDuration = (dep: DeploymentData) => {
    const noLogEntries = logs?.length === 0;
    const isActive = dep?.status && ["PENDING", "STARTED"].includes(dep.status);
    if (isActive || noLogEntries) {
      return "-";
    }

    const firstLogTime = dep.insertedAt;
    const lastLogTime = dep.updatedAt;
    const totalSeconds = moment
      .duration(moment(lastLogTime).diff(moment(firstLogTime)))
      .asSeconds();
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return totalSeconds > 59 ? `${minutes}m ${seconds}s` : `${seconds}s`;
  };

  const renderRef =
    (repository: string) =>
    ({ ref }: { ref: string }) =>
      <GitRef repo={repository} repoRef={ref} />;

  const renderDate = ({ insertedAt }: DeploymentData) =>
    moment(insertedAt).format("LLL");

  const renderStatus = ({ status }: DeploymentData) => {
    let icon;
    let className;
    let label;

    switch (status) {
      case "COMPLETED":
        icon = <i className="material-icons">done</i>;
        className = style.success;
        label = "Completed";
        break;
      case "PENDING":
      case "STARTED":
        icon = <i className={`material-icons ${style.rotate}`}>autorenew</i>;
        className = style.running;
        label = "Deploying";
        break;
      case "CANCELING":
        icon = <i className={`material-icons ${style.rotate}`}>autorenew</i>;
        className = style.cancel;
        label = "Canceling";
        break;
      case "CANCELED":
        icon = <i className="material-icons">close</i>;
        className = style.cancel;
        label = "Canceled";
        break;
      default:
        icon = <i className="material-icons">priority_high</i>;
        className = style.error;
        label = "Errored";
    }

    return (
      <span className={classnames(style.status, className)}>
        {icon}
        <span>{label}</span>
      </span>
    );
  };

  return (
    <li>
      <div
        className={classnames(style.leftSide, {
          [style.success]: deployment.status === "COMPLETED",
          [style.running]:
            deployment?.status &&
            ["PENDING", "STARTED"].includes(deployment?.status),
          [style.cancel]:
            deployment?.status &&
            ["CANCELED", "CANCELING"].includes(deployment.status),
          [style.error]:
            deployment?.status &&
            ["FAILED", "ERRORED"].includes(deployment.status),
        })}
      >
        <div className={style.artifactName}>
          <ArtifactName artifactName={deployment.artifactName} />
        </div>
        <div className={style.time}>{renderDate(deployment)}</div>
      </div>
      <Paper className={style.details}>
        <div>
          <label>Status</label>
          {isDeploymentLoading ? (
            <CircularProgress size={20} />
          ) : (
            renderStatus(deployment)
          )}

          <CancelButton deployment={deployment} environment={environment} />
        </div>
        <div>
          <label>Deployed By</label>
          {isUserLoading ? (
            <CircularProgress size={20} />
          ) : (
            user?.displayName || "-"
          )}
        </div>
        <div>
          <label>Ref</label>
          {isDeploymentLoading ? (
            <CircularProgress size={20} />
          ) : (
            deployment &&
            renderRef(project?.repository || "")({
              ref: deployment?.artifactName,
            })
          )}
        </div>
        <div>
          <label>Auto promoted</label>
          {isDeploymentLoading ? (
            <CircularProgress size={20} />
          ) : (
            renderAutoPromoted(deployment)
          )}
        </div>
        <div>
          <label>Duration</label>
          {isDeploymentLoading ? (
            <CircularProgress size={20} />
          ) : (
            renderDuration(detailedDeployment)
          )}
        </div>
        <div className={style.deploymentLogWrap}>
          <label>Deployment Log</label>
          <Button
            flat
            appearance="primary"
            small
            onClick={() => setShowLog(!showLog)}
            icon={showLog ? "arrow_drop_up" : "arrow_drop_down"}
            disabled={isDeploymentLoading}
          >
            {showLog ? "Hide" : "View"} Log
          </Button>
          <div
            className={classnames(style.deploymentLog, {
              [style.open]: showLog,
            })}
          >
            {showLog && !isDeploymentLoading && (
              <DeploymentLog
                subscribe={
                  deployment?.status &&
                  ["PENDING", "STARTED", "CANCELING"].includes(
                    deployment.status
                  )
                }
                deployment={detailedDeployment}
                project={project}
                environment={environment}
              />
            )}
            {showLog && isDeploymentLoading && (
              <div className={style.loaderContainer}>
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
      </Paper>
    </li>
  );
};

export default Deployment;
