import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import { Deployment, DeploymentStatus, Environment } from "types/api";

interface DeploymentResponse {
  entries: Deployment[];
}
export interface EnvironmentWithLatestDeployment extends Environment {
  lastDeployment?: {
    artifactName: string;
    id: number;
    status?: DeploymentStatus;
    ref?: string;
    autoPromoted?: boolean;
    deployerId?: number;
    insertedAt?: string;
  };
}

export const useEnvironmentWithLatestDeployment = (
  environment: Environment
): EnvironmentWithLatestDeployment & { refetch: () => Promise<any> } => {
  const { data, refetch } = useQuery<
    DeploymentResponse,
    Error,
    EnvironmentWithLatestDeployment
  >({
    ...useTenantQuery([`environments/${environment.id}/deployments`], {
      pageSize: 1,
    }),
    select: (data) => ({
      ...environment,
      lastDeployment: data.entries[0]
        ? {
            artifactName: data.entries[0].artifactName,
            id: data.entries[0].id,
            status: data.entries[0].status,
            ref: data.entries[0].ref,
            autoPromoted: data.entries[0].autoPromoted,
            deployerId: data.entries[0].deployerId,
            insertedAt: data.entries[0].insertedAt,
          }
        : undefined,
    }),
  });

  return {
    ...(data || { ...environment, lastDeployment: undefined }),
    refetch,
  };
};

export default useEnvironmentWithLatestDeployment;
