import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Title from 'components/Title';
import { Paper, Alert, Row, Col } from '../../../modules/ui-kit-react/lib';
import AuditLog from '../../../components/AuditLog';

import style from './style.module.css';
import Container from '../../../components/Container';

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <Title title="Dashboard" />

        <Container>
          <Alert
            type="warning"
            message={
              <React.Fragment>
                {
                  "You haven't setup any AWS profiles yet. You will need a profile before using any deployment features. "
                }
                <Link to="aws-profiles/new">Add an AWS Profile</Link>
              </React.Fragment>
            }
          />
        </Container>
        <Row className={style.dashboard}>
          <Col sm={6}>
            <Paper className={style.section}>
              <h2>Activity</h2>
              <AuditLog limit={10} pagination={false} />
            </Paper>
          </Col>
          <Col sm={6}>
            <Paper className={style.section}>
              <h2>Stats</h2>
            </Paper>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Dashboard;
