import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { ROLES, highestRole } from 'components/Auth/roles';
import TextInput from 'components/Form/TextInput';
import SelectInput from 'components/Form/SelectInput';
import { get } from 'lodash';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import { Form } from '../../modules/react-forms/lib';
import styles from './style.module.css';
import extractFieldError from '../../utils/gqlErrors';

function UserRoleForm({
  onSubmit,
  loading,
  userRole = {},
  edit = false,
  gqlError = null,
}) {
  const navigate = useNavigate();
  const { tenant } = useParams();

  const cancel = () => {
    navigate(`/${tenant}/admin/user-roles`);
  };

  const getError = (field) => {
    return extractFieldError(gqlError, field);
  };

  return (
    <Form onValidSubmit={onSubmit}>
      <TextInput
        name="name"
        label="Name"
        disabled
        defaultValue={get(userRole, 'user.username')}
        error={getError('name')}
      />

      <SelectInput
        name="role"
        label="Role"
        required
        defaultValue={highestRole(userRole.roles || [])}
        source={ROLES.map((r) => ({ value: r, label: r }))}
        error={getError('role')}
      />

      <div className={styles.actions}>
        <Button
          name="save"
          disabled={loading}
          raised
          appearance="primary"
          type="submit"
        >
          {edit ? 'Update' : 'Create'}
        </Button>
        <Button
          name="cancel"
          disabled={loading}
          lowered
          appearance="danger"
          onClick={cancel}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}

UserRoleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  userRole: PropTypes.shape({
    id: PropTypes.string,
    roles: PropTypes.array,
  }),
  edit: PropTypes.bool,
  gqlError: PropTypes.shape({
    graphQLErrors: PropTypes.array,
  }),
};

export default UserRoleForm;
