import React from "react";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { useQueryClient } from '@tanstack/react-query';
import { Form } from "../../modules/react-forms/lib";
import { Project, UpdateProjectRequest } from "types/api";

import TextInput from "components/Form/TextInput";

import { 
  Button, 
  CircularProgress, 
  Box,
  Grid,
  Typography
} from "@mui/material";

import style from "./style.module.css";

const fieldContainerStyle = {
  minHeight: '70px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start'
};

interface ProjectBadgeFormProps {
  onSuccess: () => void;
  onCancel: () => void;
  project: Project;
}

interface BadgeFormValues {
  url: string;
  imgSrc: string;
}

const ProjectBadgeForm: React.FC<ProjectBadgeFormProps> = ({
  onSuccess,
  onCancel,
  project,
}) => {
  const queryClient = useQueryClient();
  const updateProject = useAuthenticatedMutation<void, UpdateProjectRequest>(
    `projects/${project.id}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`projects/${project.id}`]
        });
        onSuccess();
      },
    }
  );

  const handleSubmit = (badge: BadgeFormValues) => {
    if (!project.name || !project.repository) return;

    const existingBadges = project.badges || [];

    updateProject.mutate({
      name: project.name,
      description: project.description,
      repository: project.repository,
      badges: [badge, ...existingBadges],
    });
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Add New Badge
      </Typography>

      {updateProject.isPending && (
        <Box display="flex" justifyContent="center" my={2}>
          <CircularProgress size={24} />
        </Box>
      )}

      <Form onValidSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={4}>
            <Box sx={fieldContainerStyle}>
              <TextInput
                name="url"
                type="text"
                placeholder="Badge URL"
                required
                size="small"
                fullWidth
                margin="dense"
                inputProps={{
                  className: style.input,
                  style: { fontSize: '0.7rem', lineHeight: 1 }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={fieldContainerStyle}>
              <TextInput
                name="imgSrc"
                type="text"
                placeholder="Badge Image Source"
                required
                size="small"
                fullWidth
                margin="dense"
                inputProps={{
                  className: style.input,
                  style: { fontSize: '0.7rem', lineHeight: 1 }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" gap={1} sx={{ height: '100%', alignItems: 'flex-start' }}>
              <Button 
                variant="contained" 
                size="small" 
                color="primary"
                type="submit"
              >
                Create
              </Button>
              <Button 
                variant="outlined" 
                size="small" 
                color="error" 
                onClick={onCancel}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default ProjectBadgeForm;
