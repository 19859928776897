import React from "react";
import { MultiSelect } from "../../modules/ui-kit-react/lib";
import { Props as MenuProps } from "react-select";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import { ProjectGroup } from "types/api";

type Props = MenuProps;

const ProjectGroupSelector = ({ ...rest }: Props) => {
  const { data, isLoading, error } = useQuery<{
    projectGroups: { entries: ProjectGroup[] };
  }>(useTenantQuery(["project-groups"]));

  if (isLoading) return <span>...</span>;
  if (error || !data?.projectGroups)
    return <span>Unable to load project groups.</span>;

  const projectGroups = data.projectGroups.entries;

  return (
    <MultiSelect
      label="Project Groups"
      options={
        projectGroups?.length
          ? projectGroups.map((p) => ({
              label: p.name,
              value: p.id,
            }))
          : ([{ label: "None", value: "none" }] as any)
      }
      {...rest}
    />
  );
};

export default ProjectGroupSelector;
