import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../modules/ui-kit-react/lib";
import Protected from "../Protected";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { Environment, Project } from "types/api";

const MySwal = withReactContent(Swal);

const ProjectActions = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const { tenant } = useParams();

  const archiveProject = useAuthenticatedMutation<void, void>(
    `projects/${project.id}/archive`,
    {
      requiresTenant: true,
      method: "POST",
      onSuccess: () => navigate(`/${tenant}/projects`),
    }
  );

  const deleteProject = useAuthenticatedMutation<void, void>(
    `projects/${project.id}`,
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => navigate(`/${tenant}/projects`),
    }
  );

  const shouldArchive = () =>
    project?.environments?.length ||
    project?.environments?.filter((e: Environment) => e.archived).length;

  const handleRemoveClick = () => {
    const verb = shouldArchive() ? "archive" : "delete";
    const mutation = shouldArchive() ? archiveProject : deleteProject;

    MySwal.fire({
      icon: "warning",
      title: `Are you sure you want to ${verb} ${project.name}?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#d33",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        mutation.mutate();
      }
    });
  };

  return (
    <>
      <Protected hasRole="ADMIN">
        <Button
          flat
          appearance="primary"
          onClick={() =>
            navigate(`/${tenant}/projects/${project.id}/environments/new`)
          }
          icon="add"
        >
          Create Environment
        </Button>
      </Protected>
      <Protected hasRole="USER">
        <Button
          flat
          appearance="primary"
          onClick={() => navigate(`/${tenant}/projects/${project.id}/edit`)}
          icon="edit"
        >
          Edit
        </Button>
      </Protected>
      <Protected hasRole="ADMIN">
        <Button
          flat
          appearance="danger"
          icon={shouldArchive() ? "archive" : "delete"}
          onClick={(e) => {
            e.preventDefault();
            handleRemoveClick();
          }}
        >
          {shouldArchive() ? "Archive" : "Delete"}
        </Button>
      </Protected>
    </>
  );
};

export default ProjectActions;
