import React from 'react';
import Alert from '../../modules/ui-kit-react/lib/components/Alert';
import { CircularProgress } from '@mui/material';
import SlackAlertForm from 'components/SlackAlertForm';
import { useUpdateSlackAlert } from 'hooks/slack-hooks';
import { getStatusEnumsFromForm } from 'utils/enums';
import style from './style.module.css';
import { SlackAlert } from 'types/api';

interface SlackAlertInlineEditProps {
  onClose: () => void;
  alert: SlackAlert;
  environmentId: number;
}

const SlackAlertInlineEdit: React.FC<SlackAlertInlineEditProps> = ({
  onClose,
  alert,
  environmentId,
}) => {
  const updateAlert = useUpdateSlackAlert(alert.id);

  const handleSubmit = async (form: { channel: string; on: string[] }) => {
    try {
      await updateAlert.mutateAsync({
        channel: form.channel,
        on: getStatusEnumsFromForm(form.on)
      });
      onClose();
    } catch (error) {
      // Error handling is automatic through react-query
      console.error('Error updating slack alert:', error);
    }
  };

  return (
    <div className={style.container}>
      {updateAlert.isPending && <CircularProgress />}
      
      {updateAlert.error && (
        <Alert type="danger" message={updateAlert.error.message} />
      )}
      
      <div className={style.heading}>Edit Alert</div>
      
      <SlackAlertForm
        edit
        gqlError={updateAlert.error}
        onCancel={onClose}
        alert={alert}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default SlackAlertInlineEdit;
