import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from '../style.module.css';

const MoveButton = ({ isDragging, dragHandleProps }) => {
  const buttonRef = React.useRef();

  useEffect(() => {
    if (isDragging && buttonRef.current) {
      ReactTooltip.hide(buttonRef.current);
    }
  }, [isDragging]);

  return (
    <i
      {...dragHandleProps}
      className={classnames('material-icons', style.moveButton)}
      data-tip="Move environment"
      ref={buttonRef}
    >
      drag_indicator
    </i>
  );
};

MoveButton.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  dragHandleProps: PropTypes.object.isRequired
};

export default MoveButton;