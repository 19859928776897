import React, { useState } from 'react';
import { Button } from '../../modules/ui-kit-react/lib';
import { useAuth } from 'components/Auth/AuthContext';
import { useCreateSlackAlert, useDeleteSlackAlert, useListSlackAlerts } from 'hooks/slack-hooks';
import { Environment } from 'types/api';
import style from './style.module.css';

interface SlackAlertSubscribeProps {
  environment: Environment;
}

const SlackAlertSubscribe: React.FC<SlackAlertSubscribeProps> = ({ environment }) => {
  const [showSlackLoginMsg, setShowSlackLoginMsg] = useState(false);
  const { slackId } = useAuth();

  const { data: slackAlerts } = useListSlackAlerts(environment.id);
  
  const createAlert = useCreateSlackAlert(environment.id);
  const deleteAlert = useDeleteSlackAlert(environment.id);

  if (!slackAlerts) return null;

  const existingAlert = slackAlerts.find(
    alert => alert.channel === `@${slackId}`
  );

  const togglePersonalSlackAlert = async () => {
    if (!slackId) {
      setShowSlackLoginMsg(true);
      return;
    }

    try {
      if (existingAlert) {
        await deleteAlert.mutateAsync({ id: existingAlert.id });
      } else {
        await createAlert.mutateAsync({
          channel: `@${slackId}`,
          on: [
            'ERRORED',
            'FAILED',
            'COMPLETED',
            'STARTED',
            'PENDING',
            'CANCELED',
            'CANCELING'
          ],
          environmentId: environment.id
        });
      }
    } catch (error) {
      // Error handling is automatic through react-query
      console.error('Error toggling slack alert:', error);
    }
  };

  const renderError = (message: string) => (
    <div className={style.error}>
      <i className="material-icons">error_outline</i>
      <span>{message}</span>
    </div>
  );

  return (
    <div className={style.wrap}>
      {showSlackLoginMsg && renderError(
        "Your Slack account has not been linked to Shield. Type '/shield login' in any slack channel."
      )}
      
      {(createAlert.error || deleteAlert.error) && renderError(
        (createAlert.error || deleteAlert.error)?.message || 'An error occurred'
      )}
      
      {false && (
        <Button
          data-cy="slack-notification-sub-btn"
          raised
          appearance={existingAlert ? 'success' : 'primary'}
          onClick={togglePersonalSlackAlert}
          icon={existingAlert ? 'notifications' : 'notifications_off'}
        >
          {existingAlert
            ? 'Unsubscribe to Slack Notifications'
            : 'Subscribe via Slack'}
        </Button>
      )}
    </div>
  );
};

export default SlackAlertSubscribe;
