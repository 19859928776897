import React from 'react';
import { CircularProgress } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';

import logo from 'assets/logo.png';
import style from './style.module.css';

const LoadingPage = () => {
  return (
    <div className={style.container}>
      <div className={style.logoContainer}>
        <img className={classnames(style.i1)} src={logo} alt="logo" />
        <img className={classnames(style.i2)} src={logo} alt="logo" />
        <img className={classnames(style.i3)} src={logo} alt="logo" />
      </div>
      <CircularProgress 
        size={40}
        color="primary"
        thickness={4}
      />
    </div>
  );
};

const TIME_TO_RENDER = 500;
const MIN_RENDER_TIME = 1500;

export class LoadingController extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.func.isRequired
  };

  state = {
    renderLoading: false,
    renderContent: false
  };

  static getDerivedStateFromProps(props, state) {
    const { loading } = props;

    if (loading && state.renderContent) {
      return {
        renderLoading: false,
        renderContent: false
      };
    }

    return state;
  }

  componentDidMount = () => {
    const { loading } = this.props;

    if (loading) {
      this.resetRenderTimes();
    } else {
      this.setState({ renderContent: true });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.loadingDelay);
  };

  componentDidUpdate = prevProps => {
    const { loading } = this.props;
    const { renderLoading } = this.state;
    const now = new Date().getTime();

    if (prevProps.loading && !loading) {
      clearTimeout(this.loadingDelay);

      if (!renderLoading) {
        this.setState({ renderContent: true });
      } else if (now < this.hideLoadingAt) {
        setTimeout(this.stopLoading.bind(this), this.hideLoadingAt - now);
      } else {
        this.stopLoading();
      }
    } else if (!prevProps.loading && loading) {
      this.resetRenderTimes();
    }
  };

  resetRenderTimes = () => {
    const now = new Date().getTime();
    this.hideLoadingAt = now + TIME_TO_RENDER + MIN_RENDER_TIME;

    this.loadingDelay = setTimeout(() => {
      this.setState({ renderLoading: true });
    }, TIME_TO_RENDER);
  };

  stopLoading = () => {
    this.setState({
      renderLoading: false,
      renderContent: true
    });
  };

  render() {
    const { children } = this.props;
    const { renderContent, renderLoading } = this.state;

    if (renderLoading) {
      return <LoadingPage />;
    }

    if (renderContent) {
      return children();
    }

    return null;
  }
}

export default LoadingPage;
