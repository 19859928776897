import React, { Component } from 'react';
import AuditLog from 'components/AuditLog';
import Title from 'components/Title';
import { CardHead, CardContent } from '../../../../modules/ui-kit-react/lib';

class AuditLogsList extends Component {
  render() {
    return (
      <React.Fragment>
        <Title title="Audit Log" />

        <CardHead>Audit Log</CardHead>

        <CardContent>
          <AuditLog />
        </CardContent>
      </React.Fragment>
    );
  }
}

export default AuditLogsList;
