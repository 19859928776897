import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useAuthenticatedMutation,
  useTenantQuery,
} from "components/Auth/AuthenticatedQueryProvider";
import Container from "components/Container";
import ProjectGroupForm from "components/ProjectGroups/Form";
import Title from "components/Title";
import ProjectGroupProjectsList from "components/ProjectGroups/ProjectsList";
import GroupProjectGroupsList from "components/ProjectGroups/ProjectGroupsList";
import { Form } from "../../../modules/react-forms/lib";
import {
  Alert,
  Card,
  CardHead,
  CardContent,
  Button,
  Row,
  Col,
} from "../../../modules/ui-kit-react/lib";
import { useNavigate, useParams } from "react-router-dom";
import style from "./style.module.css";
import { ProjectGroup } from "types/api";

function ProjectGroupsEdit() {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: projectGroup,
    error,
    isLoading,
  } = useQuery<ProjectGroup>(useTenantQuery([`project-groups/${id}`, id]));

  const { mutate: updateProjectGroup, error: updateError, isPending } = useAuthenticatedMutation<any, any>(
    `project-groups/${id}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        navigate(`../${id}`);
      },
    }
  );

  // Loading state
  if (isLoading || !projectGroup) return null;

  // Fetch errors
  if (error) {
    return <Alert type="danger" message={error.message} />;
  }

  const handleSubmit = (input: any) => {
    updateProjectGroup(input);
  };

  return (
    <Container>
      <Title title={`Edit Project Group "${projectGroup.name}"`} />

      <Card className={style.mainCard}>
        <CardHead className={style.header}>
          <div>{projectGroup.name}</div>
          <div>
            <Button
              flat
              appearance="primary"
              onClick={() => navigate(`../${projectGroup.id}`)}
              icon="arrow_back"
            >
              Back to Project Group
            </Button>
          </div>
        </CardHead>

        <CardContent className={style.cardContent}>
          <Row>
            <Col sm={6} className={style.cardColumn}>
              <Card className={style.innerCard}>
                <CardHead>Group Details</CardHead>
                <CardContent>
                  {updateError && (
                    <Alert
                      message={updateError.message}
                      type="danger"
                    />
                  )}

                  <Form onValidSubmit={handleSubmit}>
                    <ProjectGroupForm projectGroup={projectGroup} />
                    <Button raised appearance="primary" small>
                      {isPending ? "Updating..." : "Update"}
                    </Button>
                  </Form>
                </CardContent>
              </Card>
            </Col>

            <Col sm={6} className={style.cardColumn}>
              <div className={style.rightColumnCards}>
                <Card className={style.innerCard}>
                  <ProjectGroupProjectsList projectGroup={projectGroup} />
                </Card>
                
                <Card className={`${style.innerCard} ${style.secondCard}`}>
                  <GroupProjectGroupsList projectGroup={projectGroup} />
                </Card>
              </div>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ProjectGroupsEdit;
