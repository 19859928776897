import React, { useMemo } from "react";
import Container from "components/Container";
import ProjectForm from "components/ProjectForm";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import Title from "components/Title";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Card,
  CardHead,
  CardContent,
  Breadcrumbs,
  Crumb,
} from "../../../modules/ui-kit-react/lib";

const ProjectsNew = () => {
  const navigate = useNavigate();

  const createProject = useAuthenticatedMutation("projects", {
    requiresTenant: true,
    onSuccess: (data) => {
      navigate(`../${data.id}`);
    },
  });

  const errors = useMemo(() => {
    if (!createProject.error || !("errors" in createProject.error)) {
      return {};
    }
    return createProject.error.errors;
  }, [createProject.error]);

  return (
    <Container>
      <Title title="New Project" />
      <CardHead>
        <Breadcrumbs>
          <Crumb>
            <Link to="..">Projects</Link>
          </Crumb>
          <Crumb active>New</Crumb>
        </Breadcrumbs>
      </CardHead>

      <CardContent>
        <h1>New Project</h1>
        <Row>
          <Col md={6} xs={12}>
            <Card>
              <CardHead>Project Details</CardHead>
              <CardContent>
                {createProject.error && !("errors" in createProject.error) && (
                  <Alert type="danger" message={createProject.error.message} />
                )}

                <ProjectForm
                  onSubmit={(formData) => {
                    createProject.mutate(formData);
                  }}
                  loading={createProject.isPending}
                  errors={errors}
                />
              </CardContent>
            </Card>
          </Col>
        </Row>
      </CardContent>
    </Container>
  );
};

export default ProjectsNew;
