const isNonProd = window.location.origin.includes('nonprod');

// Defaults (production)
const config = {
  API: {
    BASE_URL: isNonProd ? 'https://api.shield-nonprod.rvohealth.com' : 'https://api.shield.rvohealth.com',
  },
  AUTH: {
    CLIENT_ID: isNonProd ? 'a4192e23-d7aa-40ae-8325-fcd712c1468d' : 'b276faf6-e3c1-41f6-b258-80001af4cf83',
    AUTHORIZATION_URL: 'https://login.microsoftonline.com/00e1df3d-9626-410c-898c-16aaa8c2afc9/oauth2/v2.0/authorize',
    TOKEN_URL: 'https://login.microsoftonline.com/00e1df3d-9626-410c-898c-16aaa8c2afc9/oauth2/v2.0/token ',
    SCOPE: isNonProd ? 'a4192e23-d7aa-40ae-8325-fcd712c1468d/.default' : 'b276faf6-e3c1-41f6-b258-80001af4cf83/.default',
    REDIRECT_URI: `${window.location.origin}/oauth/callback`
  }
};

// For developing against non-prod (the default)
if (process.env.NODE_ENV === 'development') {
  // Dev-specific values
  config.AUTH.REDIRECT_URI = 'http://localhost:3000/oauth/callback';
  config.API.BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL :  'https://api.shield-nonprod.rvohealth.com';
}

if (process.env.NODE_ENV !== 'production') {
  config.AUTH.CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID ? process.env.REACT_APP_AUTH_CLIENT_ID : 'a4192e23-d7aa-40ae-8325-fcd712c1468d';
  config.AUTH.SCOPE = process.env.REACT_APP_AUTH_SCOPE ? process.env.REACT_APP_AUTH_SCOPE : 'a4192e23-d7aa-40ae-8325-fcd712c1468d/.default';
}

export default config;
