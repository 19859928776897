import React, { useState, useRef } from "react";
import Button from "../../modules/ui-kit-react/lib/components/Button";
// @ts-expect-error
import { Form, Validators } from "../../modules/react-forms/lib";
import { get } from "lodash";
import TextInput from "components/Form/TextInput";
import extractFieldError from "utils/gqlErrors";
import SelectInput from "components/Form/SelectInput";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import {
  RadioGroup,
  RadioButton,
} from "../../modules/ui-kit-react/lib/components/Radio";
import style from "./style.module.css";
import DeploymentStatusOptions from "../DeploymentStatusOptions";
import LambdaUrl from "./LambdaUrl";
import getWebHookType from "./util";
import { AwsProfile, WebHook } from "types/api";

interface WebHookFormProps {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  webHook?: WebHook;
  edit?: boolean;
  gqlError?: {
    graphQLErrors: any[];
  };
}

const WebHookForm: React.FC<WebHookFormProps> = ({
  onSubmit,
  onCancel,
  webHook,
  edit = false,
  gqlError = null,
}) => {
  // @ts-expect-error
  const formRef = useRef<Form>(null);
  const [type, setType] = useState(getWebHookType(webHook?.url || ""));

  // Fetch AWS profiles
  const { data: profilesData } = useQuery<AwsProfile[]>({
    ...useTenantQuery(["providers/aws/profiles"]),
    enabled: type === "lambda",
  });

  const getError = (field: string) => {
    return extractFieldError(gqlError, field);
  };

  return (
    <Form
      //@ts-expect-error
      className={style.form}
      onValidSubmit={onSubmit}
      ref={formRef}
    >
      {!edit && (
        <fieldset className={style.radioFieldset}>
          <legend>WebHook Type *</legend>

          <RadioGroup
            //@ts-expect-error
            label="WebHook Type"
            name="type"
            value={type}
            required
            className={style.radios}
            //@ts-expect-error
            onChange={(value) => setType(value)}
          >
            <RadioButton label="HTTPS" value="https" />
            <RadioButton label="Lambda" value="lambda" />
          </RadioGroup>
        </fieldset>
      )}

      {type === "https" && (
        <TextInput
          name="url"
          type="text"
          placeholder="URL"
          className={style.input}
          defaultValue={get(webHook, "url")}
          error={getError("url")}
          required
        />
      )}

      {type === "lambda" && (
        <>
          <SelectInput
            name="awsProfileId"
            label="AWS Profile"
            defaultValue={get(webHook, "awsProfile.id")}
            required
            source={
              profilesData?.map((profile) => ({
                value: profile.id,
                label: profile.name,
              })) || []
            }
            error={getError("awsProfile.id")}
            validation={!Validators.isEmpty}
          />
          <LambdaUrl name="url" defaultValue={get(webHook, "url")} />
        </>
      )}

      <DeploymentStatusOptions selected={webHook?.on} />

      <div className={style.formActions}>
        <Button
          raised
          small
          appearance="primary"
          onClick={(e) => {
            e.preventDefault();
            formRef.current?.submit();
          }}
        >
          {edit ? "Update" : "Create"}
        </Button>

        <Button
          flat
          small
          appearance="danger"
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default WebHookForm;
