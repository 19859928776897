import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import withAuth from "components/Auth";
import PropTypes from "prop-types";
import Gravatar from "react-gravatar";
import { styled } from "@mui/material/styles";
import { Box, Chip, Menu, MenuItem, Divider, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VersionTag from "../VersionTag";
import getUserRole from "../../utils/getUserRole";
import { useTenant } from "context/TenantContext";
import { useAppContext } from "context";

// Styled components
const WrapperBox = styled(Box)({
  position: "relative",
  cursor: "pointer",
  display: "flex",
});

const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "3.3rem",
  padding: "0 0.5rem 0 0.8rem",
  background: "var(--color-san-juan)",
  borderRadius: "8px",
  "@media (prefers-color-scheme: dark)": {
    background: "var(--color-gray-4)",
  },
}));

const PictureBox = styled(Box)({
  width: "1.75rem",
  height: "1.75rem",
  marginRight: "0.75rem",
  "& img": {
    width: "inherit",
    height: "inherit",
    clipPath: "circle(50% at 50% 50%)",
  },
});

const UsernameBox = styled(Box)({
  flex: 1,
  marginRight: "0.75rem",
  "@media screen and (max-width: 1200px)": {
    display: "none",
    width: 0,
  },
});

const TenantText = styled("span")({
  fontWeight: "bold",
  fontSize: "0.8rem",
  color: "#fff",
});

const StyledChip = styled(Chip)({
  background: "var(--color-accent-dark)",
  border: "1px solid var(--chip-border)",
  borderRadius: "calc(var(--unit)* 0.4)",
  fontSize: "0.6rem",
  lineHeight: "1.4rem",
  marginLeft: "1em",
  '& .MuiChip-label': {
    padding: "0.0em 0.4em",
    color: "var(--chip-color)",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    width: "12rem",
    marginTop: "0.7rem",
  },
});

function UserDropdown({ user }) {
  const navigate = useNavigate();
  const { logout } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const { currentTenant } = useTenant();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRole = () => getUserRole(user.roles);

  return (
    <WrapperBox>
      <ProfileBox onClick={handleClick} data-cy="user-drop-down">
        {user.email && (
          <PictureBox>
            <Gravatar email={user.email} />
          </PictureBox>
        )}
        <UsernameBox>
          <Box component="span">{user.displayName}</Box>
          <br />
          {currentTenant && <TenantText>{currentTenant}</TenantText>}
        </UsernameBox>
        {currentTenant && <StyledChip label={getRole()} size="small" />}
        <IconButton size="small" sx={{ color: "white", padding: 0 }}>
          <ExpandMoreIcon />
        </IconButton>
      </ProfileBox>

      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/select-tenant");
            handleClose();
          }}
        >
          {`${currentTenant ? "Switch" : "Select"} Tenant`}
        </MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <VersionTag />
        </MenuItem>
      </StyledMenu>
    </WrapperBox>
  );
}

UserDropdown.propTypes = {
  auth: PropTypes.shape({
    tenant: PropTypes.string,
    token: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    displayName: PropTypes.string,
    roles: PropTypes.array,
    email: PropTypes.string,
    currentTenant: PropTypes.string,
  }).isRequired,
};

export default withAuth(UserDropdown);
