import { useState, useEffect } from 'react';
import { generateCodeVerifier, generateCodeChallenge } from './utils';
import config from 'config';

export const usePKCE = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const setupPKCE = async () => {
      try {
        const codeVerifier = generateCodeVerifier();
        const codeChallenge = await generateCodeChallenge(codeVerifier);
        
        localStorage.setItem('pkce_code_verifier', codeVerifier);

        const authUrl =
          `${config.AUTH.AUTHORIZATION_URL}?` +
          `client_id=${config.AUTH.CLIENT_ID}&` +
          `response_type=code&` +
          `redirect_uri=${config.AUTH.REDIRECT_URI}&` +
          `scope=${config.AUTH.SCOPE}&` +
          `code_challenge_method=S256&` +
          `code_challenge=${codeChallenge}`;

        setUrl(authUrl);
      } catch (error) {
        console.error('Error setting up PKCE:', error);
      }
    };

    if (!url) {
      setupPKCE();
    }
  }, []);

  return url;
};