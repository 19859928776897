import React from 'react';
import { useQuery } from '@tanstack/react-query';
import style from './style.module.css';
import { HealthResponse } from 'types/api';
import { useAuth } from 'components/Auth/AuthContext';
import config from 'config';

const buildUrl = (path: string): string => {
  const cleanPath = path.startsWith('/') ? path.slice(1) : path;
  return `${config.API.BASE_URL}/${cleanPath}`;
};

const VersionTag: React.FC = () => {
  const { token } = useAuth();

  const { data, isLoading, error } = useQuery<HealthResponse>({
    queryKey: ['health'],
    queryFn: async () => {
      if (!token) {
        throw new Error('No authentication token available');
      }

      const url = buildUrl('health');
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    },
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false
  });

  if (!data || isLoading || error) return null;

  return (
    <div className={style.tag}>
      {`UI: ${process.env.REACT_APP_VERSION || 'dev'}`}
      <br />
      {`API: ${data.build.tag || 'unknown'}`}
    </div>
  );
};

export default VersionTag;
