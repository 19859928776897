import React, { FC } from "react";
import { get } from "lodash";
import {
  Card,
  CardContent,
  CardHead,
  Alert,
  Button,
} from "../../../modules/ui-kit-react/lib";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import AddProjectGroupToGroupButton from "../AddProjectGroupToGroupButton";
import style from "../Form/style.module.css";
import { ProjectGroup } from "types/api";

interface ComponentProps {
  projectGroup: ProjectGroup;
}

const ProjectGroupsList: FC<ComponentProps> = ({ projectGroup }) => {
  const queryClient = useQueryClient();

  const removeGroup = useAuthenticatedMutation<any, { projectGroupId: null }>(
    `project-groups/${projectGroup.id}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`project-groups/${projectGroup.id}`],
        });
      },
    }
  );

  const groups = get(projectGroup, "projectGroups") || [];

  return (
    <Card>
      <CardHead>Project Groups in This Group</CardHead>
      <CardContent>
        {groups.length === 0 && (
          <Alert
            type="warning"
            className={style.noProjects}
            message={
              <>
                There are no project groups in this group.
                <AddProjectGroupToGroupButton
                  small
                  raised
                  flat={false}
                  projectGroup={projectGroup}
                />
              </>
            }
          />
        )}

        <ul className={style.projects}>
          {groups.map((group) => (
            <li key={group.id}>
              <div>{group.name}</div>
              <div>
                <Button
                  appearance="danger"
                  small
                  raised
                  flat
                  icon="remove"
                  onClick={() => removeGroup.mutate({ projectGroupId: null })}
                />
              </div>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default ProjectGroupsList;
