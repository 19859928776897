import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { max, takeRight } from 'lodash';
import PropTypes from 'prop-types';
import { Chip, Paper } from '../../modules/ui-kit-react/lib';
import styles from '../../pages/Projects/List/style.module.css';
import ProjectBadges from '../ProjectBadges';

function ProjectBox({ project }) {
  const { tenant } = useParams();

  const renderEnvTags = (environments) => (
    <div style={{ whiteSpace: 'normal' }}>
      {environments.map(({ id, name }) => (
        <Link key={id} to={`/${tenant}/environments/${id}`}>
          <Chip>{name}</Chip>
        </Link>
      ))}
    </div>
  );

  const renderName = (proj) => (
    <span>
      <Link className={styles.title} to={`/${tenant}/projects/${proj.id}`}>
        {proj.name}
      </Link>
      <ProjectBadges project={proj} />
    </span>
  );

  const renderSparkline = (proj) => {
    if (!proj.pulse) return null; // If no pulse data, skip
    const data = takeRight(proj.pulse.map((dep) => dep.value), 20);
    const maxScale = max(data) || 0;

    return (
      <div className={styles.sparkline}>
        <Sparklines data={data} max={maxScale || 1}>
          <SparklinesCurve style={{ strokeWidth: 0.5 }} />
        </Sparklines>
      </div>
    );
  };

  return (
    <Paper className={styles.project}>
      <div>{renderName(project)}</div>
      <div>
        {renderSparkline(project)}
        <label className={styles.envLabel}>Environments</label>
        {renderEnvTags(project.environments || [])}
      </div>
    </Paper>
  );
}

ProjectBox.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    environments: PropTypes.array,
    pulse: PropTypes.array,
  }).isRequired,
};

export default ProjectBox;
