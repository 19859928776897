import React from "react";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import Button from "../../../modules/ui-kit-react/lib/components/Button/index";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { EnvironmentWithLatestDeployment } from "hooks/useLastDeployment";
import { Project, ProviderType } from "types/api";
import style from "../style.module.css";
import Protected from "../../Protected";
import EnvLatestDeploymentDetails from "components/EnvLatestDeploymentDetails";

interface LatestDeploymentProps {
  environment: EnvironmentWithLatestDeployment;
  project: Project;
  onStatusChange?: () => void;
  changePane: (pane: string) => void;
  setError: (error: Error) => void;
}

const LatestDeployment: React.FC<LatestDeploymentProps> = ({
  environment,
  project,
  onStatusChange = () => {},
  changePane,
  setError,
}) => {
  const navigate = useNavigate();
  const [canceling, setCanceling] = React.useState(false);
  const cancelDeployment = useAuthenticatedMutation<void, void>(
    `environments/${environment.id}/deployments`,
    {
      requiresTenant: true,
      method: "DELETE",
      onError: setError,
      onSettled: () => {
        setCanceling(false);
        onStatusChange();
      },
    }
  );

  const onCancel = () => {
    if (canceling) return;
    setCanceling(true);
    cancelDeployment.mutate();
  };

  const supportsCancelation = () =>
    environment.providerType === ProviderType.Ecs;

  const renderCancelButton = () => {
    if (!supportsCancelation()) return null;

    const status = get(environment, "lastDeployment.status");
    const deploymentId = get(environment, "lastDeployment.id");

    if (!deploymentId || status !== "STARTED") return null;

    const { privileged } = environment;

    return (
      <Protected hasRole={privileged ? "DEPLOYER" : "USER"}>
        <Button
          raised
          appearance="danger"
          onClick={onCancel}
          disabled={canceling}
          // @ts-expect-error
          icon={privileged ? "lock" : null}
        >
          Cancel
        </Button>
      </Protected>
    );
  };

  const renderDeployButton = () => {
    const status = get(environment, "lastDeployment.status");
    if (status === "STARTED" && supportsCancelation()) {
      return null;
    }

    const inProgress =
      status && ["PENDING", "STARTED", "CANCELING"].includes(status);
    const { privileged, providerType } = environment;
    if (!providerType) return null;

    return (
      <Protected hasRole={privileged ? "DEPLOYER" : "USER"}>
        <Button
          raised
          appearance="primary"
          disabled={inProgress}
          onClick={() => changePane("deploy-tool")}
          // @ts-expect-error
          icon={privileged ? "lock" : null}
        >
          Deploy
        </Button>
      </Protected>
    );
  };

  return (
    <>
      <EnvLatestDeploymentDetails
        changePane={changePane}
        environment={environment}
        project={project}
        onStatusChange={onStatusChange}
      />

      <div className={style.foot}>
        <Button
          flat
          appearance="primary"
          onClick={() => navigate(`../../environments/${environment.id}`)}
        >
          View
        </Button>
        {renderCancelButton()}
        {renderDeployButton()}
      </div>
    </>
  );
};

export default LatestDeployment;
