import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withShortcut } from 'components/Shortcuts';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import style from './style.module.css';
import ProjectSelector from '../ProjectSelector';
import { useTenant } from "../../context/TenantContext";

interface ProjectSwitcherProps {
  close?: () => void;
}

const ProjectSwitcher: React.FC<ProjectSwitcherProps> = ({ close }) => {
  const navigate = useNavigate();
  const { currentTenant } = useTenant();

  if (!currentTenant) {
    return <div className={style.message}>Please select a tenant first.</div>;
  }

  return (
    <ProjectSelector
      // @ts-ignore
      autoFocus
      menuIsOpen
      isMulti={false}
      placeholder="Switch Project..."
      onChange={({ value }: { value: string }) => {
        navigate(`/${currentTenant}/projects/${value}`);
        if (close) close();
      }}
    />
  );
}

ProjectSwitcher.propTypes = {
  close: PropTypes.func,
};

export default flowRight(withShortcut('p'))(ProjectSwitcher);
