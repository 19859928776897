import React, { FC } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { get } from "lodash";
import classnames from "classnames";
import {
  CardContent,
  CardHead,
  Col,
  Button,
  Row,
} from "../../../modules/ui-kit-react/lib";
import Container from "components/Container";
import Title from "components/Title";
import ProjectBox from "components/ProjectBox";
import projectListStyles from "pages/Projects/List/style.module.css";
import AddProjectToGroupButton from "components/ProjectGroups/AddProjectToGroupButton";
import AddProjectGroupToGroupButton from "components/ProjectGroups/AddProjectGroupToGroupButton";
import Protected from "components/Protected";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import ProjectGroupBox from "components/ProjectGroups/List/ProjectGroupBox";
import style from "./style.module.css";
import { NotFoundError } from "../../../errors";
import { ProjectGroup } from "types/api";

interface ParentLinkProps {
  parentGroupId: number | undefined;
}

const ParentLink: FC<ParentLinkProps> = ({ parentGroupId }) => {
  if (!parentGroupId) return null;

  const { data: parentData } = useQuery<ProjectGroup>(
    useTenantQuery(["project-groups", parentGroupId])
  );

  if (!parentData?.name) return null;

  return (
    <small className={style.parentGroupName}>
      <i className="material-icons">subdirectory_arrow_right</i>
      <Link to={`../project-groups/${parentGroupId}`}>{parentData.name}</Link>
    </small>
  );
};

const ProjectGroupView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: projectGroup, error, isLoading } = useQuery<ProjectGroup>(
    useTenantQuery([`project-groups/${id}`, id])
  );

  const deleteGroup = useAuthenticatedMutation<void, void>(
    `project-groups/${id}`,
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["project-groups"] });
        navigate("../../projects");
      },
    }
  );

  if (isLoading || !projectGroup) return null;
  if (error) throw error;

  const groupProjects = get(projectGroup, "projects") || [];
  const groupGroups = get(projectGroup, "projectGroups") || [];

  return (
    <Container>
      <Title title={`Project Group "${projectGroup.name}"`} />

      <CardHead>
        <div className={projectListStyles.head}>
          <div className={classnames(projectListStyles.title, style.title)}>
            {projectGroup?.name}
            <br />
            <ParentLink parentGroupId={projectGroup.parentProjectGroupID} />
          </div>

          <div className={style.actions}>
            <Button
              appearance="primary"
              icon="edit"
              flat
              onClick={() => navigate(`../${projectGroup.id}/edit`)}
            >
              Edit Group
            </Button>

            <AddProjectToGroupButton projectGroup={projectGroup} />
            <AddProjectGroupToGroupButton projectGroup={projectGroup} />

            <Protected hasRole="ADMIN">
              <Button
                appearance="danger"
                icon="delete"
                flat
                onClick={() => deleteGroup.mutate()}
              >
                Delete Group
              </Button>
            </Protected>
          </div>
        </div>
      </CardHead>

      <CardContent>
        <p>{projectGroup?.description}</p>

        <Row>
          {groupGroups?.map((group) => (
            <ProjectGroupBox key={group.id} projectGroup={group} />
          ))}
        </Row>

        <Row>
          {groupProjects?.map((project) => (
            <Col sm={4} className={projectListStyles.col} key={project.id}>
              <ProjectBox project={project} />
            </Col>
          ))}
        </Row>

        {groupProjects.length === 0 && (
          <div className={projectListStyles.noProjects}>No projects</div>
        )}
      </CardContent>
    </Container>
  );
};

export default ProjectGroupView;
