import React from 'react';
import { get } from 'lodash';
import TextInput from '../../Form/TextInput';
import SelectInput from '../../Form/SelectInput';
import { awsRegions, humanize } from '../../../utils/enums';
import extractFieldError from '../../../utils/gqlErrors';
import { useAwsProfiles } from 'hooks/provider-hooks';
import { AwsProfile, EcsScheduledTaskConfiguration } from 'types/api';
interface EcsScheduledTaskFieldsProps {
  providerConfig?: EcsScheduledTaskConfiguration;
  gqlError?: {
    graphQLErrors?: any[];
  } | null;
}

const EcsScheduledTaskFields: React.FC<EcsScheduledTaskFieldsProps> = ({
  providerConfig = {},
  gqlError = null
}) => {
  const { data: awsProfilesResponse, isLoading } = useAwsProfiles();

  const getError = (field: string) => {
    return extractFieldError(gqlError, field);
  };

  if (isLoading) {
    return null;
  }

  const awsProfiles = awsProfilesResponse || [];

  return (
    <>
      <TextInput
        name="providerConfiguration[__typename]"
        type="hidden"
        defaultValue="ecs-scheduled-task"
      />

      <TextInput
        name="providerConfiguration[taskFamily]"
        label="Task Family"
        defaultValue={get(providerConfig, 'taskFamily')}
        required
        error={getError('taskFamily')}
      />

      <TextInput
        name="providerConfiguration[image]"
        label="Image"
        defaultValue={get(providerConfig, 'image')}
        required
        error={getError('image')}
      />

      <SelectInput
        name="providerConfiguration[region]"
        label="Region"
        defaultValue={get(providerConfig, 'region')}
        required
        error={getError('region')}
        source={awsRegions.map(region => ({
          value: region,
          label: humanize(region)
        }))}
      />

      <SelectInput
        name="providerConfiguration[awsProfileId]"
        label="Profile"
        defaultValue={get(providerConfig, 'awsProfileId')}
        required
        source={awsProfiles.map((profile: AwsProfile) => ({
          value: profile.id,
          label: profile.name
        }))}
        error={getError('awsProfileId')}
      />
    </>
  );
};

export default EcsScheduledTaskFields;
