import React from 'react';
import { Navigate } from 'react-router-dom';
import { useTenant } from '../../context/TenantContext';

const Redirector = () => {
  const { currentTenant } = useTenant();

  return (
    <Navigate
      to={currentTenant ? `/${currentTenant}` : '/select-tenant'}
      replace
    />
  );
};

export default Redirector;
