import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "components/Header";
import AuthProvider from "components/Auth/AuthProvider";
import AuthenticatedQueryProvider from "components/Auth/AuthenticatedQueryProvider";
import ErrorBoundry from "components/ErrorBoundry";
import ContextComposer from "components/Auth/ContextComposer";
import { TenantProvider } from "context/TenantContext";
import NotFound from "components/NotFound";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tenant, { Redirector as TenantRedirector } from "../Tenant";
import Footer from "../../components/Footer";
import Shortcuts from "../../components/Shortcuts";
import { ContextProvider } from "../../context";
import Admin from "../Admin";

const theme = createTheme({
  colorSchemes: {
    dark: true,
  },
});

const ReleaseNotes = React.lazy(() => import("../ReleaseNotes"));
const SlackAuth = React.lazy(() => import("components/SlackAuth"));
const SuperAdmin = React.lazy(() => import("containers/SuperAdmin"));
const TenantSelector = React.lazy(() => import("components/TenantSelector"));

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <AuthProvider>
          <TenantProvider>
            <AuthenticatedQueryProvider>
              <ContextComposer>
                <ErrorBoundry>
                  <Shortcuts />
                  <Header />
                  <Suspense fallback={<div />}>
                    <Routes>
                      <Route path="/" element={<TenantRedirector />} />

                      {/* SlackAuth */}
                      <Route
                        path="/authorize-slack/:id"
                        element={<SlackAuth />}
                      />

                      {/* ReleaseNotes */}
                      <Route path="/release-notes" element={<ReleaseNotes />} />

                      {/* TenantSelector */}
                      <Route path="/select-tenant" element={<TenantSelector />} />

                      <Route
                        path="/superadmin"
                        element={<Navigate to="/super-admin" replace />}
                      />

                      {/* Admin */}
                      <Route path="/:tenant/admin/*" element={<Admin />} />

                      {/* SuperAdmin */}
                      <Route path="/super-admin/*" element={<SuperAdmin />} />

                      <Route path=":tenant/*" element={<Tenant />} />

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Suspense>
                  <Footer />
                </ErrorBoundry>
              </ContextComposer>
            </AuthenticatedQueryProvider>
          </TenantProvider>
        </AuthProvider>
      </ContextProvider>
    </ThemeProvider>
  );
}
