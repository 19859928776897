import React from "react";
import Alert from "../../modules/ui-kit-react/lib/components/Alert";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { getStatusEnumsFromForm } from "utils/enums";
import WebHookForm from "./Form";
import { WebHook } from "types/api";
import style from "./style.module.css";

interface WebHookInlineEditProps {
  onClose: () => void;
  webHook: WebHook;
}

const WebHookInlineEdit: React.FC<WebHookInlineEditProps> = ({
  onClose,
  webHook,
}) => {
  const updateWebHook = useAuthenticatedMutation<
    any,
    {
      url: string;
      on: string[];
      awsProfileId: number | null;
    }
  >(`webhooks/${webHook.id}`, {
    requiresTenant: true,
    method: "PUT",
    onSuccess: onClose,
  });

  return (
    <div className={style.container}>
      {updateWebHook.error && (
        <Alert type="danger" message={updateWebHook.error.message} />
      )}
      <div className={style.heading}>Edit Alert</div>
      <WebHookForm
        edit
        onCancel={onClose}
        webHook={webHook}
        onSubmit={(form) =>
          updateWebHook.mutate({
            url: form.url,
            on: getStatusEnumsFromForm(form.on),
            awsProfileId: form.awsProfileId ? Number(form.awsProfileId) : null,
          })
        }
      />
    </div>
  );
};

export default WebHookInlineEdit;
