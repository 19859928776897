import React, { useState } from "react";
import { Alert, Modal } from "../../../modules/ui-kit-react/lib";
import ProjectSelector from "../../ProjectSelector";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { ProjectGroup } from "types/api";
import style from "./style.module.css";

interface AddProjectProps {
  active: boolean;
  onClose: () => void;
  projectGroup: ProjectGroup;
}

const AddProject: React.FC<AddProjectProps> = ({
  active,
  onClose,
  projectGroup,
}) => {
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const queryClient = useQueryClient();

  // Add project to group mutation
  const addProjectToGroup = useAuthenticatedMutation<
    void,
    { projectId: string }
  >(`project-groups/${projectGroup.id}/projects/:projectId`, {
    requiresTenant: true,
    method: "POST",
    pathParams: (variables) => ({
      projectId: variables.projectId,
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`project-groups/${projectGroup.id}`],
      });
    },
  });

  const handleSubmit = async () => {
    try {
      await Promise.all(
        selectedProjects.map((projectId) =>
          addProjectToGroup.mutate({ projectId })
        )
      );
      onClose();
    } catch (error) {
      // Error will be handled by the mutation error state
    }
  };

  const projectsInGroup = projectGroup?.projects?.map((p) => p.id) || [];

  return (
    <Modal
      active={active}
      closeModal={onClose}
      type="small"
      title={`Add Projects to ${projectGroup.name}`}
      actions={
        [
          {
            flat: true,
            appearance: "danger",
            label: "Cancel",
            onClick: onClose,
            theme: {},
          },
          {
            raised: true,
            appearance: "primary",
            label: "Add",
            onClick: handleSubmit,
            disabled: addProjectToGroup.isPending,
            theme: {},
          },
        ] as any
      }
      theme={{
        body: style.modalBody,
      }}
    >
      <>
        {addProjectToGroup.error && (
          <Alert type="danger" message={addProjectToGroup.error.message} />
        )}
        <ProjectSelector
          filter={(project) => !projectsInGroup.includes(project.id)}
          // @ts-ignore
          autoFocus
          isMulti
          placeholder="Select Projects..."
          onChange={(selected: any) => {
            if (Array.isArray(selected)) {
              setSelectedProjects(selected.map((s) => s.value));
            } else {
              setSelectedProjects([]);
            }
          }}
        />
      </>
    </Modal>
  );
};

export default AddProject;
