import React from 'react';
import { get } from 'lodash';
import TextInput from '../../Form/TextInput';
import SelectInput from '../../Form/SelectInput';
import { awsRegions, humanize } from '../../../utils/enums';
import extractFieldError from '../../../utils/gqlErrors';
import CheckBox from '../../Form/Checkbox';
import HelpTip from '../../HelpTip';
import { useAwsProfiles } from 'hooks/provider-hooks';
import { AwsProfile, CodebuildConfiguration } from 'types/api';

interface CodeBuildFieldsProps {
  providerConfig?: CodebuildConfiguration;
  gqlError?: {
    graphQLErrors?: any[];
  } | null;
}

const CodeBuildFields: React.FC<CodeBuildFieldsProps> = ({
  providerConfig = {},
  gqlError = null
}) => {
  const { data: awsProfilesResponse, isLoading } = useAwsProfiles();

  const getError = (field: string) => {
    return extractFieldError(gqlError, field);
  };

  if (isLoading) {
    return null;
  }

  const awsProfiles = awsProfilesResponse || [];

  return (
    <>
      <TextInput
        name="providerConfiguration[__typename]"
        type="hidden"
        defaultValue="codebuild"
      />

      <TextInput
        name="providerConfiguration[projectName]"
        label="Project Name"
        defaultValue={get(providerConfig, 'projectName')}
        required
        error={getError('projectName')}
      />

      <SelectInput
        name="providerConfiguration[timeout]"
        label="Timeout (min)"
        defaultValue={get(providerConfig, 'timeout')}
        required
        error={getError('timeout')}
        source={[3, 5, 10, 15, 20, 25, 30].map(minutes => ({
          value: minutes * 60,
          label: minutes.toString()
        }))}
      />

      <SelectInput
        name="providerConfiguration[region]"
        label="Region"
        defaultValue={get(providerConfig, 'region')}
        required
        error={getError('region')}
        source={awsRegions.map(region => ({
          value: region,
          label: humanize(region)
        }))}
      />

      <SelectInput
        name="providerConfiguration[awsProfileId]"
        label="Profile"
        defaultValue={get(providerConfig, 'awsProfileId')}
        required
        source={awsProfiles.map((profile: AwsProfile) => ({
          value: profile.id,
          label: profile.name
        }))}
        error={getError('awsProfileId')}
      />

      <CheckBox
        name="providerConfiguration[specifySourceVersion]"
        label={
          <>
            Specify Source Version &nbsp;
            <HelpTip text="If checked, sets source version as the artifact name. Please check out the codebuild docs for more information." />
          </>
        }
        defaultValue={get(providerConfig, 'specifySourceVersion', false)}
        error={getError('specifySourceVersion')}
        disabled={false}
      />
    </>
  );
};

export default CodeBuildFields;
