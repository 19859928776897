import React, { useState } from 'react';
import { get } from 'lodash';
import { awsRegions, humanize } from 'utils/enums';
import TextInput from 'components/Form/TextInput';
import extractFieldError from 'utils/gqlErrors';
import SelectInput from 'components/Form/SelectInput';
import RadioGroup, { RadioButton } from 'components/Form/RadioGroup';
// @ts-expect-error
import { Validators } from '../../../modules/react-forms';
import { useAwsProfiles } from 'hooks/provider-hooks';
import { BasicLambdaConfiguration } from 'types/api';
import styles from './style.module.css';

const s3Validation = Validators.isURL({
  protocols: ['s3'],
  require_tld: false,
  require_protocol: true,
  allow_underscores: true
});

type LambdaType = 'ZIP' | 'CONTAINER';

interface BasicLambdaFieldsProps {
  configuration?: BasicLambdaConfiguration;
  gqlError?: {
    graphQLErrors?: any[];
  } | null;
}

const BasicLambdaFields: React.FC<BasicLambdaFieldsProps> = ({
  configuration = {},
  gqlError = null
}) => {
  const [type, setType] = useState<LambdaType | null>(null);
  const { data: awsProfilesResponse, isLoading } = useAwsProfiles();

  const getError = (field: string) => {
    return extractFieldError(gqlError, field);
  };

  if (isLoading) {
    return null;
  }

  const typeValue = type || configuration?.type || 'ZIP';
  const awsProfiles = awsProfilesResponse || [];

  return (
    <>
      <TextInput
        name="providerConfiguration[__typename]"
        type="hidden"
        defaultValue="lambda"
        error=""
      />

      <fieldset className={styles.radioFieldset}>
        <legend>Lambda Type *</legend>

        <RadioGroup
          label="Lambda Type"
          name="providerConfiguration[type]"
          defaultValue={typeValue}
          required
          className={styles.radios}
          validation={(value: string) => {
            if (value && value !== typeValue) {
              setType(value as LambdaType);
            }
            return true;
          }}
        >
          <RadioButton label="Zip" value="ZIP" />
          <RadioButton label="Container" value="CONTAINER" />
        </RadioGroup>
      </fieldset>

      {typeValue === 'ZIP' && (
        <TextInput
          name="providerConfiguration[artifactBucket]"
          label="Artifact Bucket"
          defaultValue={get(configuration, 'artifactBucket')}
          required
          error={getError('artifactBucket')}
          placeholder="s3://bucket/path"
          validation={{
            'Invalid S3 bucket URI': s3Validation
          }}
        />
      )}

      {typeValue === 'CONTAINER' && (
        <TextInput
          name="providerConfiguration[image]"
          label="Image"
          defaultValue={get(configuration, 'image')}
          required
          error={getError('image')}
        />
      )}

      <TextInput
        name="providerConfiguration[functionName]"
        label="Function Name"
        defaultValue={get(configuration, 'functionName')}
        required
        error={getError('functionName')}
        placeholder="super-awesome-function"
      />

      <SelectInput
        name="providerConfiguration[region]"
        label="Region"
        defaultValue={get(configuration, 'region')}
        required
        error={getError('region')}
        source={awsRegions.map(region => ({
          value: region,
          label: humanize(region)
        }))}
      />

      <SelectInput
        name="providerConfiguration[awsProfileId]"
        label="AWS Profile"
        defaultValue={get(configuration, 'awsProfileId')}
        required
        source={awsProfiles.map(profile => ({
          value: profile.id,
          label: profile.name
        }))}
        error={getError('awsProfileId')}
        validation={!Validators.isEmpty}
      />
    </>
  );
};

export default BasicLambdaFields;
