import React from "react";
import { startCase } from "lodash";
import moment from "moment";
import {
  Paper,
  Typography,
  Button,
  Alert,
  Tooltip,
  IconButton,
  Chip,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import GitRef from "components/GitRef";
import ArtifactName from "components/ArtifactName";
import Protected from "../Protected";
import { useUser } from "hooks/security-hooks";
import { Environment, Project } from "types/api";
import useEnvironmentWithLatestDeployment from "hooks/useLastDeployment";

interface EnvLatestDeploymentDetailsProps {
  onStatusChange: (status: string | null) => void;
  project: Project;
  environment: Environment;
  changePane: (pane: string) => void;
}

const EnvLatestDeploymentDetails: React.FC<EnvLatestDeploymentDetailsProps> = ({
  onStatusChange,
  project,
  environment,
  changePane,
}) => {
  const environmentWithLatestDeployment =
    useEnvironmentWithLatestDeployment(environment);

  const { data: user } = useUser(
    environmentWithLatestDeployment?.lastDeployment?.deployerId
  );

  const renderProviderConfigMissing = () => {
    const configExists = environment.providerType;

    if (configExists) {
      return null;
    }

    return (
      <Alert
        severity="warning"
        sx={{ mb: 2 }}
        action={
          <Protected hasRole="ADMIN">
            <Button
              variant="contained"
              size="small"
              onClick={() => changePane("provider-config")}
            >
              Setup
            </Button>
          </Protected>
        }
      >
        Deployment configuration is missing.
      </Alert>
    );
  };

  const renderNoDeployments = () => (
    <>
      {renderProviderConfigMissing()}
      <Paper elevation={0} sx={{ p: 3, textAlign: "center" }}>
        <Typography variant="h6">No Deployment History</Typography>
      </Paper>
    </>
  );

  const lastDeployment = environmentWithLatestDeployment?.lastDeployment;
  const deploymentId = lastDeployment?.id;
  // useDeploymentPolling(deploymentId, onStatusChange);

  if (!deploymentId) {
    return renderNoDeployments();
  }

  const status = startCase(lastDeployment.status?.toLowerCase());

  // Get status color based on status
  const getStatusColor = (status: string) => {
    const statusLower = status.toLowerCase();
    if (statusLower.includes("completed")) return "success";
    if (statusLower.includes("pending") || statusLower.includes("started"))
      return "info";
    if (statusLower.includes("cancel")) return "warning";
    if (statusLower.includes("fail") || statusLower.includes("error"))
      return "error";
    return "default";
  };

  return (
    <>
      <Protected hasRole="ADMIN">{renderProviderConfigMissing()}</Protected>
      <Grid
        container
        spacing={3}
        style={{
          marginLeft: 0,
          paddingInline: "24px",
          paddingTop: "32px",
        }}
      >
        {/* Row 1 */}
        <Grid
          xs={6}
          sx={{
            paddingBlock: "8px",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Ref
          </Typography>
          <GitRef
            repoRef={lastDeployment.ref || ""}
            repo={project.repository}
          />
        </Grid>
        <Grid
          xs={6}
          sx={{
            paddingBlock: "8px",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Time
          </Typography>
          <Typography>
            <time
              dateTime={lastDeployment.insertedAt}
              title={moment(lastDeployment.insertedAt).format(
                "MMMM Do YYYY, h:mm a"
              )}
            >
              {moment(lastDeployment.insertedAt).fromNow()}
            </time>
          </Typography>
        </Grid>

        {/* Row 2 */}
        <Grid
          xs={6}
          sx={{
            paddingBlock: "8px",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Deployed By
          </Typography>
          <Typography>{user?.displayName || "Unknown User"}</Typography>
        </Grid>
        <Grid
          xs={6}
          sx={{
            paddingBlock: "8px",
          }}
        >
          <Stack direction="row" alignItems="center" gap="8px" sx={{ mb: 1 }}>
            <Typography variant="subtitle2" color="textSecondary">
              Artifact Name
            </Typography>
            <Tooltip title="Compare with other artifacts">
              <IconButton
                size="small"
                color="primary"
                onClick={() => changePane("compare-tool")}
                style={{ padding: 2 }}
                aria-label="Compare artifacts"
              >
                <CompareArrowsIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              paddingRight: "24px",
            }}
          >
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              <ArtifactName artifactName={lastDeployment.artifactName} />
            </div>
          </div>
        </Grid>

        {/* Row 3 */}
        <Grid
          xs={6}
          sx={{
            paddingBlock: "8px",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Auto Promoted
          </Typography>
          <Typography>{lastDeployment.autoPromoted ? "Yes" : "No"}</Typography>
        </Grid>
        <Grid
          xs={6}
          sx={{
            paddingBlock: "8px",
          }}
        >
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Status
          </Typography>
          <Chip
            label={status}
            size="small"
            color={getStatusColor(status) as any}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EnvLatestDeploymentDetails;
