import React, { FC } from "react";
import Button from "@mui/material/Button";
import { Project } from "types/api";
import TagSelector from "../../TagSelector";
import { EnvironmentWithLatestDeployment } from "hooks/useLastDeployment";
import style from "../style.module.css";

type Props = {
  environment: EnvironmentWithLatestDeployment;
  project: Project;
  inline?: boolean;
  onCompareClick?: () => void;
};

const CompareTool: FC<Props> = ({
  environment,
  project,
  inline = false,
  onCompareClick,
}) => {
  // Inline version just returns the compare button
  if (inline) {
    return (
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={onCompareClick}
        className={style.compareButton}
      >
        Compare
      </Button>
    );
  }

  // Full version with tag selector and back button
  return (
    <>
      <TagSelector
        currentTag={environment?.lastDeployment?.artifactName || ""}
        environmentId={environment.id}
        repoUrl={project?.repository || ""}
      />

      <div className={style.foot}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.history.back()}
        >
          Back
        </Button>
      </div>
    </>
  );
};

export default CompareTool;
