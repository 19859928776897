import React, { useEffect, useState } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function RoutedPagination({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Local state for page
  const [page, setPage] = useState(1);

  // Sync page state whenever the URL changes (e.g. back/forward).
  useEffect(() => {
    const parsed = qs.parse(location.search);
    // Use ?page= param or just 1
    setPage(parsed.page ? parsed.page : 1);
  }, [location.search]);

  // Will update query string accordingly
  const changePage = (newPage) => {
    const query = qs.parse(location.search);

    if (newPage > 1) {
      query.page = newPage;
    } else {
      delete query.page;
    }

    navigate(`${location.pathname}?${qs.stringify(query)}`);
    setPage(newPage);
  };

  // any child component gets the props injected 
  return React.cloneElement(children, {
    page: Number.parseInt(page, 10),
    changePage,
  });
}

RoutedPagination.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RoutedPagination;

export const withRoutedPagination = (BaseComponent) => {
  function Wrapped(props) {
    return (
      <RoutedPagination>
        <BaseComponent {...props} />
      </RoutedPagination>
    );
  }

  Wrapped.displayName = `withRoutedPagination(${BaseComponent.displayName || BaseComponent.name})`;
  Wrapped.WrappedComponent = BaseComponent;

  return hoistNonReactStatic(Wrapped, BaseComponent);
};
