import React, { FC, useState, useEffect } from "react";
import Pagination from "components/Pagination";
import moment from "moment";
import { slice } from "lodash";
import ReactJson from "react18-json-view";
import "react18-json-view/src/style.css";
import 'react18-json-view/src/dark.css';
import Tooltip from "components/Tooltip";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import { withRoutedPagination } from "../RoutedPagination";
import {
  AuditLog as AuditLogEntry,
  PaginatedAuditLog,
  UserType,
} from "types/api";

export interface AuditLogProps {
  changePage: (pageNumber: number) => void;
  limit?: number;
  page: number;
  pagination?: boolean;
}

const renderTime = (insertedAt: string | undefined | null): string =>
  moment(insertedAt).fromNow();

const AuditLog: FC<AuditLogProps> = ({
  changePage,
  limit = 0,
  page,
  pagination = true,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
  
    const handleChange = () => {
      setIsDarkMode(mediaQuery.matches);
    };
  
    // Attach listener to respond to changes
    mediaQuery.addEventListener('change', handleChange);
  
    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  const { data, error } = useQuery<PaginatedAuditLog>(
    useTenantQuery(["security/audit-logs", { page }])
  );

  if (error) throw error;
  if (!data) return null;

  let logs: AuditLogEntry[] = [];
  let lastUser: number | undefined;
  let hideCount = 0;

  if (limit) {
    logs = slice(data.entries, 0, limit);
  } else {
    logs = data.entries || [];
  }

  const renderPagination = () => (
    <div className={style.pagination}>
      <Pagination
        settings={{
          total: data.pagination.totalEntries,
          pageSize: data.pagination.pageSize,
          currentPage: page,
        }}
        nextPage={(pageNumber: number) => changePage(pageNumber)}
        theme={{
          container: style.paginationContainer,
        }}
      />
    </div>
  );

  return (
    <>
      {pagination && renderPagination()}
      <ul className={style.log} data-cy="audit-log-view">
        {logs.map((log) => {
          // Hide the username on consecutive logs with the same user
          let hideUser = false;
          if (lastUser === log.userId) {
            if (hideCount === 10) {
              hideCount = 0;
            } else {
              hideUser = true;
              hideCount += 1;
            }
          } else {
            hideCount = 0;
          }

          lastUser = log.userId;

          return (
            <li key={log.id}>
              <div className={style.leftSide}>
                <div className={style.userName}>
                  {hideUser ? null : (
                    <>
                      {log.displayName}
                      {log.type === UserType.Service ? (
                        <span className={style.userType}>
                          <i
                            className="material-icons"
                            data-tip="This action was performed via the Shield API by a Service Client"
                          >
                            smart_toy
                          </i>
                          <Tooltip />
                        </span>
                      ) : null}
                      {log.type === UserType.Normal ? (
                        <span className={style.userType}>
                          <i
                            className="material-icons"
                            data-tip="This action was performed via the Shield UI by an authenticated user"
                          >
                            person
                          </i>
                          <Tooltip />
                        </span>
                      ) : null}
                    </>
                  )}
                </div>
                <div className={style.time}>{renderTime(log.insertedAt)}</div>
              </div>
              <div className={style.details}>
                <div>
                  <label>Action</label>
                  {log.action}
                </div>
                {log.changes?.length ? (
                  <div className={style.changes}>
                    <label>Changes</label>
                    {log.changes.join(", ")}
                  </div>
                ) : null}
                <div className={style.snapshot}>
                  <label>Entity Snapshot</label>
                  <ReactJson
                    src={JSON.parse(log.snapshot ?? "")}
                    enableClipboard={false}
                    collapsed={true}
                    dark={isDarkMode}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      {pagination && renderPagination()}
    </>
  );
};

export default withRoutedPagination(AuditLog);
