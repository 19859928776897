import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTenant } from "../../context/TenantContext";
import Projects from "../Projects";
import ProjectGroups from "../ProjectGroups";
import Environments from "../Environments";
import Access from "../../pages/Tenant/Access";
import LoadingPage from "../../components/LoadingPage";

const Tenant = () => {
  const navigate = useNavigate();
  const { tenant } = useParams();
  const { setCurrentTenant } = useTenant();

  const { data, isLoading } = useQuery({
    queryKey: ["tenants"],
  });

  useEffect(() => {
    if (data?.tenants && tenant) {
      if (data.tenants.includes(tenant)) {
        setCurrentTenant(tenant);
      } else {
        navigate("/select-tenant", { state: { tenant }, replace: true });
      }
    }
  }, [data, tenant, setCurrentTenant, navigate]);

  if (isLoading) return <LoadingPage />;

  return (
    <Routes>
      <Route index element={<Navigate to="projects" replace />} />

      <Route path="projects/*" element={<Projects />} />
      <Route path="project-groups/*" element={<ProjectGroups />} />
      <Route path="environments/*" element={<Environments />} />
      <Route path="access" element={<Access />} />
    </Routes>
  );
};

export default Tenant;

export { default as Redirector } from "./Redirector";
