import React from "react";
import ProjectBadges from "components/ProjectBadges";
import withAuth from "components/Auth";
import { CardHead, CardContent } from "../../modules/ui-kit-react/lib";
import styles from "./style.module.css";
import ProjectActions from "../ProjectActions";
import ProjectLinks from "../ProjectLinks";
import AccessNotice from "../AccessNotice";
import getUserRole from "../../utils/getUserRole";
import { Project } from "types/api";
import { useProjectReferences } from "hooks/project-hooks";

interface ProjectDetailsProps {
  auth: any;
  project?: Project;
}

const ProjectDetails = ({ auth, project }: ProjectDetailsProps) => {
  const canEditBadges = getUserRole(auth.profile.roles) !== "read-only";
  const { data: references } = useProjectReferences(project?.id);

  if (!project) return;

  return (
    <>
      <CardHead className={styles.bar}>
        <div className={styles.name}>
          <h2>{project?.name}</h2>
          <small data-cy="project-slug">{project?.slug}</small>
        </div>

        <ProjectLinks
          project={{
            ...project,
            environments: project?.environments || [],
            references: references || [],
          }}
        />
        <ProjectActions project={project} />
      </CardHead>
      <AccessNotice />

      <CardContent>
        <ProjectBadges project={project} showEdit={canEditBadges} />
        <div className={styles.description}>{project?.description}</div>
      </CardContent>
    </>
  );
};

export default withAuth(ProjectDetails);
