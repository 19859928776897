import React, { useEffect, useState } from "react";
import classnames from "classnames";
import localforage from "localforage";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import style from "./style.module.css";
import { ReleaseNote } from "types/api";

const ReleaseNotesIndicator: React.FC = () => {
  const navigate = useNavigate();
  const [notify, setNotify] = useState(false);
  const [setFromLs, setSetFromLs] = useState(false);

  const { data, isLoading, error } = useQuery<{ releaseNotes: ReleaseNote[] }>({
    queryKey: ["release-notes"],
  });

  useEffect(() => {
    if (!isLoading && !error && data?.releaseNotes?.[0] && !setFromLs) {
      const latest = data.releaseNotes[0].insertedAt || 0;

      localforage.getItem<string>("shield:release_notes").then((lastViewed) => {
        const shouldNotify = lastViewed
          ? moment(latest).isAfter(moment(lastViewed))
          : true;

        if (notify !== shouldNotify) {
          setNotify(shouldNotify);
          setSetFromLs(true);
        }
      });
    }
  }, [data, setFromLs, notify, isLoading, error]);

  const handleClick = () => {
    setNotify(false);
    navigate("/release-notes");
  };

  return (
    <span
      onClick={handleClick}
      className={classnames("material-icons", style.releaseNotes, {
        [style.notify]: notify,
      })}
    >
      notifications
    </span>
  );
};

export default ReleaseNotesIndicator;
