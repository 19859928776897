import React from 'react';
import { Grid } from '@mui/material';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import ProjectEnvGridItem from 'components/ProjectEnvGridItem';
import styles from './style.module.css';

const ProjectEnvGrid = ({ project, environments, isDragging, subscribeToMore }) => (
  <Grid container spacing={3}>
    {environments.length === 0 ? (
      <Grid item xs={12}>
        <div className={styles.noEnvironments} data-cy="env-missing">
          No environments
        </div>
      </Grid>
    ) : (
      <SortableContext 
        items={environments.map(env => env.id)} 
        strategy={rectSortingStrategy}
      >
        {environments.map((env) => (
          <ProjectEnvGridItem
            key={env.id}
            env={env}
            isDragging={isDragging}
            project={project}
            subscribeToMore={subscribeToMore}
          />
        ))}
      </SortableContext>
    )}
  </Grid>
);

export default ProjectEnvGrid;
