import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EnvironmentsView from 'pages/Environments/View';
import EnvironmentEdit from 'pages/Environments/Edit';
import withAuth from 'components/Auth';

function Environments() {
  return (
    <Routes>
      <Route path=":id" element={<EnvironmentsView />} />
      <Route path=":id/edit" element={<EnvironmentEdit />} />
    </Routes>
  );
}

export default withAuth(Environments);
