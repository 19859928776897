import React, { useEffect } from "react";
import ProjectEnvGridContainer from "components/ProjectEnvGridContainer";
import ProjectDetails from "components/ProjectDetails";
import Title from "components/Title";
import Container from "components/Container";
import { useParams, useLocation } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import { Project } from "types/api";
import styles from "./style.module.css";
import { CardContent, CircularProgress } from "@mui/material";

const ProjectView = () => {
  const { id: projectId } = useParams();
  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    // Refetch project data whenever the location changes
    queryClient.refetchQueries({
      queryKey: [`projects/${projectId}`],
    });
  }, [location, projectId, queryClient]);

  const {
    data: project,
    error,
    isLoading,
  } = useQuery<Project>({
    ...useTenantQuery([`projects/${projectId}`]),
    refetchOnWindowFocus: true,
    staleTime: 0, // Consider data stale immediately
  });

  if (isLoading) {
    return (
      <Container data-cy="project-view">
        <CircularProgress />
      </Container>
    );
  }

  if (error) throw error;

  if (!project?.name) {
    return (
      <Container data-cy="project-view">
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container data-cy="project-view">
      <Title title={`Project "${project?.name}"`} />

      <ProjectDetails project={project} />

      <CardContent className={styles.wrap}>
        <ProjectEnvGridContainer project={project} subscribeToMore={() => {}} />
      </CardContent>
    </Container>
  );
};

export default ProjectView;
