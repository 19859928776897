import React, { FC } from "react";
import { Alert, Modal } from "../../../modules/ui-kit-react/lib";
import { UpdateProjectGroupRequest, ProjectGroup } from "types/api";
import { Form } from "../../../modules/react-forms";
import MultiSelect from "components/Form/MultiSelectInput";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";

interface ComponentProps {
  active: boolean;
  onClose: () => void;
  projectGroup: Pick<ProjectGroup, "id" | "name">;
}

interface FormProps {
  projectGroupIds: string[];
}

const AddProjectGroupsModal: FC<ComponentProps> = ({
  active,
  onClose,
  projectGroup,
}) => {
  const { id: currentProjectGroupId } = useParams<{ id: string }>();
  const formRef = React.createRef<Form<FormProps>>();
  const queryClient = useQueryClient();

  // Fetch project groups
  const { data, error: queryError } = useQuery<{ entries: ProjectGroup[] }>(
    useTenantQuery(["project-groups"])
  );

  const projectGroups = data?.entries;

  // Update project group mutation
  const updateGroup = useAuthenticatedMutation<
    ProjectGroup,
    UpdateProjectGroupRequest & {
      projectGroupId: number;
    }
  >(`project-groups/:projectGroupId`, {
    requiresTenant: true,
    method: "PUT",
    pathParams: (variables) => ({
      projectGroupId: String(variables.projectGroupId),
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`project-groups/${currentProjectGroupId}`],
      });
    },
  });

  if (!currentProjectGroupId) return null;

  const onSubmit = async ({ projectGroupIds }: FormProps) => {
    try {
      await Promise.all(
        projectGroupIds.map((id) => {
          // Find the current project group data to preserve its properties
          const groupToUpdate = projectGroups?.find(
            (group) => String(group.id) === id
          );

          if (!groupToUpdate) {
            throw new Error(`Project group with ID ${id} not found`);
          }

          return updateGroup.mutate({
            // Preserve existing name and description from the found group
            name: groupToUpdate.name,
            description: groupToUpdate.description || "",
            parentProjectGroupId: parseInt(currentProjectGroupId),
            projectGroupId: parseInt(id),
          });
        })
      );
      onClose();
    } catch (e) {
      // Errors will be handled by the mutation error state
    }
  };

  return (
    <Modal
      active={active}
      closeModal={onClose}
      type="small"
      title={`Add Groups to ${projectGroup.name}`}
      actions={
        [
          {
            flat: true,
            appearance: "danger",
            label: "Cancel",
            onClick: onClose,
            theme: {},
          },
          {
            raised: true,
            appearance: "primary",
            label: "Add",
            onClick: () => formRef.current?.submit(),
            theme: {},
          },
        ] as any
      }
      theme={{
        body: style.modalBody,
      }}
    >
      <>
        {queryError && <Alert type="danger" message={queryError.message} />}
        {updateGroup.error && (
          <Alert type="danger" message={updateGroup.error.message} />
        )}

        <Form ref={formRef} onValidSubmit={onSubmit}>
          <MultiSelect
            autoFocus
            required
            isMulti
            name="projectGroupIds"
            placeholder="Search project group..."
            options={projectGroups
              ?.filter(
                ({ id, parentProjectGroupID }: ProjectGroup) =>
                  !parentProjectGroupID && id !== projectGroup.id
              )
              .map(({ id, name }: ProjectGroup) => ({
                value: String(id),
                label: name,
              }))}
          />
        </Form>
      </>
    </Modal>
  );
};

export default AddProjectGroupsModal;
