import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "context";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import FullPageSpinner from "components/FullPageSpinner";
import { useAuth } from "./AuthContext";
import { useTenant } from "../../context/TenantContext";

const ContextUpdateWrapper = ({ profileData }) => {
  const { updateProfile } = useAuth();
  const { setTenant } = useAppContext();
  const { currentTenant } = useTenant();

  useEffect(() => {
    if (profileData) {
      updateProfile(profileData);
      setTenant(currentTenant);
    }
  }, [profileData, updateProfile, setTenant, currentTenant]);

  return null;
};

const ContextComposer = ({ children }) => {
  const { currentTenant } = useTenant();

  // First query to get initial profile data
  const {
    data: basicProfile,
    isLoading: isLoadingInitial,
    error: initialError,
  } = useQuery({
    queryKey: ["profile"],
    staleTime: 0,
    retry: 3,
    refetchOnWindowFocus: false,
  });

  // Only make the user-roles query once we have the tenant
  const {
    data: rolesResponse,
    isLoading: isLoadingRoles,
    error: rolesError,
  } = useQuery({
    ...useTenantQuery([`security/user-roles/${basicProfile?.id}`, currentTenant]),
    // Only execute this query when we have both the profile and tenant
    enabled: !!basicProfile && !!currentTenant && !isLoadingInitial,
  });

  const profileData = {
    ...basicProfile,
    roles: rolesResponse,
  }

  if (isLoadingInitial || isLoadingRoles) {
    return <FullPageSpinner />;
  }

  if (initialError || rolesError) {
    return <div>Error loading profile</div>;
  }

  return (
    <>
      {profileData && (
        <ContextUpdateWrapper
          profileData={profileData}
        />
      )}
      {children}
    </>
  );
};

export default ContextComposer;
