import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import get from "lodash/get";
import config from "config";

import { withAppContext } from "context";
import withAuth from "components/Auth";
import UserDropdown from "components/UserDropdown";
import ReleaseNotesIndicator from "../ReleaseNotesIndicator";
import Tooltip from "../Tooltip";

import logo from "assets/logo.png";
import Nav from "../../modules/ui-kit-react/lib/components/Internal/Nav";
import style from "./style.module.css";

function Header({ auth, tenant }) {
  const location = useLocation();

  const [renderError, setRenderError] = useState(false);

  const renderDevTools = () => {
    const error = () => {
      throw new Error("Test Render Error");
    };

    return (
      <span className={style.devTools}>
        <i
          onClick={() => navigator.clipboard.writeText(auth.token)}
          className="material-icons"
          data-tip="Copy JWT"
        >
          file_copy
        </i>
        <i
          onClick={() => setRenderError(true)}
          className="material-icons"
          data-tip="Trigger Render Error"
        >
          error
        </i>
        {renderError ? error() : null}
      </span>
    );
  };

  // Right side elements: Dev tools, release notes, user dropdown
  const renderRightSide = () => (
    <div className={style.rightSide}>
      {process.env.NODE_ENV === "development" ? renderDevTools() : null}
      <ReleaseNotesIndicator />
      <UserDropdown user={auth.profile} logout={auth.logout} />
    </div>
  );

  // Build up routes for the nav
  const routes = [];
  const superAdmin = get(auth.profile, "superAdmin", false);

  if (tenant) {
    routes.push({ label: "Projects", path: `/${tenant}/projects` });
    if (auth.hasRole("ADMIN")) {
      routes.push({ label: "Admin", path: `/${tenant}/admin` });
    }
  } else {
    routes.push({ label: "Select Tenant", path: `/select-tenant` });
  }

  if (superAdmin) {
    routes.push({ label: "Super Admin", path: `/super-admin` });
  }

  // Right routes (docs in dev)
  const rightRoutes = [
    {
      label: "Docs",
      path: "https://shield-docs.rvohealth.dev/docs/getting-started/intro.html",
      template: (route) => (
        <a target="_blank" rel="noopener noreferrer" href={route.path}>
          {route.label}
        </a>
      ),
    },
    {
      label: "API",
      path: `${config.API.BASE_URL}/docs`,
      template: (route) => (
        <a target="_blank" rel="noopener noreferrer" href={route.path}>
          {route.label}
        </a>
      ),
    },
  ];

  return (
    <>
      <Nav
        theme={{ navWrap: style.navWrap }}
        template={(route) => <Link to={route.path}>{route.label}</Link>}
        activePath={location.pathname}
        logo={
          <Link to="/" className={style.logoLink}>
            <img
              className={classnames(style.logoIcon)}
              src={logo}
              alt="logo"
              data-cy="logo"
            />
          </Link>
        }
        routes={routes}
        rightRoutes={rightRoutes}
        rightSide={renderRightSide()}
        underhang
        fluid={false}
      />
      <Tooltip />
    </>
  );
}

Header.propTypes = {
  auth: PropTypes.shape({
    token: PropTypes.string,
    logout: PropTypes.func,
    profile: PropTypes.shape({
      username: PropTypes.string,
      superAdmin: PropTypes.bool,
    }),
    hasRole: PropTypes.func,
  }).isRequired,
  tenant: PropTypes.string,
};

export default withAppContext(withAuth(Header));
