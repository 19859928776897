import React, { useState, FC } from 'react';
import { repeat } from 'lodash';
import style from './style.module.css';

interface ComponentProps {
  value?: String;
}

const Component: FC<ComponentProps> = ({ value }) => {
  const [isDisplayed, setDisplayed] = useState(false);
  const length = (value && value.length) || 0;

  return (
    <div className={style.content}>
      <code>{isDisplayed ? value : repeat('•', length)}</code>

      <i className="material-icons" onClick={() => setDisplayed(!isDisplayed)}>
        {isDisplayed ? 'visibility_off' : 'visibility'}
      </i>
    </div>
  );
};

export default Component;
