import React from "react";
import { useNavigate } from "react-router-dom";
import Protected from "components/Protected";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import style from "../style.module.css";

interface Environment {
  id: string;
  lastDeployment: any | null;
  privileged: boolean;
  providerConfiguration: any | null;
}

interface Project {
  id: string;
}

interface ActionsProps {
  env: Environment;
  project: Project;
  providerClick: () => void;
}

const Actions: React.FC<ActionsProps> = ({ env, project, providerClick }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id, privileged } = env;

  const archiveEnvironment = useAuthenticatedMutation<void, void>(
    `environments/${id}/archive`,
    {
      requiresTenant: true,
      method: "POST",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`projects/${project.id}`],
        });
      },
    }
  );

  return (
    <div className={style.actions} data-cy="environment-actions">
      {env.id && (
        <Protected hasRole="ADMIN">
          <i
            className="material-icons"
            onClick={providerClick}
            data-tip="Edit Deployment Configuration"
          >
            code
          </i>
        </Protected>
      )}

      <Protected hasRole={privileged ? "ADMIN" : "USER"}>
        <i
          className="material-icons"
          onClick={() => navigate(`../../environments/${id}/edit`)}
          data-tip="Edit environment"
        >
          edit
        </i>
      </Protected>

      <Protected hasRole="ADMIN">
        <i
          className={`material-icons ${style.archiveButton}`}
          onClick={() => archiveEnvironment.mutate()}
          data-tip="Archive environment"
          style={{
            cursor: archiveEnvironment.isPending ? "wait" : "pointer",
          }}
        >
          archive
        </i>
      </Protected>
    </div>
  );
};

export default Actions;
