import React from 'react';
import { Alert } from '../../modules/ui-kit-react/lib';
import { get } from 'lodash';
import CheckBox from '../Form/Checkbox';
import { useQuery } from '@tanstack/react-query';
import { useTenantQuery, useAuthenticatedMutation } from 'components/Auth/AuthenticatedQueryProvider';
import { TenantFeature } from 'types/api';

const features = {
  ECS_DETAILS: 'Show ECS task definition details'
};

const TenantFeatures: React.FC = () => {
  const { data, error, isLoading, refetch } = useQuery<TenantFeature[]>(
    useTenantQuery(['features'])
  );

  const updateFeature = useAuthenticatedMutation<TenantFeature, { feature: string; value: boolean }>(
    'features',
    {
      requiresTenant: true,
      method: 'PUT',
      onSuccess: () => refetch()
    }
  );

  if (error) {
    return <Alert type="danger" message={error.message} />;
  }

  if (!data || isLoading) {
    return null;
  }

  return (
    <>
      {data.map((feature) => (
        <CheckBox
          name={feature.key}
          key={feature.key}
          defaultValue={feature.value}
          label={get(features, feature.key, feature.key)}
          onChange={(value: boolean): void => {
            updateFeature.mutate({
              feature: feature.key,
              value
            });
          }}
          standAlone
        />
      ))}
    </>
  );
};

export default TenantFeatures;
