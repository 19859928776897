import React, { ReactNode } from 'react';
import { Container } from '../../modules/ui-kit-react/lib';
import style from './style.module.css';

interface SubNavProps {
  children: ReactNode;
}

const SubNav: React.FC<SubNavProps> = ({ children }) => (
  <Container>
    <nav className={style.nav}>{children}</nav>
  </Container>
);

export default SubNav;
