import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withShortcut } from 'components/Shortcuts';
import { flowRight } from 'lodash';
import style from './style.module.css';
import ProjectGroupSelector from '../ProjectGroupSelector';

interface Props {
  close: () => void;
  auth: {
    tenant: string;
  };
}

function ProjectGroupSwitcher({ close, auth: { tenant } }: Props) {
  const navigate = useNavigate();

  if (!tenant) {
    return <div className={style.message}>Please select a tenant first.</div>;
  }

  return (
    <ProjectGroupSelector
      autoFocus
      menuIsOpen
      isMulti={false}
      placeholder="Switch Project Group..."
      onChange={({ value }: any) => {
        navigate(`/${tenant}/project-groups/${value}`);
        close();
      }}
    />
  );
}

export default flowRight(withShortcut('g'))(ProjectGroupSwitcher);
