import React, { useState } from "react";
import classnames from "classnames";
import Protected from "components/Protected";
import ReferenceInlineAdd from "components/ReferenceInlineAdd";
import ReferenceInlineEdit from "components/ReferenceInlineEdit";
import { useQueryClient } from "@tanstack/react-query";
import { useProjectReferences } from "hooks/project-hooks";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { ProjectWithReferences } from "../../pages/Projects/Edit";
import style from "./style.module.css";

interface ReferencesProps {
  project: ProjectWithReferences;
}

const References: React.FC<ReferencesProps> = ({ project }) => {
  const { data: references } = useProjectReferences(project?.id);
  const [addingReference, setAddingReference] = useState(false);
  const [editingReferenceId, setEditingReferenceId] = useState<number | null>(
    null
  );
  const queryClient = useQueryClient();

  const deleteReference = useAuthenticatedMutation<
    void,
    { referenceId: number }
  >(`projects/${project.id}/references/:referenceId`, {
    requiresTenant: true,
    method: "DELETE",
    pathParams: (variables) => ({
      referenceId: variables.referenceId.toString(),
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [`projects/${project.id}/references`] });
    },
  });

  return (
    <div className={style.links}>
      {references?.map((ref) => (
        <React.Fragment key={ref.id}>
          <ul className={style.envLinks} data-cy="reference-list">
            <li>
              <div className={style.linkName}>
                <a href={ref.url} target="_blank" rel="noopener noreferrer">
                  <i className="material-icons">open_in_new</i>
                  {ref.name}
                </a>
              </div>
              <Protected hasRole="USER">
                <div className={style.actions}>
                  <i
                    onClick={(e) => {
                      e.preventDefault();
                      setEditingReferenceId(ref.id);
                    }}
                    className={classnames("material-icons", style.edit)}
                  >
                    edit
                  </i>
                  <i
                    onClick={() =>
                      deleteReference.mutate({ referenceId: ref.id })
                    }
                    className={classnames("material-icons", style.delete)}
                    data-cy="delete-reference-btn"
                  >
                    delete
                  </i>
                </div>
              </Protected>
            </li>
          </ul>
          {editingReferenceId === ref.id && (
            <ReferenceInlineEdit
              project={project}
              reference={ref}
              onClose={() => setEditingReferenceId(null)}
            />
          )}
        </React.Fragment>
      ))}

      {addingReference ? (
        <ReferenceInlineAdd
          project={project}
          onClose={() => setAddingReference(false)}
        />
      ) : (
        <Protected hasRole="USER">
          <div onClick={() => setAddingReference(true)} className={style.add}>
            <i className="material-icons">add</i>
            <span>Add Reference</span>
          </div>
        </Protected>
      )}
    </div>
  );
};

export default References;
