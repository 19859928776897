import { components, operations } from "./generated/generated";
export * from "./generated/generated";

// Re-exporting everything above, but here
// we'll export specifically used types with better names
export type CreateReleaseNoteRequest =
  components["schemas"]["CreateReleaseNoteRequest"];
export type UpdateReleaseNoteRequest =
  components["schemas"]["UpdateReleaseNoteRequest"];
export type PaginatededProjectResponse =
  components["schemas"]["PaginatedProject"];
export type Tag = components["schemas"]["Tag"];
export type UpdateProjectGroupRequest =
  components["schemas"]["UpdateProjectGroupRequest"];
export type PaginatedDeployment = components["schemas"]["PaginatedDeployment"];
export type Badge = components["schemas"]["Badge"];
export type WebHook = components["schemas"]["WebHook"];
export type AwsProfile = components["schemas"]["AwsProfile"];
export type Reference = components["schemas"]["Reference"];
export type DeploymentLogEntry = components["schemas"]["DeploymentLogEntry"];
export type BuildInfo = components["schemas"]["BuildInfo"];
export type HealthResponse = components["schemas"]["HealthResponse"];
export type SlackAlert = components["schemas"]["SlackAlert"];
export type SlackReminder = components["schemas"]["SlackReminder"];
export type Environment = components["schemas"]["Environment"];
export type ProjectGroup = components["schemas"]["ProjectGroup"];
export type Deployment = components["schemas"]["Deployment"];
export type BasicLambdaConfiguration =
  components["schemas"]["BasicLambdaConfiguration"];
export type CodebuildConfiguration =
  components["schemas"]["CodebuildConfiguration"];
export type EcsConfiguration = components["schemas"]["EcsConfiguration"];
export type EcsScheduledTaskConfiguration =
  components["schemas"]["EcsScheduledTaskConfiguration"];
export type EksHelmConfiguration =
  components["schemas"]["EksHelmConfiguration"];
export type S3Configuration = components["schemas"]["S3Configuration"];
export type TaskTestConfiguration =
  components["schemas"]["TaskTestConfiguration"];
export type TenantFeature = components["schemas"]["TenantFeature"];
export type EcsCluster = components["schemas"]["EcsCluster"];
export type EksCluster = components["schemas"]["EksCluster"];
export type SimpleProject = components["schemas"]["SimpleProject"];
export type Project = components["schemas"]["Project"];
export type User = components["schemas"]["User"];
export type UserRole = components["schemas"]["UserRole"];
export type AuditLog = components["schemas"]["AuditLog"];
export type PaginatedAuditLog = components["schemas"]["PaginatedAuditLog"];
export type PaginatedProjectGroup =
  components["schemas"]["PaginatedProjectGroup"];
export type Token = components["schemas"]["Token"];
export type ReleaseNote = components["schemas"]["ReleaseNote"];
export type CreateAwsProfileRequest =
  components["schemas"]["CreateAwsProfileRequest"];
export type CreateProjectGroupRequest =
  components["schemas"]["CreateProjectGroupRequest"];
export type CreateEnvironmentRequest =
  components["schemas"]["CreateEnvironmentRequest"];
export type UpdateEnvironmentRequest =
  components["schemas"]["UpdateEnvironmentRequest"];
export type UpdateProjectRequest =
  components["schemas"]["UpdateProjectRequest"];
export type UpdateEnvironmentDeploymentConfiguration =
  operations["UpdateEnvironmentDeploymentConfiguration"];

export enum ProviderType {
  Ecs = "ecs",
  EcsScheduledTask = "ecs-scheduled-task",
  EksHelm = "eks-helm",
  Lambda = "lambda",
  S3 = "s3",
  CodeBuild = "codebuild",
  TaskTest = "task-test",
}

export type DeploymentStatus =
  | "PENDING"
  | "STARTED"
  | "FAILED"
  | "COMPLETED"
  | "CANCELING"
  | "CANCELED"
  | "RETRYING"
  | "UNKNOWN";

export type Provider = ProviderType;

export enum UserType {
  Normal = "NORMAL",
  Service = "SERVICE",
}

export enum UserRoleValue {
  Admin = "ADMIN",
  Config = "CONFIG",
  Deployer = "DEPLOYER",
  User = "USER",
}
