import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedMutation, useTenantQuery } from 'components/Auth/AuthenticatedQueryProvider';
import { SlackAlert } from 'types/api';

interface CreateSlackAlertRequest {
  channel: string;
  on: string[];
  environmentId: number;
}

interface UpdateSlackAlertRequest {
  channel: string;
  on: string[];
}

// Hook to fetch slack alerts
export const useListSlackAlerts = (environmentId: number) => {
    return useQuery<SlackAlert[]>({
      ...useTenantQuery([`environments/${environmentId}/slack-alerts`]),
    });
  };

// Hook to fetch environment data
export const useEnvironment = (environmentId: number) => {
  return useQuery({
    ...useTenantQuery([`environments/${environmentId}`]),
  });
};

// Hook to create a slack alert
export const useCreateSlackAlert = (environmentId: number) => {
  const queryClient = useQueryClient();
  
  return useAuthenticatedMutation<SlackAlert, CreateSlackAlertRequest>(
    `environments/${environmentId}/slack-alerts`,
    {
      requiresTenant: true,
      onSuccess: () => {
        // Invalidate environment query to refetch updated slack alerts
        queryClient.invalidateQueries({
          queryKey: [`environments/${environmentId}`],
        });
      },
    }
  );
};

// Hook to update a slack alert
export const useUpdateSlackAlert = (slackAlertId: number) => {  
  return useAuthenticatedMutation<SlackAlert, UpdateSlackAlertRequest>(
    `slack-alerts/${slackAlertId}`,
    {
      requiresTenant: true,
      method: 'PUT'
    }
  );
};

// Hook to delete a slack alert
export const useDeleteSlackAlert = (environmentId: number) => {
  const queryClient = useQueryClient();
  
  return useAuthenticatedMutation<void, { id: number }>(
    'slack-alerts',
    {
      requiresTenant: true,
      method: 'DELETE',
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`environments/${environmentId}`],
        });
      },
    }
  );
};

// Helper function to get allowed status values
export const getAllowedStatusValues = () => [
  'PENDING',
  'STARTED',
  'FAILED',
  'COMPLETED',
  'CANCELING',
  'CANCELED',
];
