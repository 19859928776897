import React from "react";
import { Alert } from "../../../modules/ui-kit-react/lib";
import MultiSelectInput, { Option } from "components/Form/MultiSelectInput";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";

interface Props {
  clusterId?: string | null;
  clusterName?: string;
  service?: string | null;
}

function getOptions(ecsServices?: string[] | null): Option[] {
  const services = ecsServices || [];
  services.sort();
  return services.map((service) => ({
    label: service,
    value: service,
  }));
}

function getDefaultValue(
  service?: string | null,
  ecsServices?: string[] | null
): string | null {
  if (service) return service;
  if (ecsServices?.length === 1 && ecsServices[0]) return ecsServices[0];
  return null;
}

export default function ServiceSelect({
  clusterId,
  clusterName,
  service,
}: Props) {
  if (!clusterId) return null;

  const { data, error, isLoading } = useQuery<string[]>(
    useTenantQuery([`providers/aws/ecs-clusters/${clusterId}/services`])
  );

  if (error) return <Alert message={error.message} type="danger" />;

  return (
    <MultiSelectInput
      name="providerConfiguration[service]"
      label="Service"
      defaultValue={getDefaultValue(service, data || [])}
      options={getOptions(data || [])}
      isMulti={false}
      isLoading={isLoading}
      isDisabled={!clusterId}
      noOptionsMessage={() => {
        const msg = "No Services Found";
        return clusterName ? `${msg} in "${clusterName}"` : msg;
      }}
      required
    />
  );
}
