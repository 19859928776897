import React, { useState } from "react";
import { get } from "lodash";
import Button from "../../../modules/ui-kit-react/lib/components/Button/index";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import TagSelector from "../../TagSelector";
import { Environment, Project, Tag } from "types/api";
import style from "../style.module.css";

interface DeployToolProps {
  environment: Environment;
  project: Project;
  changePane?: (pane: string) => void;
  setError: (error: Error) => void;
}

const DeployTool: React.FC<DeployToolProps> = ({
  environment,
  project,
  changePane,
  setError,
}) => {
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const queryClient = useQueryClient();

  const createDeployment = useAuthenticatedMutation<
    any,
    { artifactId: string; ref: string }
  >(`environments/${environment.id}/deployments`, {
    requiresTenant: true,
    onSuccess: () => {
      // Invalidate latest deployment query
      queryClient.invalidateQueries({
        queryKey: [`environments/${environment.id}/deployments`],
      });
      // Change pane back to latest-deployment after successful deployment
      if (changePane) {
        changePane("latest-deployment");
      }
    },
    onError: setError,
  });

  const handleDeploy = () => {
    if (!selectedTag) return;
    
    createDeployment.mutate({
      artifactId: selectedTag.name,
      ref: selectedTag.sha,
    });
  };

  return (
    <>
      <TagSelector
        currentTag={get(environment, "lastDeployment.artifactName", "")}
        environmentId={environment.id}
        repoUrl={project.repository || ""}
        onTagSelect={(tag) => setSelectedTag(tag)}
      />
      <div className={style.foot}>
        <Button
          raised
          appearance="primary"
          onClick={handleDeploy}
        >
          {selectedTag === null ? "Select a Tag" : "Deploy"}
        </Button>
        <Button
          raised
          appearance="danger"
          onClick={() => changePane?.("latest-deployment")}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default DeployTool;
