import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import { Row, Alert } from "../../../modules/ui-kit-react/lib";
import { PaginatedProjectGroup } from "types/api";
import style from "./style.module.css";
import ProjectGroupBox from "./ProjectGroupBox";

const ProjectGroups = () => {
  const { data: groups, error } = useQuery<PaginatedProjectGroup>(
    useTenantQuery(["project-groups"])
  );

  if (error) {
    return <Alert type="danger" message={error.message} />;
  }

  if (!groups || groups.entries?.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={style.heading}>Project Groups</div>
      <Row>
        {groups.entries?.map((g) => (
          <ProjectGroupBox key={g.id} projectGroup={g} />
        ))}
      </Row>
    </React.Fragment>
  );
};

export default ProjectGroups;
