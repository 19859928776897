import React from "react";
import AwsProfileForm from "components/AwsProfileForm/index";
import Title from "components/Title";
import { humanize } from "utils/enums";
import classnames from "classnames";
import {
  Alert,
  Button,
  Table,
  Row,
  Col,
  Card,
  CardHead,
  CardContent,
} from "../../../../modules/ui-kit-react/lib";
import { CircularProgress } from "@mui/material";
import style from "./style.module.css";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import LoadingPage from "components/LoadingPage";
import { useParams, useNavigate } from "react-router-dom";

const AwsProfilesEdit = () => {
  const { id: profileId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Fetch AWS profile
  const {
    data: profile,
    error: profileError,
    isLoading: profileLoading,
  } = useQuery(useTenantQuery([`providers/aws/profiles/${profileId}`]));

  // Fetch ECS clusters
  const {
    data: ecsClusters,
    error: ecsClustersError,
    isLoading: ecsClustersLoading,
  } = useQuery(useTenantQuery(["providers/aws/ecs-clusters"]));
  const filteredEcsClusters = ecsClusters?.filter(cluster => cluster.awsProfileId.toString() === profileId) || [];

  // Fetch EKS clusters
  const {
    data: eksClusters,
    error: eksClustersError,
    isLoading: eksClustersLoading,
  } = useQuery(useTenantQuery(["providers/aws/eks-clusters"]));
  const filteredEksClusters = eksClusters?.filter(cluster => cluster.awsProfileId.toString() === profileId) || [];

  // Update profile mutation
  const updateProfile = useAuthenticatedMutation(
    `providers/aws/profiles/${profileId}`,
    {
      requiresTenant: true,
      method: "PUT",
      onSuccess: () => {
        navigate("..");
      },
    }
  );

  // Import ECS clusters mutation
  const importEcsClusters = useAuthenticatedMutation(
    "providers/aws/ecs-clusters/import",
    {
      requiresTenant: true,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["providers/aws/ecs-clusters"],
        });
      },
      queryParams: (variables) => ({
        awsProfileId: variables.awsProfileId,
      }),
    }
  );

  // Import EKS clusters mutation
  const importEksClusters = useAuthenticatedMutation(
    "providers/aws/eks-clusters/import",
    {
      requiresTenant: true,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["providers/aws/eks-clusters"],
        });
      },
      queryParams: (variables) => ({
        awsProfileId: variables.awsProfileId,
      }),
    }
  );

  // Delete ECS cluster mutation
  const deleteEcsCluster = useAuthenticatedMutation(
    "providers/aws/ecs-clusters/:id",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["providers/aws/ecs-clusters"],
        });
      },
      pathParams: (variables) => ({
        id: variables.id.toString(),
      }),
    }
  );

  // Delete EKS cluster mutation
  const deleteEksCluster = useAuthenticatedMutation(
    "providers/aws/eks-clusters/:id",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["providers/aws/eks-clusters"],
        });
      },
      pathParams: (variables) => ({
        id: variables.id.toString(),
      }),
    }
  );

  if (profileError || ecsClustersError || eksClustersError) {
    throw profileError || ecsClustersError || eksClustersError;
  }

  if (!profile || profileLoading || ecsClustersLoading || eksClustersLoading) {
    return <LoadingPage />;
  }

  const renderRefreshButton = (isPending, isError, onClick) => (
    <Button appearance="primary" lowered onClick={onClick} disabled={isPending}>
      {isPending ? (
        <span className={style.spinnerContainer}>
          <span className={style.buttonText}>Syncing</span>
          <CircularProgress size={16} className={style.buttonSpinner} />
        </span>
      ) : (
        <>
          Resync
          <i
            className={classnames("material-icons", style.resync, {
              [style.loading]: isPending,
            })}
          >
            {isError ? "sync_problem" : "sync"}
          </i>
        </>
      )}
    </Button>
  );

  const renderNoClusters = (type) => (
    <Alert
      type="warning"
      message={
        <p aria-label={`no-${type}-clusters`}>
          <b>
            <span role="img" aria-label="warning">
              ⚠️
            </span>
            This profile does not have any {type.toUpperCase()} clusters
            associated with it.
          </b>
          <br />
          <br />
          Hit the &#34;Resync&#34; button above if you would like to use this
          profile for {type.toUpperCase()} deployments.
        </p>
      }
    />
  );

  return (
    <>
      <Title title="Manage AWS Profile" />
      <CardHead>Manage AWS Profile</CardHead>

      <CardContent>
        <Row>
          <Col md={6} xs={12}>
            <Card>
              <CardHead>Edit Detail</CardHead>
              <CardContent>
                {updateProfile.error ? (
                  <Alert type="danger" message={updateProfile.error.message} />
                ) : null}
                <AwsProfileForm
                  gqlError={updateProfile.error}
                  loading={updateProfile.isLoading}
                  edit
                  awsProfile={profile}
                  onSubmit={(form) =>
                    updateProfile.mutate({
                      externalId: form.externalId,
                      name: form.name,
                      roleArn: form.roleArn,
                    })
                  }
                />
              </CardContent>
            </Card>

            <Card>
              <CardHead className={style.head}>
                <div>ECS Clusters</div>
                <div>
                  {renderRefreshButton(
                    importEcsClusters.isPending,
                    importEcsClusters.isError,
                    () => {
                      importEcsClusters.mutate({
                        awsProfileId: profile.id,
                      });
                    }
                  )}
                </div>
              </CardHead>

              <CardContent>
                {importEcsClusters.isLoading ? (
                  <div className={style.loadingContainer}>
                    <CircularProgress size={40} />
                    <p>Syncing ECS clusters...</p>
                  </div>
                ) : ecsClusters?.length ? (
                  <Table
                    paginationSize={10}
                    columns={[
                      {
                        heading: "Name",
                        path: "name",
                        sort: true,
                        className: style.nameCol,
                      },
                      {
                        heading: "Region",
                        path: "region",
                        sort: true,
                        template: ({ region }) => humanize(region),
                      },
                      {
                        heading: "Actions",
                        template: (cluster) => (
                          <Button
                            appearance="danger"
                            small
                            raised
                            flat
                            onClick={() => {
                              deleteEcsCluster.mutate({ id: cluster.id });
                            }}
                            disabled={deleteEcsCluster.isLoading}
                          >
                            <i className="material-icons">delete</i>
                          </Button>
                        ),
                      },
                    ]}
                    data={filteredEcsClusters}
                  />
                ) : (
                  renderNoClusters("ecs")
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHead className={style.head}>
                <div>EKS Clusters</div>
                <div>
                  {renderRefreshButton(
                    importEksClusters.isPending,
                    importEksClusters.isError,
                    () => {
                      importEksClusters.mutate({
                        awsProfileId: profile.id,
                      });
                    }
                  )}
                </div>
              </CardHead>

              <CardContent>
                {importEksClusters.isLoading ? (
                  <div className={style.loadingContainer}>
                    <CircularProgress size={40} />
                    <p>Syncing EKS clusters...</p>
                  </div>
                ) : eksClusters?.length ? (
                  <Table
                    paginationSize={10}
                    columns={[
                      {
                        heading: "Name",
                        path: "name",
                        sort: true,
                        className: style.nameCol,
                      },
                      {
                        heading: "Region",
                        path: "region",
                        sort: true,
                        template: ({ region }) => humanize(region),
                      },
                      {
                        heading: "Actions",
                        template: (cluster) => (
                          <Button
                            appearance="danger"
                            small
                            raised
                            flat
                            onClick={() => {
                              deleteEksCluster.mutate({ id: cluster.id });
                            }}
                            disabled={deleteEksCluster.isLoading}
                          >
                            <i className="material-icons">delete</i>
                          </Button>
                        ),
                      },
                    ]}
                    data={filteredEksClusters}
                  />
                ) : (
                  renderNoClusters("eks")
                )}
              </CardContent>
            </Card>
          </Col>
        </Row>
      </CardContent>
    </>
  );
};

export default AwsProfilesEdit;
