import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProjectGroupsView from '../../pages/ProjectGroups/View';
import ProjectGroupsEdit from '../../pages/ProjectGroups/Edit';

function ProjectGroups() {
  return (
    <Routes>
      <Route path=":id/edit" element={<ProjectGroupsEdit />} />
      <Route path=":id/*" element={<ProjectGroupsView />} />
    </Routes>
  );
}

export default ProjectGroups;
