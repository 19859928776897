import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTenant } from 'context/TenantContext';
import withAuth from 'components/Auth';
import PropTypes from 'prop-types';
import TextInput from 'components/Form/TextInput';
import Button from '../../modules/ui-kit-react/lib/components/Button';
import { Form } from '../../modules/react-forms/lib';
import styles from './style.module.css';
import extractFieldError from '../../utils/gqlErrors';

function AwsProfileForm({
  onSubmit,
  loading = false,
  awsProfile = {},
  edit = false,
  gqlError = null
}) {
  const navigate = useNavigate();
  
  const { currentTenant: tenant } = useTenant();

  const cancel = () => {
    navigate(`/${tenant}/admin/aws-profiles`);
  };

  const getError = (field) => extractFieldError(gqlError, field);

  return (
    <Form onValidSubmit={onSubmit}>
      <TextInput
        name="name"
        label="Name"
        placeholder="e.g. Production"
        defaultValue={awsProfile.name}
        error={getError('name')}
      />

      <TextInput
        name="roleArn"
        label="Role ARN"
        placeholder="e.g. arn:aws:iam::799335648850:role/app.shield"
        defaultValue={awsProfile.roleArn}
        error={getError('role_arn')}
      />

      <TextInput
        label="ExternalID"
        name="externalId"
        defaultValue={awsProfile.externalId || tenant}
        hint={
          <div className={styles.externalId}>
            The External ID in the condition element in a role’s trust policy...
          </div>
        }
      />

      <div className={styles.actions}>
        <Button
          data-cy="submit-aws-profile-btn"
          name="save"
          disabled={loading}
          raised
          appearance="primary"
          type="submit"
        >
          {edit ? 'Update' : 'Create'}
        </Button>
        {!edit && (
          <Button
            data-cy="cancel-aws-profile-btn"
            name="cancel"
            disabled={loading}
            lowered
            appearance="danger"
            onClick={cancel}
          >
            Cancel
          </Button>
        )}
      </div>
    </Form>
  );
}

AwsProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  awsProfile: PropTypes.shape({
    name: PropTypes.string,
    roleArn: PropTypes.string,
  }),
  edit: PropTypes.bool,
  gqlError: PropTypes.shape({
    graphQLErrors: PropTypes.array,
  })
};

export default withAuth(AwsProfileForm);
