import React, { useEffect } from "react";
import {
  Link,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import Dashboard from "pages/Admin/Dashboard";
import AuditLogsList from "pages/Admin/AuditLogs/List";
import AwsProfilesList from "pages/Admin/AwsProfiles/List";
import AwsProfilesNew from "pages/Admin/AwsProfiles/New";
import AwsProfilesEdit from "pages/Admin/AwsProfiles/Edit";
import UsersList from "pages/Admin/Users/List";
import UsersEdit from "pages/Admin/Users/Edit";
import Settings from "pages/Admin/Settings";
import HookTokens from "containers/Admin/HookTokens";
import ArchivedProjectsList from "pages/Admin/ArchivedProjects/List";
import Container from "components/Container";
import SubNav from "components/SubNav";
import withAuth from "components/Auth";

function Admin({ auth }) {
  const navigate = useNavigate();
  const { tenant } = useParams();

  useEffect(() => {
    if (!auth.profile.roles) {
      return;
    }

    if (!auth.hasRole("ADMIN")) {
      navigate("/");
    }
  }, [auth, navigate]);

  return (
    <>
      <SubNav>
        <Link to={`/${tenant}/admin/audit-logs`}>Audit Logs</Link>
        <Link to={`/${tenant}/admin/aws-profiles`}>AWS Profiles</Link>
        <Link to={`/${tenant}/admin/user-roles`}>User Roles</Link>
        <Link to={`/${tenant}/admin/hook-tokens`}>Hook Tokens</Link>
        <Link to={`/${tenant}/admin/settings`}>Settings</Link>
        <Link to={`/${tenant}/admin/archived-projects`}>Archived Projects</Link>
      </SubNav>

      <Container>
        <Routes>
          <Route path="/" element={<Navigate to="aws-profiles" replace />} />

          <Route path="dashboard" element={<Dashboard />} />
          <Route path="audit-logs" element={<AuditLogsList />} />

          <Route path="aws-profiles/new" element={<AwsProfilesNew />} />
          <Route path="aws-profiles/:id" element={<AwsProfilesEdit />} />
          <Route path="aws-profiles" element={<AwsProfilesList />} />

          <Route path="user-roles/:id" element={<UsersEdit />} />
          <Route path="user-roles" element={<UsersList />} />

          <Route path="hook-tokens/*" element={<HookTokens />} />

          <Route path="settings" element={<Settings />} />

          <Route path="archived-projects" element={<ArchivedProjectsList />} />
        </Routes>
      </Container>
    </>
  );
}

export default withAuth(Admin);
