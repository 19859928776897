import React, { FC, useState } from "react";
import classnames from "classnames";
import { buildTagComparisonUrl } from "utils/github";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";

interface Tag {
  name: string;
  url: string;
  sha: string;
}

interface Props {
  onTagSelect?: (tag: Tag) => void;
  environmentId: number | null;
  currentTag: string;
  repoUrl: string;
}

const CompareUrl: FC<Pick<Props, "repoUrl" | "currentTag"> & { tag: Tag }> = ({
  repoUrl,
  currentTag,
  tag,
}) => {
  if (!currentTag) return null;
  if (tag.name === currentTag) {
    return <span className={style.current}>Current</span>;
  }

  return (
    <span>
      <a
        className={style.compareLink}
        href={buildTagComparisonUrl(repoUrl, currentTag, tag.name)}
        target="_blank"
        rel="noopener noreferrer"
      >
        Compare
        <i className="material-icons">compare_arrows</i>
      </a>
    </span>
  );
};

const TagSelector: FC<Props> = ({
  environmentId,
  currentTag,
  onTagSelect,
  repoUrl,
}) => {
  const [selectedTag, selectTag] = useState<string>();

  const { data, isLoading, error } = useQuery<Tag[]>({
    ...useTenantQuery([`environments/${environmentId}/tags`]),
    enabled: !!environmentId,
  });

  if (isLoading) return <div className={style.loading}>Loading...</div>;
  if (error || !data) return <p>Unable to load tags.</p>;

  const tags = data || [];

  return (
    <div className={style.tagSelector}>
      <ul className={style.tags}>
        {tags.length ? (
          tags.map((tag: Tag) => (
            <li
              key={tag.name}
              className={classnames({
                [style.active]: selectedTag === tag.name,
              })}
              onClick={(): void => {
                if (!onTagSelect) return;
                selectTag(tag.name);
                onTagSelect(tag);
              }}
            >
              <div className={style.tagName}>{tag.name}</div>
              <CompareUrl tag={tag} currentTag={currentTag} repoUrl={repoUrl} />
            </li>
          ))
        ) : (
          <li className={style.noTags}>No Tags found</li>
        )}
      </ul>
    </div>
  );
};

export default TagSelector;
