import React, { FC } from "react";
import { Col, Paper } from "../../../modules/ui-kit-react/lib";
import { ProjectGroup } from "types/api";
import { Link, useParams } from "react-router-dom";
import style from "./style.module.css";

interface RouteParams {
  tenant: string;
}

interface ComponentProps {
  projectGroup: ProjectGroup;
}

const ProjectGroupComponent: FC<ComponentProps> = ({ projectGroup }) => {
  const { tenant } = useParams<Readonly<RouteParams>>();

  return (
    <Col
      key={projectGroup.id}
      sm={3}
      style={{
        paddingInline: "4px",
      }}
    >
      <Paper depth={1} className={style.group}>
        <div className={style.name}>
          <i className="material-icons">folder</i>
          <Link to={`/${tenant}/project-groups/${projectGroup.id}`}>
            {projectGroup.name}
          </Link>
        </div>

        <div className={style.chip}>
          {projectGroup.projectIDs ? projectGroup.projectIDs.length : 0}
        </div>
      </Paper>
    </Col>
  );
};

export default ProjectGroupComponent;
