import React from "react";
import { Routes, Route } from "react-router-dom";
import ProjectsList from "pages/Projects/List";
import ProjectsView from "pages/Projects/View";
import ProjectsNew from "pages/Projects/New";
import EnvironmentsAdd from "pages/Environments/Add";
import ProjectsEdit from "pages/Projects/Edit";

export default function Projects() {
  return (
    <>
      <Routes>
        <Route path="new" element={<ProjectsNew />} />
        <Route path=":projectId/environments/new" element={<EnvironmentsAdd />} />
        <Route path=":id/edit" element={<ProjectsEdit />} />
        <Route path=":id" element={<ProjectsView />} />
        <Route index element={<ProjectsList />} />
      </Routes>
    </>
  );
}
