import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthenticatedMutation, useTenantQuery } from 'components/Auth/AuthenticatedQueryProvider';
import { SimpleProject, Reference } from 'types/api';

interface CreateReferenceRequest {
  name: string;
  url: string;
}

interface UpdateReferenceRequest {
  name: string;
  url: string;
}

export const useListProjects = () => {
  return useQuery<SimpleProject[]>({
    ...useTenantQuery([`projects/all`]),
  });
};

// Hook to fetch project data
export const useProject = (projectId: number) => {
  return useQuery({
    ...useTenantQuery([`projects/${projectId}`])
  });
};

export const useProjectReferences = (projectId?: number) => {
  return useQuery<Reference[]>({
    ...useTenantQuery([`projects/${projectId}/references`])
  });
};

// Hook to create a reference
export const useCreateReference = (projectId: number) => {
  const queryClient = useQueryClient();
  
  return useAuthenticatedMutation<Reference, CreateReferenceRequest>(
    `projects/${projectId}/references`,
    {
      requiresTenant: true,
      onSuccess: () => {
        // Invalidate project query to refetch updated references
        queryClient.invalidateQueries({
          queryKey: [`projects/${projectId}`]
        });
        queryClient.invalidateQueries({
          queryKey: [`projects/${projectId}/references`]
        });
      }
    }
  );
};

// Hook to update a reference
export const useUpdateReference = (projectId: number, referenceId: number) => {
  const queryClient = useQueryClient();
  
  return useAuthenticatedMutation<Reference, UpdateReferenceRequest>(
    `projects/${projectId}/references/${referenceId}`,
    {
      requiresTenant: true,
      method: 'PUT',
      onSuccess: () => {
        // Invalidate project query to refetch updated references
        queryClient.invalidateQueries({
          queryKey: [`projects/${projectId}`]
        });
        queryClient.invalidateQueries({
          queryKey: [`projects/${projectId}/references`]
        });
      }
    }
  );
};
