import React from 'react';
import InfraDiagram from 'components/InfraDiagram';
import PropTypes from 'prop-types';
import { Alert, Table } from '../../modules/ui-kit-react/lib';
import { useQuery } from '@tanstack/react-query';
import { useTenantQuery } from 'components/Auth/AuthenticatedQueryProvider';
import style from './style.module.css';

interface TaskDefinitionDetailsProps {
  environmentId: number;
}

interface EnvVar {
  key: string;
  value: string;
  sensitive: boolean;
}

interface EcsServiceStatus {
  serviceDetails: any;
  taskDefinitionDetails: {
    environment: EnvVar[];
  };
}

const TaskDefinitionDetails: React.FC<TaskDefinitionDetailsProps> = ({ environmentId }) => {
  const { data, error, isLoading } = useQuery<EcsServiceStatus>(
    useTenantQuery([`environments/${environmentId}/ecs-service-status`])
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) return <Alert type="danger" message={error.message} />;
  if (!data?.serviceDetails) return null;

  const {
    serviceDetails,
    taskDefinitionDetails: { environment }
  } = data;

  const valueTemplate = ({ value, sensitive }: EnvVar) => {
    if (!sensitive) {
      return value;
    }

    if (!value) {
      return <i className="material-icons">lock</i>;
    }

    return (
      <span className={style.sensitiveWrap}>
        <i className={`material-icons ${style.sensitiveIcon}`}>lock</i>
        &nbsp;
        {value}
      </span>
    );
  };

  return (
    <div>
      <div>
        <h2>Infrastructure Diagram</h2>
        <InfraDiagram serviceDetails={serviceDetails} />
      </div>
      <div className={style.envVars}>
        <h2>Environment Variables</h2>
        <Table
          paginationSize={20}
          data={environment}
          columns={[
            {
              heading: 'Key',
              path: 'key'
            },
            {
              heading: 'Value',
              path: 'value',
              template: valueTemplate
            }
          ]}
        />
      </div>
    </div>
  );
};

TaskDefinitionDetails.propTypes = {
  environmentId: PropTypes.number.isRequired
};

export default TaskDefinitionDetails;
