import { useQuery } from "@tanstack/react-query";
import { User } from "types/api";

export const useListUsers = () => {
  return useQuery<User[]>({
    queryKey: ["security/users"],
    meta: { requiresTenant: false },
  });
};

// Hook to fetch project data
export const useUser = (userId: number | undefined) => {
  return useQuery<User>({
    queryKey: [`security/users/${userId}`],
    enabled: !!userId,
  });
};
