import React from "react";
import Title from "components/Title";
import VisibilityToggle from "components/VisibilityToggle";
import moment from "moment";
import {
  CardHead,
  CardContent,
  Button,
  Table,
} from "../../../../modules/ui-kit-react/lib";
import { useQuery } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import { Token as HookToken } from "types/api";

const HookTokenList: React.FC = () => {
  const { data, error, isLoading, refetch } = useQuery<HookToken[]>(
    useTenantQuery(["security/hook-tokens"])
  );

  const createToken = useAuthenticatedMutation<HookToken, void>(
    "security/hook-tokens",
    {
      requiresTenant: true,
      onSuccess: () => {
        refetch();
      },
    }
  );

  const deleteToken = useAuthenticatedMutation<void, { id: number }>(
    "security/hook-tokens/:id",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        refetch();
      },
      pathParams: (variables) => ({ id: variables.id.toString() }),
    }
  );

  if (error) throw error;
  if (isLoading || !data) return null;

  const renderActions = (row: HookToken) => (
    <Button
      data-cy="delete-hook-btn"
      appearance="danger"
      small
      raised
      flat
      onClick={() => deleteToken.mutate({ id: row.id })}
    >
      <i className="material-icons">delete</i>
    </Button>
  );

  return (
    <>
      <Title title="Hook Tokens" />

      <CardHead className={style.head}>
        <div>Hook Tokens</div>
        <div className={style.actions}>
          <Button
            data-cy="add-hook-btn"
            appearance="primary"
            raised
            onClick={() => createToken.mutate()}
          >
            New
          </Button>
        </div>
      </CardHead>

      <CardContent>
        <Table
          paginationSize={15}
          columns={[
            {
              heading: "Token",
              path: "token",
              sort: true,
              template: ({ token }) => <VisibilityToggle value={token} />,
            },
            {
              heading: "User",
              path: "displayName",
              sort: true,
            },
            {
              heading: "Created",
              path: "insertedAt",
              sort: true,
              template: ({ insertedAt }) => moment(insertedAt).fromNow(),
            },
            {
              heading: "Actions",
              sort: false,
              template: renderActions,
            },
          ]}
          data={data || []}
        />
      </CardContent>
    </>
  );
};

export default HookTokenList;
