import React, { FC, useState } from "react";
import Protected from "components/Protected";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { Button } from "../../modules/ui-kit-react/lib";
import SlackReminderInlineAdd from "./SlackReminderInlineAdd";
import { EnvironmentWithSlack } from "components/EnvForm";
import style from "../SlackAlerts/style.module.css";

interface Props {
  environment: EnvironmentWithSlack;
}

const SlackReminders: FC<Props> = ({ environment }) => {
  const [formActive, setFormActive] = useState(false);
  const queryClient = useQueryClient();

  const deleteReminder = useAuthenticatedMutation<void, { id: number }>(
    "slack-reminders/{reminderId}",
    {
      requiresTenant: true,
      method: "DELETE",
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`environments/${environment.id}`],
        });
      },
    }
  );

  const reminders = environment.slackReminders || [];

  return (
    <>
      <h4>Slack Reminders</h4>

      {reminders.map((reminder) => (
        <div className={style.alert} key={reminder.id}>
          <div className={style.alertChannel}>
            <div className={style.alertChannelName}>
              <i className="material-icons">alarm</i>
              <span>{reminder.channel}</span>
            </div>

            <Protected hasRole="USER">
              <div className={style.actions}>
                <Button
                  appearance="danger"
                  small
                  flat
                  icon="delete"
                  onClick={() => deleteReminder.mutate({ id: reminder.id })}
                />
              </div>
            </Protected>
          </div>
        </div>
      ))}

      {formActive ? (
        <SlackReminderInlineAdd
          environmentId={environment.id}
          onClose={() => setFormActive(false)}
        />
      ) : (
        <Protected hasRole="USER">
          <div onClick={() => setFormActive(true)} className={style.add}>
            <i className="material-icons">add</i>
            <span data-cy="add-channel-btn">Add Channel</span>
          </div>
        </Protected>
      )}
    </>
  );
};

export default SlackReminders;
