import React from "react";
import Pagination from "components/Pagination";
import { useQuery } from "@tanstack/react-query";
import { useTenantQuery } from "components/Auth/AuthenticatedQueryProvider";
import style from "./style.module.css";
import Deployment from "./Deployment";
import {
  Deployment as DeploymentItem,
  Environment,
  PaginatedDeployment,
} from "types/api";

interface DeploymentListProps {
  environment: Environment;
  page: number;
  updatePage: (page: number) => void;
}

const DeploymentList: React.FC<DeploymentListProps> = ({
  environment,
  page,
  updatePage,
}) => {
  const { data, isLoading } = useQuery<PaginatedDeployment>({
    ...useTenantQuery([`environments/${environment.id}/deployments`, { page }]),
    refetchInterval: 5000,
  });
  // Fetch deployments with pagination

  const deployments = data?.entries || [];
  const pagination = data?.pagination;

  const renderDeployments = (envObj: Environment, list: DeploymentItem[]) =>
    list.map((deployment) => (
      <Deployment
        key={deployment.id}
        deployment={deployment}
        environment={envObj}
      />
    ));

  const renderPagination = () => (
    <Pagination
      settings={{
        total: pagination?.totalEntries || 0,
        pageSize: pagination?.pageSize || 0,
        currentPage: page || 1,
      }}
      nextPage={updatePage}
    />
  );

  if (isLoading && !data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className={style.heading}>
        <div>
          <h2>Deployment History</h2>
        </div>
        <div>{renderPagination()}</div>
      </div>
      {deployments.length ? (
        <ul className={style.log}>
          {renderDeployments(environment, deployments)}
        </ul>
      ) : (
        <div className={style.noDeployments}>No Deployments</div>
      )}
      {renderPagination()}
    </>
  );
};

export default DeploymentList;
