import { useQuery } from "@tanstack/react-query";

import { useTenantQuery } from 'components/Auth/AuthenticatedQueryProvider';
import { AwsProfile } from 'types/api';

export const useAwsProfiles = (projectId?: number) => {
  return useQuery<AwsProfile[]>({
    ...useTenantQuery([`providers/aws/profiles`])
  });
};

