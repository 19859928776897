import React, { useState } from 'react';
import { Button } from '../../../modules/ui-kit-react/lib';
import AddProject from '../AddProjectToGroup';
import { ProjectGroup } from 'types/api';

interface AddProjectToGroupButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  projectGroup: ProjectGroup;
  flat?: boolean;
  raised?: boolean;
  small?: boolean;
}

const AddProjectToGroupButton: React.FC<AddProjectToGroupButtonProps> = ({
  projectGroup,
  ...rest
}) => {
  const [showAddProject, setShowAddProject] = useState(false);

  return (
    <>
      <Button
        flat
        appearance="primary"
        icon="add"
        onClick={() => setShowAddProject(true)}
        {...rest}
      >
        Add Project
      </Button>
      
      <AddProject
        active={showAddProject}
        projectGroup={projectGroup}
        onClose={() => setShowAddProject(false)}
      />
    </>
  );
};

export default AddProjectToGroupButton;
