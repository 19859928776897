import React, { FC } from 'react';
import { Button } from '../../modules/ui-kit-react/lib';
import { useNavigate } from 'react-router-dom';  // <-- v6 hook
import Title from 'components/Title';
import styles from './style.module.css';

interface Props {
  title: string;
  message: string;
  icon: string;
  onBack?: () => void;
}

const ErrorPage: FC<Props> = ({ title, message, icon, onBack }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
    if (onBack) onBack();
  };

  return (
    <div className={styles.wrap}>
      <Title title="Error" />

      <div className={styles.icon}>
        <i className="material-icons">{icon}</i>
      </div>
      <div className={styles.text}>
        <div className={styles.title}>{title}</div>
        <div>
          <pre>{message}</pre>
        </div>
        <div>
          <Button appearance="primary" onClick={handleGoBack}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
