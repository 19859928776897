import React, { createContext, useState, useContext, FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

interface AppContext {
  token: string;
  tenant: string;
  logout: () => void;
}

const Context = createContext<AppContext>({
  token: '',
  tenant: '',
  logout: () => {},
});

export const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [token, setToken] = useState('');
  const [tenant, setTenant] = useState('');
  const navigate = useNavigate();

  const updateToken = (newToken: string): void => {
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem('access_token');
    navigate('/');
  }

  const context = { token, setToken: updateToken, tenant, setTenant, logout };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useAppContext(): AppContext {
  const context = useContext(Context);
  return context;
}

export function withAppContext(Component: React.ComponentType): FC<any> {
  return props => (
    <Context.Consumer>
      {ctx => <Component {...props} {...ctx} />}
    </Context.Consumer>
  );
}
