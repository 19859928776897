import React, { useEffect, useState } from "react";
import {
  DndContext,
  DragEndEvent,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import ProjectEnvGrid from "components/ProjectEnvGrid";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { Environment, Project } from "types/api";

interface ProjectEnvGridContainerProps {
  project: Project;
  subscribeToMore: () => void;
}

const ProjectEnvGridContainer: React.FC<ProjectEnvGridContainerProps> = ({
  project,
  subscribeToMore,
}) => {
  const [environments, setEnvironments] = useState<Environment[]>([]);
  const [isDragging, setIsDragging] = useState(false);

  const updatePosition = useAuthenticatedMutation<
    void,
    { environmentId: number; newPosition: number | undefined }
  >("environments/:environmentId/position", {
    requiresTenant: true,
    method: "PUT",
    // Path parameters
    pathParams: (variables) => ({
      environmentId: variables.environmentId.toString(),
    }),
    // Query parameters
    queryParams: (variables) => ({
      // Only send the new position if it's defined
      ...(variables.newPosition && {
        newPosition: variables?.newPosition?.toString(),
      }),
    }),
  });

  useEffect(() => {
    if (project.environments) {
      setEnvironments(project.environments);
    } else {
      setEnvironments([]);
    }
  }, [project]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || !environments?.length) {
      setIsDragging(false);
      return;
    }

    if (active.id !== over.id) {
      // Convert IDs to numbers for comparison if they're not already
      const oldIndex = environments.findIndex(
        (env) => env.id === Number(active.id)
      );
      
      const newIndex = environments.findIndex(
        (env) => env.id === Number(over.id)
      );

      if (oldIndex === -1 || newIndex === -1) {
        console.warn("Could not find environment indices", {
          oldIndex,
          newIndex,
        });
        setIsDragging(false);
        return;
      }

      const newEnvironments = arrayMove(environments, oldIndex, newIndex);
      setEnvironments(newEnvironments);

      updatePosition.mutate({
        environmentId: Number(active.id),
        newPosition: environments[newIndex]?.position,
      });
    }

    setIsDragging(false);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <ProjectEnvGrid
        project={project}
        environments={environments}
        isDragging={isDragging}
        subscribeToMore={subscribeToMore}
      />
    </DndContext>
  );
};

export default ProjectEnvGridContainer;
