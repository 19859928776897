import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import Container from "components/Container";
import Title from "components/Title";
import { withRoutedPagination } from "components/RoutedPagination";
import Pagination from "components/Pagination";
import withAuth from "components/Auth";
import { debounce } from "lodash";
import { useQuery } from "@tanstack/react-query";
import {
  Row,
  Col,
  CardHead,
  CardContent,
  Input,
} from "../../../modules/ui-kit-react/lib";
import styles from "./style.module.css";
import ProjectGroups from "../../../components/ProjectGroups/List";
import CreateProjectButton from "../../../components/CreateProjectButton";
import ProjectBox from "../../../components/ProjectBox";
import AccessNotice from "../../../components/AccessNotice";
import { Grid } from "@mui/material";

const ProjectsList = ({ auth, changePage, page }) => {
  const { tenant } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");

  const { data, error, isLoading } = useQuery({
    queryKey: ["projects", { page, search }],
    meta: { requiresTenant: true },
  });

  const searchFn = debounce((value) => {
    changePage(1);
    setSearch(value);
  }, 200);

  const renderSearch = () => (
    <Input
      placeholder="Search Projects"
      defaultValue={search}
      onChange={searchFn}
      icon="search"
      role="searchbox"
    />
  );

  const renderPagination = () => {
    if (!data?.entries?.length) return null;

    return (
      <Pagination
        settings={{
          total: data.pagination.totalEntries,
          pageSize: data.pagination.pageSize,
          currentPage: data.pagination.pageNumber,
        }}
        nextPage={changePage}
      />
    );
  };

  if (isLoading) return null;
  if (error) return `Error! ${error.message}`;

  return (
    <Container>
      <Title title="Projects" />
      <CardHead>
        <div className={styles.head}>
          <div className={styles.title}>
            <span className={styles.tenant}>
              {`${tenant?.replace("_", " ")} `}
            </span>
            Projects
          </div>
          <div className={styles.search}>{renderSearch()}</div>
          {auth.hasRole("USER") && (
            <div className={styles.addProject}>
              <CreateProjectButton history={navigate} />
            </div>
          )}
        </div>
      </CardHead>
      <AccessNotice />
      <CardContent>
        <ProjectGroups />
        <div data-cy="projects-list">
          <div className={styles.projectsHeading}>
            <div className={styles.heading}>All Projects</div>
            <div>{renderPagination()}</div>
          </div>
          <Grid container spacing={3}>
            {data?.entries?.map((project) => (
              <Grid item lg={4} md={4} xs={6} key={project.id}>
                <ProjectBox project={project} />
              </Grid>
            ))}
            {data?.entries?.length === 0 && (
              <div className={styles.noProjects}>No projects</div>
            )}
          </Grid>
          {renderPagination()}
        </div>
      </CardContent>
    </Container>
  );
};

export default withAuth(withRoutedPagination(ProjectsList));
