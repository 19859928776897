export const ROLES = ['ADMIN', 'DEPLOYER', 'USER'];

/**
 *
 * Helper for building graphql request.
 * Read Only is an implicit role so we never send that to api.
 *
 * @param {string} role
 */
export function sliceRoles(role) {
  return ROLES.slice(ROLES.indexOf(role), ROLES.length);
}

/**
 * Takes list of roles and tells you the highest role
 * (while taking into account graphql weirdness)
 * @param {array} roles
 */
export function highestRole(roles) {
  return roles[0];
}
