import React from 'react';
import { CardHead, CardContent } from '../../../modules/ui-kit-react/lib';
import TenantFeatures from 'components/TenantFeatures';
import Title from 'components/Title';

const Settings: React.FC = () => (
  <>
    <Title title="Tenant Settings" />
    <CardHead>Tenant Settings</CardHead>

    <CardContent>
      <TenantFeatures />
    </CardContent>
  </>
);

export default Settings;
