import React from 'react';
import Alert from '../../modules/ui-kit-react/lib/components/Alert';
import { CircularProgress } from '@mui/material';
import ReferenceForm from 'components/ReferenceForm';
import { useUpdateReference } from 'hooks/project-hooks';
import style from './style.module.css';
import { Project, Reference } from 'types/api';

interface ReferenceInlineEditProps {
  onClose: () => void;
  project: Project;
  reference: Reference;
}

const ReferenceInlineEdit: React.FC<ReferenceInlineEditProps> = ({
  onClose,
  project,
  reference
}) => {
  const updateReference = useUpdateReference(project.id, reference.id);

  const handleSubmit = async (form: { name: string; url: string }) => {
    try {
      await updateReference.mutateAsync({
        name: form.name,
        url: form.url
      });
      onClose();
    } catch (error) {
      // Error handling is automatic through react-query
      console.error('Error updating reference:', error);
    }
  };

  return (
    <div className={style.container}>
      {updateReference.isPending && <CircularProgress />}
      
      {updateReference.error && (
        <Alert type="danger" message={updateReference.error.message} />
      )}
      
      <div className={style.heading}>Edit Reference</div>
      
      <ReferenceForm
        edit
        gqlError={updateReference.error}
        reference={reference}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default ReferenceInlineEdit;
