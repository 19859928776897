import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import CreateProjectGroup from '../ProjectGroups/CreateGroup';

function CreateProjectButton() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openGroupForm = () => {
    setShowForm(true);
    handleClose();
  };

  const closeGroupForm = () => {
    setShowForm(false);
  };

  const handleProjectClick = () => {
    navigate('../projects/new');
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Create
      </Button>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleProjectClick}>
          <ListItemIcon>
            <InsertDriveFileIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Project</ListItemText>
        </MenuItem>
        <MenuItem onClick={openGroupForm}>
          <ListItemIcon>
            <FolderIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Group</ListItemText>
        </MenuItem>
      </Menu>

      <CreateProjectGroup active={showForm} onClose={closeGroupForm} />
    </div>
  );
}

export default CreateProjectButton;
