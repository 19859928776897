/* eslint-disable */

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = undefined;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _flexboxgridModule = _interopRequireDefault(require("../../themes/flexboxgrid.module.css"));

var _constants = require("./constants");

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var Col = function Col(props) {
  var children = props.children,
      xs = props.xs,
      sm = props.sm,
      md = props.md,
      lg = props.lg,
      first = props.first,
      last = props.last,
      className = props.className,
      rest = _objectWithoutProperties(props, ["children", "xs", "sm", "md", "lg", "first", "last", "className"]);

  var bpProps = {
    xs: xs,
    sm: sm,
    md: md,
    lg: lg
  }; // build a classname string with each breakpoint

  var breakpointClassNames = _constants.breakpoints.reduce(function (acc, bp) {
    // doesn't exist or set to false
    if (!props[bp]) {
      return acc;
    } // is explicitly set to true


    if (props[bp] === true) {
      return "".concat(acc, " ").concat(_flexboxgridModule["default"]["col-".concat(bp)]);
    }

    return "".concat(acc, " ").concat(_flexboxgridModule["default"]["col-".concat(bp, "-").concat(bpProps[bp])]);
  }, '').trim();

  if (breakpointClassNames.length === 0) {
    breakpointClassNames = _flexboxgridModule["default"]['col-xs'];
  } // build classes for `first-bp` breakdpoints


  var firstClasses = first.reduce(function (acc, bp) {
    return "".concat(acc, " ").concat(_flexboxgridModule["default"]["first-".concat(bp)]);
  }, '').trim(); // build classes for `last-bp` breakpoints

  var lastClasses = last.reduce(function (acc, bp) {
    return "".concat(acc, " ").concat(_flexboxgridModule["default"]["first-".concat(bp)]);
  }, '').trim();
  var classes = (0, _classnames["default"])(breakpointClassNames, firstClasses, lastClasses, className);
  return _react["default"].createElement("div", _extends({
    className: classes
  }, rest), children);
};

var gridOrBool = [_propTypes["default"].oneOf(_constants.gridSize), _propTypes["default"].bool];
Col.propTypes = {
  children: _propTypes["default"].node.isRequired,
  xs: _propTypes["default"].oneOfType(gridOrBool),
  sm: _propTypes["default"].oneOfType(gridOrBool),
  md: _propTypes["default"].oneOfType(gridOrBool),
  lg: _propTypes["default"].oneOfType(gridOrBool),
  first: _propTypes["default"].arrayOf(_propTypes["default"].oneOf(_constants.breakpoints)),
  last: _propTypes["default"].arrayOf(_propTypes["default"].oneOf(_constants.breakpoints)),
  className: _propTypes["default"].string
};
Col.defaultProps = {
  xs: null,
  sm: null,
  md: null,
  lg: null,
  first: [],
  last: [],
  className: null,
  children: null
};
var _default = Col;
exports["default"] = _default;