import React from "react";
import { MultiSelect } from "../../modules/ui-kit-react/lib";
import { Project } from "types/api";
import { useListProjects } from "hooks/project-hooks";

interface ProjectSelectorProps
  extends Omit<React.ComponentProps<typeof MultiSelect>, "options"> {
  filter?: (project: Project) => boolean;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  filter = () => true,
  ...rest
}) => {
  const { data, isLoading } = useListProjects();

  if (isLoading || !data?.entries) return "...";

  return (
    <MultiSelect
      options={
        data.filter(filter).map((p) => ({
          label: p.name,
          value: p.id,
        })) as any
      }
      {...rest}
    />
  );
};

export default ProjectSelector;
