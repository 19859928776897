import React, { FC } from "react";
import {
  CardHead,
  CardContent,
  Table,
} from "../../../../modules/ui-kit-react/lib";
import { Button as MuiButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingPage from "components/LoadingPage";
import Title from "components/Title";
import moment from "moment";
import style from "./style.module.css";
import { GraphQLError } from "../../../../errors";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { useQuery } from "@tanstack/react-query";
import { PaginatededProjectResponse, Project } from "types/api";

const ArchivedProjectList: FC = () => {
  const {
    data,
    error,
    isLoading: loading,
    refetch: refetchArchivedProjects,
  } = useQuery<PaginatededProjectResponse>({
    queryKey: ["projects", { only_archived: true }],
    meta: { requiresTenant: true },
  });

  const unarchiveProject = useAuthenticatedMutation<Project, { id: number }>(
    "projects/:id/unarchive",
    {
      requiresTenant: true,
      method: "POST",
      onSuccess: () => {
        refetchArchivedProjects();
      },
      pathParams: (variables) => ({ id: variables.id.toString() }),
    }
  );

  

  if (error) throw new GraphQLError(error);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <>
      <Title title="Archived Projects" />

      <CardHead className={style.head}>
        <div>Archived Projects</div>
      </CardHead>

      <CardContent>
        <Table<Project>
          paginationSize={15}
          columns={[
            {
              heading: "Name",
              path: "name",
              sort: true,
              template: ({ name }) => <div>{name}</div>,
            },
            {
              heading: "Archived At",
              path: "updatedAt",
              sort: true,
              template: ({ updatedAt }) => moment(updatedAt || "").fromNow(),
            },
            {
              heading: "Actions",
              sort: false,
              template: ({ id }) => (
                <MuiButton
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => unarchiveProject.mutate({ id })}
                  sx={{
                    minWidth: "100px",
                    padding: "8px 16px",
                  }}
                >
                  Unarchive
                </MuiButton>
              ),
            },
          ]}
          data={(data && data.entries) || []}
        />
      </CardContent>
    </>
  );
};

export default ArchivedProjectList;
