/* eslint-disable import/prefer-default-export */

import semver from "semver";

/**
 * Returns the GitHub URL for comparing two tags for a repo.
 *
 * It tries to compare the tags using semver. If that fails,
 * it falls back to using tag1 as the base tag.
 *
 * @param repoUrl the repo's github URL
 * @param tag1 semver tag (e.g. 1.0.0)
 * @param tag2 semver tag (e.g. 1.0.2)
 */
export const buildTagComparisonUrl = (
  repoUrl: string,
  tag1: string,
  tag2: string
): string => {
  let baseTag = tag1;
  let compareTag = tag2;

  try {
    if (semver.gt(tag1, tag2, true)) {
        // Swap tags if base is an older version
        [baseTag, compareTag] = [compareTag, baseTag];
    }
  } catch (e: unknown) {
    // Default to tag1 as base tag if the tags aren't parseable
  }

  return `${repoUrl}/compare/${baseTag}...${compareTag}`;
};
