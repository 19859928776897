import React from "react";
import Alert from "../../modules/ui-kit-react/lib/components/Alert";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthenticatedMutation } from "components/Auth/AuthenticatedQueryProvider";
import { getStatusEnumsFromForm } from "utils/enums";
import WebHookForm from "./Form";
import style from "./style.module.css";

interface WebHookInlineAddProps {
  environmentId: string;
  onClose: (webhook?: any) => void;
}

const WebHookInlineAdd: React.FC<WebHookInlineAddProps> = ({
  onClose,
  environmentId,
}) => {
  const queryClient = useQueryClient();

  const createWebHook = useAuthenticatedMutation<
    any,
    {
      url: string;
      on: string[];
      awsProfileId: number | null;
    }
  >(`environments/${environmentId}/webhooks`, {
    requiresTenant: true,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [`environments/${environmentId}`],
      });
      onClose(data);
    },
  });

  return (
    <div className={style.container} data-cy="web-hook-form">
      {createWebHook.error && (
        <Alert type="danger" message={createWebHook.error.message} />
      )}
      <div className={style.heading}>New Alert</div>
      <WebHookForm
        onCancel={onClose}
        onSubmit={(form) =>
          createWebHook.mutate({
            url: form.url,
            on: getStatusEnumsFromForm(form.on),
            awsProfileId: form.awsProfileId ? Number(form.awsProfileId) : null,
          })
        }
      />
    </div>
  );
};

export default WebHookInlineAdd;
