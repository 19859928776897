import React, { useState, useEffect, useCallback } from "react";
import config from "config";
import { withAppContext } from "context";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import { usePKCE } from "./usePKCE";
import { AuthContext } from "./AuthContext";

function AuthProvider({ children, setToken, token }) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profile, setProfileData] = useState({});
  const url = usePKCE();

  const updateProfile = (data) => setProfileData(data);

  const hasRole = (role) => get(profile, "roles", []).includes(role);

  const login = useCallback(() => {
    if (!url) return;
    window.location.href = url;
  }, [url]);

  useEffect(() => {
    if (!isAuthenticated && !loading) {
      login();
    }
  }, [isAuthenticated, loading, login]);

  const isTokenExpired = useCallback((token) => {
    if (!token) return true;

    try {
      const [, payload] = token.split(".");
      const decodedPayload = JSON.parse(atob(payload));
      const expirationTime = decodedPayload.exp * 1000;
      return Date.now() >= expirationTime;
    } catch (error) {
      console.error("Error parsing token:", error);
      return true;
    }
  }, []);
  
  const refreshAuthToken = useCallback(async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      console.error("No refresh token available.");
      return false;
    }

    const body = new URLSearchParams({
      client_id: config.AUTH.CLIENT_ID,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: config.AUTH.SCOPE,
    });

    try {
      const response = await fetch(config.AUTH.TOKEN_URL, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: body.toString(),
      });
      const data = await response.json();

      if (data.access_token && data.refresh_token) {
        setIsAuthenticated(true);
        setToken(data.access_token);
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        return true;
      }
      console.error("Failed to refresh access token");
      return false;
    } catch (error) {
      console.error("Error refreshing access token:", error);
      return false;
    }
  }, [setToken]);

  const checkAuthentication = useCallback(async () => {
    const tokenInLs = localStorage.getItem("access_token");
    const tenantInLs = localStorage.getItem("currentTenant");
    
    if (tokenInLs && !isTokenExpired(tokenInLs)) {
      setIsAuthenticated(true);
      setToken(tokenInLs);
      if (tenantInLs) {
        setLoading(false);
      }
    } else {
      const refreshed = await refreshAuthToken();
      if (!refreshed) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        setIsAuthenticated(false);
        setToken("");
        setLoading(false);
        login();
      }
    }
  }, [setToken, isTokenExpired, refreshAuthToken, login]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const currentToken = localStorage.getItem("access_token");
      if (currentToken && isTokenExpired(currentToken)) {
        const refreshed = await refreshAuthToken();
        if (!refreshed) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          setIsAuthenticated(false);
          setToken("");
          login();
        }
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [isTokenExpired, setToken, login, refreshAuthToken]);

  const handleAuthentication = useCallback(
    async (code) => {
      const codeVerifier = localStorage.getItem("pkce_code_verifier");
      if (!code || !codeVerifier) {
        console.error("Code or code verifier is missing.");
        setLoading(false);
        return;
      }

      const tokenUrl = config.AUTH.TOKEN_URL;
      const body = new URLSearchParams({
        client_id: config.AUTH.CLIENT_ID,
        code,
        redirect_uri: config.AUTH.REDIRECT_URI,
        grant_type: "authorization_code",
        code_verifier: codeVerifier,
      });

      try {
        const response = await fetch(tokenUrl, {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: body.toString(),
        });
        const data = await response.json();
        const { access_token: accessToken, refresh_token: refreshToken } = data;

        if (accessToken && refreshToken) {
          setIsAuthenticated(true);
          setToken(accessToken);
          localStorage.setItem("access_token", accessToken);
          localStorage.setItem("refresh_token", refreshToken);
          localStorage.removeItem("pkce_code_verifier");
          navigate("/select-tenant");
        } else {
          console.error("Failed to retrieve tokens");
        }
      } catch (error) {
        console.error("Error fetching tokens:", error);
      } finally {
        setLoading(false);
      }
    },
    [navigate, setToken]
  );

  useEffect(() => {
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");

    if (currentPath.startsWith("/oauth/callback") && code) {
      handleAuthentication(code);
    } else {
      checkAuthentication();
    }
  }, [handleAuthentication, checkAuthentication]);

  const tenant = get(profile, "currentTenant", null);
  const superAdmin = get(profile, "superAdmin", false);

  const contextValue = {
    token,
    profile,
    hasRole,
    updateProfile,
    tenant,
    superAdmin,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}

export default withAppContext(AuthProvider);