import React from "react";
import EnvForm from "components/EnvForm";
import Container from "components/Container";
import Title from "components/Title";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Crumb,
  CardHead,
  CardContent,
  Alert,
} from "../../../modules/ui-kit-react/lib";
import { useQuery } from "@tanstack/react-query";
import {
  useTenantQuery,
  useAuthenticatedMutation,
} from "components/Auth/AuthenticatedQueryProvider";
import styles from "./style.module.css";
import { Environment, Project, UpdateEnvironmentRequest } from "types/api";

const EnvironmentEdit: React.FC = () => {
  const { id: environmentId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // Fetch environment details
  const { data, error } = useQuery<Environment>(
    useTenantQuery([`environments/${environmentId}`])
  );

  // Fetch project details
  const { data: project } = useQuery<Project>({
    ...useTenantQuery([`projects/${data?.projectId}`]),
    enabled: !!data?.projectId,
  });

  // Update environment mutation
  const updateEnvironment = useAuthenticatedMutation<
    Environment,
    UpdateEnvironmentRequest
  >(`environments/${environmentId}`, {
    requiresTenant: true,
    method: "PUT",
    onSuccess: () => {
      if (data) {
        navigate(`../../projects/${project?.id}`);
      }
    },
  });

  if (error) throw error;

  return (
    <Container>
      <Title title={`${project?.name} - Edit Environment "${data?.name}"`} />

      <CardHead className={styles.header}>
        <Breadcrumbs>
          <Crumb>
            <Link to="../../projects">Projects</Link>
          </Crumb>

          <Crumb>
            <Link to={`../../projects/${project?.id}`}>{project?.name}</Link>
          </Crumb>

          <Crumb>
            <Link to={`../${data?.id}`}>{data?.name}</Link>
          </Crumb>

          <Crumb active>Edit</Crumb>
        </Breadcrumbs>

        <div>
          <Button
            appearance="primary"
            flat
            icon="arrow_back"
            onClick={() => navigate(`../../projects/${project?.id}`)}
          >
            Back to Project
          </Button>
        </div>
      </CardHead>

      <CardContent>
        <h1>Edit Environment</h1>

        {updateEnvironment.error && (
          <Alert type="danger" message={updateEnvironment.error.message} />
        )}

        <EnvForm
          edit
          gqlError={updateEnvironment.error}
          projectId={project?.id}
          environment={data}
          loading={updateEnvironment.isPending}
          onSubmit={(form: Environment) => {
            const privileged = form.privileged === true;

            if (!form.name)
              return;

            updateEnvironment.mutate({
              name: form.name,
              url: form.url || null,
              tagPattern: privileged
                ? "^v(0|[1-9]+[0-9]*).(0|[1-9]+[0-9]*).(0|[1-9]+[0-9]*)$"
                : form.tagPattern || "*",
              privileged,
              autoPromote: form.autoPromote === true,
            });
          }}
        />
      </CardContent>
    </Container>
  );
};

export default EnvironmentEdit;
