import React, { Component } from 'react';
import Input from '../../modules/ui-kit-react/lib/components/Input/index';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';

function arrEq(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; i += 1) {
    if (a[i] !== b[i]) return false;
  }

  return true
}

class Search extends Component {
  static propTypes = {
    collection: PropTypes.arrayOf(PropTypes.object).isRequired,
    keys: PropTypes.arrayOf(PropTypes.string).isRequired,
    threshold: PropTypes.number,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    threshold: 0.4,
    onSearch: () => {
      // do nothing
    },
    placeholder: 'Search'
  };

  search = value => {
    const { collection, keys, threshold, onSearch } = this.props;

    if (value === '') return onSearch(collection);

    const fuse = new Fuse(collection, {
      keys,
      threshold
    });

    return onSearch(fuse.search(value));
  };

  componentDidMount = () => {
    const { collection, onSearch } = this.props;

    // return full collection on initial render
    onSearch(collection);
  };

  componentDidUpdate = prevProps => {
    const { collection, onSearch } = this.props;

    if (!arrEq(collection, prevProps.collection)) {
      onSearch(collection);
    }
  };

  render() {
    const { placeholder } = this.props;

    return <Input placeholder={placeholder} onChange={this.search} />;
  }
}

export default Search;
