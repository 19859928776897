import React, { Component } from 'react';
import {
  formField,
  FormFieldInjectedProps,
  ValidationErrors
} from '../../modules/react-forms';
import _Select, {
  Props as ReactSelectProps,
  SingleValue,
  MultiValue,
  PropsValue
} from 'react-select';
import classnames from 'classnames';
import style from './style.module.css';

export interface Option {
  label: string;
  value: string;
}

export interface MySelectProps extends Omit<ReactSelectProps<Option, boolean>, 'value' | 'onChange' | 'defaultValue'> {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  defaultValue?: string | string[] | null;
  onValueChange?: (value: string | string[] | null) => void;
}

const getFieldErrors = (
  validationErrors: ValidationErrors,
  isPristine: boolean
): string | boolean => {
  if (isPristine) return false;
  return validationErrors.join(', ');
};

function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

const styleOpts = {
  control: (provided: any) => ({
    ...provided,
    color: 'var(--color-text)',
    backgroundColor: 'var(--input-text-background-color)',
    border: 'var(--input-border)'
  }),
};

export class MySelect extends Component<MySelectProps & FormFieldInjectedProps> {
  static defaultProps = {
    isMulti: false
  };

  componentDidMount() {
    const { defaultValue, setValue } = this.props;
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }

  render() {
    const {
      value,
      setValue,
      name,
      label,
      className,
      isPristine,
      validationErrors,
      isMulti,
      options,
      onValueChange,
      defaultValue, // Add this to exclude it from rest
      ...rest
    } = this.props;

    const error = getFieldErrors(validationErrors, isPristine);

    const getValue = (
      val: string | string[] | null
    ): PropsValue<Option> => {
      if (!val) return null;
      
      const findOption = (v: string) =>
        (options as Option[])?.find((o) => o.value === v);

      if (Array.isArray(val)) {
        return val.map(findOption).filter(notUndefined);
      }
      return findOption(val) || null;
    };

    return (
      <div className={classnames(style.select, className)}>
        {label && <label htmlFor={name}>{label}</label>}
        {/* eslint-disable-next-line react/jsx-pascal-case */}
        <_Select<Option, boolean>
          options={options}
          styles={styleOpts}
          value={getValue(value)}
          onChange={(selected: SingleValue<Option> | MultiValue<Option> | null) => {
            let selectedValue: string | string[] | null = null;

            if (selected) {
              if (Array.isArray(selected)) {
                // MultiValue
                selectedValue = selected.map((s) => s.value);
              } else {
                // SingleValue
                selectedValue = ('value' in selected) ? selected.value : null;
              }
            }

            onValueChange?.(selectedValue);
            setValue(selectedValue);
          }}
          isMulti={isMulti}
          {...rest}
        />
        {error && <span className={style.error}>{error}</span>}
      </div>
    );
  }
}

export default formField(MySelect);