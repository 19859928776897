import React, { FC, useState, useEffect } from "react";
import { formField, FormFieldInjectedProps } from "../../modules/react-forms";
import Dropdown from "../../modules/ui-kit-react/lib/components/Dropdown";
import Input from "../../modules/ui-kit-react/lib/components/Input";
import { awsRegions, humanize } from "utils/enums";

import style from "./style.module.css";

function tryParseUrl(url: string): [string, string] {
  if (!url) {
    return ["", ""];
  }
  try {
    // urls containing hosts without domains get lunmped in to pathname,
    // so we have to manually parse it
    const { pathname } = new URL(url);
    const [host, path] = pathname.split("/").filter(Boolean);
    if (host && path) {
      return [host, path];
    }
  } catch {
    // continue regardless of error
  }

  return ["", ""];
}

const LambdaUrl: FC<FormFieldInjectedProps> = (props) => {
  const { value, setValue } = props;
  const [host, path] = tryParseUrl(value);

  const [region, setRegion] = useState(host);
  const [functionName, setFunctionName] = useState(path);
  useEffect(() => {
    if (!region && host) {
      setRegion(host);
    }
    if (!functionName && path) {
      setFunctionName(path);
    }
    if (region && functionName) {
      setValue(`lambda://${region}/${functionName}`);
    }
  }, [region, functionName, host, path]);

  return (
    <>
      <Dropdown
        onChange={(val) => {
          setRegion(val?.toString());
        }}
        name="region"
        value={region}
        required
        label="Region"
        source={awsRegions.map((reg) => ({
          value: humanize(reg),
          label: humanize(reg),
        }))}
      />
      <Input
        onChange={(val) => {
          setFunctionName(val.toString());
        }}
        required
        name="functionName"
        label="Function Name"
        value={functionName}
        className={style.input}
        type="text"
      />
    </>
  );
};

export default formField(LambdaUrl);
